import React from 'react';
import { DateInput, required as requiredValidate } from 'react-admin';
import { styled } from '@mui/system';

interface ShsDateInputProps {
    label?: string;
    source?: string;
    required?: boolean;
}

const styles = {
    '&.MuiFormControl-root': {
        margin: 0
    },
    '& legend': {
        display: 'none'
    },
    '& input.MuiInputBase-input': {
        background: '#ffffff',
        padding: '11px 16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    }
};

const StyledDateInput = styled(DateInput)(styles);

const ShsDateInput: React.FC<ShsDateInputProps> = ({ label, required, source }, props) => {
    const isRequired = () => (required ? '*' : '');
    const validateFn = [...(required ? [requiredValidate()] : [])];
    return (
        <div className="text_field__container top">
            {label && (
                <label className="text_field__label">
                    {label || ''}
                    {isRequired()}
                </label>
            )}
            <StyledDateInput source={source} validate={validateFn} {...props} label={false} />
        </div>
    );
};

export default ShsDateInput;

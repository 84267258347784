import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    AutocompleteInput,
    AutocompleteArrayInput,
    AutocompleteInputProps,
    required as requiredValidate
} from 'react-admin';
import { isEqual } from 'lodash';
import { useFormContext } from 'react-hook-form';
import axiosApiInstance, { postRequestCacheConfig } from '../../../api/axiosConfig';
import { Box } from '@mui/material';
import { Theme } from 'src/Theme';

type AutoCompleteWithLoadProps = Partial<Omit<AutocompleteInputProps, 'defaultValue'>> & {
    keyField: string;
    textField?: string | ((record: any) => string);
    filters?: { [key in string]: any };
    filterName?: string; // If filter name is not specified, then there won't be options reloading on filter change
    pageUrl: string;
    resultLimit?: number;
    transformData?: (record: any) => any;
    onChange?: (value: any, option: any) => void;
    choices?: never;
    required?: boolean;
    multiselect?: boolean;
    defaultValue?: any;
};

const autoCompleteInputSx = {
    '.MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: `${Theme.palette.primary.main}`
        },
        '&:focus fieldset': {
            border: `3px solid #75CACB`
        }
    },
    '& .MuiInputBase-root': {
        padding: 0,
        background: '#ffffff'
    },
    '& .MuiInputBase-root > .MuiInputBase-input': {
        padding: '11px 16px'
    },

    '&.MuiFormControl-root': {
        margin: 0
    },
    '& legend': {
        display: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    },
    '& .MuiFormHelperText-root': {
        ml: 0
    }
};

const ShsAutoCompleteWithLoad = (props: AutoCompleteWithLoadProps) => {
    const {
        keyField,
        textField,
        filterName,
        pageUrl,
        resultLimit,
        filters,
        transformData,
        onChange,
        label,
        required,
        sx,
        multiselect,
        disabled = false,
        ...otherProps
    } = props;
    const [requestFilters, setFilters] = useState<{ [key in string]: any } | undefined>(filters);
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const prevFilters = useRef(filters);
    useEffect(() => {
        if (filters != undefined || !isEqual(prevFilters.current, filters)) {
            setFilters((prevState) => ({
                ...(filters || {}),
                ...(filterName ? { [filterName]: prevState?.[filterName] } : {})
            }));
        }
        prevFilters.current = filters;
    }, [filters]);

    const { getValues } = useFormContext();

    useEffect(() => {
        setLoading(true);
        const pageRequest = {
            page: 0,
            size: resultLimit || 10,
            sort: { properties: [typeof textField === 'string' ? textField : keyField], direction: 'ASC' },
            filters: requestFilters
        };

        axiosApiInstance
            .post(pageUrl, pageRequest, postRequestCacheConfig)
            .then((response) => {
                setData(response.data.content);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                throw e;
            });
    }, [resultLimit, textField, keyField, requestFilters, pageUrl]);

    let choices = transformData ? data.map(transformData) : [...data];

    const handleChange = (value: any) => {
        const selectedOption = choices.find((choice: any) => choice[keyField] === value);
        if (onChange) {
            onChange(value, selectedOption);
        }
    };

    const InputComponent: React.FC<any> = useMemo(
        () => (multiselect ? AutocompleteArrayInput : AutocompleteInput),
        [multiselect]
    );

    const validateFn = required ? [requiredValidate()] : [];

    return (
        <div className="text_field__container top">
            {label && <Box className="text_field__label">{label + (required ? '*' : '')}</Box>}
            <InputComponent
                {...otherProps}
                sx={{ ...autoCompleteInputSx, ...(sx || {}) }}
                label={false}
                onChange={handleChange}
                choices={choices}
                optionText={textField || keyField}
                optionValue={keyField}
                isLoading={loading}
                loading={loading}
                validate={validateFn}
                disabled={disabled}
                setFilter={(value: any) =>
                    value != '' &&
                    filterName &&
                    value !==
                        choices.find((choice: any) => choice[keyField] === getValues(props.source as any))?.[
                            (textField || keyField) as string
                        ] &&
                    setFilters({ ...(filters || {}), [filterName]: `%${value.toLowerCase()}%` })
                }
            />
        </div>
    );
};

export default ShsAutoCompleteWithLoad;

import React from 'react';
import { UserRole } from 'src/auth/types';
import { WithRole } from '../common';
import { ScheduleList } from './List/ScheduleList';
import { AdminGroup, TeachersGroup } from 'src/constants/roles';
import NoAccess from '../common/Errors/NoAccess';

export default {
    list: (
        <WithRole roles={[...AdminGroup, UserRole.PSYCHOLOGIST, ...TeachersGroup]} orElse={<NoAccess />}>
            <ScheduleList />
        </WithRole>
    )
};

import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Grid, Box, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { sectionTitleStyle, smallGrayFontStyle } from 'src/styles';
import { ChildFormFields } from 'src/constants';
import { ShsButton, ShsDateString } from 'src/components/common';
import NewEducation from './NewEducation';
import ServiceEndDate from './ServiceEndDate';

interface InstitutionDataProps {
    record?: any;
}

export const InstitutionData: React.FC<InstitutionDataProps> = () => {
    const record = useRecordContext();
    const visit = record?.[ChildFormFields.VISIT];
    const [newEducation, setNewEducation] = React.useState(false);

    const serviceEndDate = visit?.[ChildFormFields.EDUCATIONAL_SERVICE_END];
    const educationCenterData = visit?.[ChildFormFields.EDUCATION_CENTER_DATA];
    const hospitalDepartmentData = visit?.[ChildFormFields.HOSPITAL_DEPARTMENT_DATA];

    return (
        <>
            {newEducation ? (
                <NewEducation cancel={() => setNewEducation(false)} />
            ) : (
                <>
                    <Grid item xs={12}>
                        <Box sx={sectionTitleStyle}>{educationCenterData?.name}</Box>
                    </Grid>
                    <Grid item xs={12} mt={1.5} mb={1}>
                        {hospitalDepartmentData?.name}
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={smallGrayFontStyle} mb={0.3}>
                            Дата згоди на надання освітніх послуг
                        </Box>
                        <ShsDateString
                            source={`${[ChildFormFields.VISIT]}.${[ChildFormFields.EDUCATIONAL_AGREEMENT_DATE]}`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={smallGrayFontStyle} mb={0.3}>
                            Дата завершення надання освітніх послуг
                        </Box>
                        {serviceEndDate ? (
                            <ShsDateString
                                source={`${[ChildFormFields.VISIT]}.${[ChildFormFields.EDUCATIONAL_SERVICE_END]}`}
                            />
                        ) : (
                            <ServiceEndDate />
                        )}
                    </Grid>
                    <Grid item xs={6} mt={2}>
                        <ShsButton
                            styleType="round"
                            label="Розпочати навчання знову"
                            icon={<AddCircleOutlineIcon />}
                            sx={{ height: '48px' }}
                            onClick={() => setNewEducation(true)}
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>
        </>
    );
};

export default InstitutionData;

import React from 'react';
import { useRecordContext, Datagrid, FunctionField, List } from 'react-admin';
import { Box } from '@mui/material';

import { EducationCenterType, HospitalDepartment } from 'src/components/Employees/types';

const DepartmentsInfoTab: React.FC = () => {
    const record = useRecordContext<EducationCenterType>();
    if (!record) return null;
    const departments = record.hospitalDepartments.map((department) => ({ name: department.name }));

    return (
        <List
            actions={false}
            pagination={false}
            sx={{
                '.RaList-content': {
                    marginTop: 0
                }
            }}
        >
            <Datagrid bulkActionButtons={false} data={departments}>
                <FunctionField
                    label="Назва відділеня"
                    render={({ name }: HospitalDepartment) => <Box py="16px">{name}</Box>}
                />
            </Datagrid>
        </List>
    );
};

export default DepartmentsInfoTab;

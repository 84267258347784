import React, { FC, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { NumberInput, maxValue, minValue, number, required, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { ShsCheckbox } from '../../common';
import { CheckboxStyles, NumberInputStyles } from '../common/styles';

export const SubjectPlanningEditControl: FC<{ sourceName: string; label: string }> = ({ sourceName, label }) => {
    const { planning } = useRecordContext();
    const form = useFormContext();
    const planningHours = planning[sourceName];
    const fieldName = `planning.${sourceName}`;
    const [readonly, setReadonly] = useState(planningHours ? undefined : true);

    const inputValidators = [number('1-999'), minValue(1, '1-999'), maxValue(999, '1-999')];

    if (!readonly) {
        inputValidators.push(required('Поле є обов`язковим'));
    }

    const handleCheckboxChange = () => {
        if (!readonly) {
            form.resetField(fieldName);
        }

        setReadonly(!readonly);
    };

    return (
        <>
            <Grid item md={3} sm={4} xs={4}>
                <ShsCheckbox
                    value={!readonly}
                    defaultValue={!readonly}
                    source=""
                    label={label}
                    onChange={handleCheckboxChange}
                    sx={CheckboxStyles}
                />
            </Grid>
            <Grid item md={4} sm={4} xs={6}>
                <Typography noWrap>Кількість годин/тиждень</Typography>
            </Grid>
            <Grid item md={5} sm={4} xs={2}>
                <NumberInput
                    defaultValue={planningHours}
                    source={fieldName}
                    label={false}
                    disabled={readonly}
                    helperText={false}
                    validate={inputValidators}
                    sx={NumberInputStyles}
                />
            </Grid>
        </>
    );
};

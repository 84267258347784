import React from 'react';
import { Grid } from '@mui/material';
import { ShsTimeInput } from 'src/components/common';

interface TimeSlotProps {
    index: number;
    getSource: (source: string) => string;
}

const TimeSlot: React.FC<TimeSlotProps> = ({ index, getSource }) => (
    <Grid container alignItems="center" spacing={1}>
        <Grid item alignItems="center">
            <ShsTimeInput source={getSource(`${index}.from`)} label="з" labelPosition="left" />
        </Grid>
        <Grid item>
            <ShsTimeInput source={getSource(`${index}.to`)} label="до" labelPosition="left" />
        </Grid>
    </Grid>
);

export default TimeSlot;

import { FC } from 'react';
import { useDrag } from 'react-dnd';
import { Box, Card, Typography } from '@mui/material';
import { ReactComponent as ReorderIcon } from 'src/assets/reorder.svg';
import { palette } from 'src/Theme';
import DateTimeUtils from 'src/utils/datetime';
import { DragItem, DragAndDropZone } from 'src/constants/drag-and-drop';
import { CalendarEvent } from '../types';
import { DragAndDropData } from '../interfaces';
import { ReactComponent as UsersIcon } from 'src/assets/users.svg';
import { UnknownAvatar } from 'src/components/common';

export const ScheduleEventCard: FC<{
    event: CalendarEvent;
    dragIcon?: boolean;
    onDragEnd?: (data: DragAndDropData) => void;
}> = ({ event, dragIcon, onDragEnd }) => {
    const { title, start, end } = event;

    // todo: use dragPreviewRef to show rotated preview
    const [{ isDragging }, dragRef, dragPreviewRef] = useDrag(
        () => ({
            type: DragItem.SCHEDULE_CARD,
            item: { ...event },
            end: (_, monitor) => {
                const dropResult = monitor.getDropResult<{ target: DragAndDropZone }>();

                if (dropResult && onDragEnd) {
                    onDragEnd({ item: event, target: dropResult.target });
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        [event]
    );

    return (
        // todo: create color palette for different subjects
        <Card
            ref={dragRef}
            sx={{
                display: 'flex',
                gap: '8px',
                background: '#059669',
                padding: '4px 8px',
                cursor: 'grab',
                height: '100%'
            }}>
            {dragIcon && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ReorderIcon />
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    minWidth: 0,
                    borderRadius: '8px',
                    color: palette.primary.creamyWhite,
                    fontSize: '10px'
                }}>
                <Typography
                    component="b"
                    sx={{
                        maxWidth: '100%',
                        fontWeight: 600,
                        fontSize: '11px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}>
                    {title}
                </Typography>
                {start && end && (
                    <Typography
                        component="time"
                        sx={{ fontSize: '11px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {DateTimeUtils.getTime(start)} - {DateTimeUtils.getTime(end)}
                    </Typography>
                )}
                {/* todo: add class data to ScheduleCalendar interface & card + correct this mock */}
                <Box sx={{ display: 'flex', gap: '14px' }}>
                    <span>{'99 клас'}</span>
                    <span style={{ display: 'flex', gap: '2px' }}>
                        <UsersIcon /> {'99'} учнів
                    </span>
                </Box>
                {/* todo: add teacher data to ScheduleCalendar interface & card + correct this mock */}
                <Box sx={{ display: 'flex', gap: '2px', alignItems: 'center', justifyContent: 'center' }}>
                    <UnknownAvatar firstName={'Марія'} lastName={'Батьківна'} size="extra-small" />
                    <Typography variant="inherit">
                        {'Марія'} {'Батьківна'}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
};

import React from 'react';
import { Confirm } from 'react-admin';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';

import { shsModalsStyles, interFontStyle } from 'src/styles';
import CircleWarning from 'src/assets/CircleWarning';
import { ShsButton } from 'src/components/common';
import { ModalProps } from 'src/components/common/ShsFormToolbar';

export const CancelCreateModal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const handleConfirm = () => {
        onConfirm?.();
        onClose();
    };

    return (
        <Confirm
            isOpen={isOpen}
            loading={false}
            title={''}
            onConfirm={handleConfirm}
            onClose={onClose}
            sx={shsModalsStyles}
            content={
                <>
                    <div className="shs-modal-header">
                        <Box sx={{ textAlign: 'center' }}>
                            <CircleWarning />
                        </Box>
                        <Typography variant="h6" component="h6" mb={2} mt={1} fontWeight="600" align="center">
                            Скасування додавання освітнього центру
                        </Typography>
                    </div>
                    <div className="shs-modal-body">
                        <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                            Чи впевнені ви що хочете скасувати додавання освітнього центру?
                        </Typography>
                        <Typography component="div" mb={4} align="center" sx={interFontStyle}>
                            Після скасування додавання освітнього центру всі дані про нього, його працівників, учнів та
                            розклади в ньому будуть видалені й не підлягатимуть відновленню.
                        </Typography>
                    </div>
                    <div className="shs-modal-footer">
                        <ShsButton label="Скасувати" styleType="outline" onClick={handleConfirm} />
                        <ShsButton label="Повернутись назад" styleType="filled-danger" onClick={onClose} />
                    </div>
                </>
            }
        />
    );
};

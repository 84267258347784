import React from 'react';
import SubjectCreate from './Create/SubjectCreate';
import SubjectsList from './List/SubjectsList';
import SubjectEdit from './Edit/SubjectEdit';
import { WithRole } from '../common';
import { AdminGroup, TeachersGroup } from 'src/constants/roles';
import { UserRole } from 'src/auth/types';
import NoAccess from '../common/Errors/NoAccess';

export default {
    list: (
        <WithRole roles={[...AdminGroup, UserRole.PSYCHOLOGIST, ...TeachersGroup]} orElse={<NoAccess />}>
            <SubjectsList />
        </WithRole>
    ),
    create: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<NoAccess />}>
            <SubjectCreate />
        </WithRole>
    ),
    edit: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<NoAccess />}>
            <SubjectEdit />
        </WithRole>
    )
};

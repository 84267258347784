import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import axiosApiInstance from '../../api/axiosConfig';
import { useLogin, useNotify } from 'react-admin';

interface CountDownProps {
    refreshCountDownKey: number;
    initialCountDownValue: number;
    requestId: string;
    errorMessage: string;
    setErrorMessage: Dispatch<React.SetStateAction<string>>;
}

const CountDownTimer: React.FC<CountDownProps> = ({
    refreshCountDownKey,
    initialCountDownValue,
    requestId,
    errorMessage,
    setErrorMessage
}) => {
    const [seconds, setSeconds] = useState(initialCountDownValue);

    const login = useLogin();

    const checkDiiaAuth = (requestId: string) => {
        axiosApiInstance
            .get('/auth/diia/verify', {
                headers: {
                    'X-Document-Request-Id': requestId
                }
            })
            .then((response) => {
                if (response.data.processed) {
                    if (response.data.authenticated) {
                        login({ authDto: response.data.authDto });
                    } else {
                        setErrorMessage(response.data.errorMessage || 'Помилка автентифікації');
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        setSeconds(initialCountDownValue);
    }, [refreshCountDownKey]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
        if (seconds > 0 && seconds % 3 == 0 && errorMessage == '') {
            checkDiiaAuth(requestId);
        }
        return () => clearInterval(intervalId);
    }, [seconds, errorMessage]);

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const display: string =
        errorMessage ||
        (seconds <= 0 ? 'Час сканування QR коду вийшов.' : 'QR-код оновиться через ' + formatTime(seconds));

    return <div className="countdown">{display}</div>;
};

export default CountDownTimer;

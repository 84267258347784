import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Card, Tabs, Tab, Box } from '@mui/material';
import { useQuery } from 'react-query';

import { CalendarEvent } from '../types';
import { EducationLevelGroup, Resource } from 'src/constants';
import { ScheduleToolbar } from './ScheduleToolbar';
import { ScheduleCalendar } from './ScheduleCalendar';
import { LessonsDuration } from 'src/constants/centers';

export const TabbedScheduleCalendar = () => {
    const dataProvider = useDataProvider();
    const [activeTab, setActiveTab] = useState<EducationLevelGroup>(EducationLevelGroup.PRESCHOOL);
    // todo: query grade data from BE
    const gradeData = {
        lessonDuration: 1000 * 60 * LessonsDuration.MINUTES60
    };

    const { data = [] } = useQuery<CalendarEvent[]>(activeTab, () =>
        dataProvider.getGroupList(Resource.SCHEDULE, {
            meta: { educationLevelGroup: activeTab }
        })
    );

    return (
        <Box>
            <ScheduleToolbar />
            <Card>
                <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
                    <Tab value={EducationLevelGroup.PRESCHOOL} label="Дошкільна освіта" />
                    <Tab value={EducationLevelGroup.JUNIOR_SCHOOL} label="1-4 класи" />
                    <Tab value={EducationLevelGroup.HIGH_SCHOOL} label="5-11 класи" />
                </Tabs>
                <ScheduleCalendar events={data} config={{ lessonDurationMs: gradeData.lessonDuration }} />
            </Card>
        </Box>
    );
};

import React from 'react';
import { Typography, Grid, Divider } from '@mui/material';

import { ShsTimeInput } from 'src/components/common';
import { CenterFormFields } from 'src/constants/centers';

export const CenterWorkingHours: React.FC = () => {
    return (
        <>
            <Typography variant="h6" component="h6" mb={3} fontWeight="600">
                Робочі години
            </Typography>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid sx={{ display: 'flex', gap: '8px' }}>
                        <ShsTimeInput source={CenterFormFields.WORK_START} label="Початок" required />
                        <Divider
                            sx={{
                                width: '7px',
                                borderColor: 'black',
                                borderWidth: '1px',
                                alignSelf: 'center',
                                marginTop: '8px'
                            }}
                        />
                        <ShsTimeInput source={CenterFormFields.WORK_END} label="Завершення" required />
                    </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid sx={{ display: 'flex', gap: '8px' }}>
                            <ShsTimeInput source={CenterFormFields.BREAK_START} label="Перерва на обід" required />
                            <Divider
                                sx={{
                                    width: '7px',
                                    borderColor: 'black',
                                    borderWidth: '1px',
                                    alignSelf: 'center',
                                    marginTop: '8px'
                                }}
                            />
                            <ShsTimeInput source={CenterFormFields.BREAK_END} required isAsteriskHidden />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';

import { interFontStyle } from 'src/styles';
import CircleWarning from 'src/assets/CircleWarning';

export const DeleteModalEmployee: React.FC = () => {
    const record = useRecordContext();

    const name = `${record.name} ${record.surname} ${record.patronymic}`.trim();
    return (
        <>
            <div className="shs-modal-header">
                <Box sx={{ textAlign: 'center' }}>
                    <CircleWarning />
                </Box>
                <Typography variant="h6" component="h6" mb={2} mt={1} fontWeight="600" align="center">
                    Видалення працівника
                </Typography>
            </div>
            <div className="shs-modal-body">
                <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                    Чи впевнені ви що хочете видалити працівника: <strong>{name}</strong>?
                </Typography>
                <Typography component="div" mb={4} align="center" sx={interFontStyle}>
                    Після видалення працівника він буде видалений у всіх розкладах та будуть втрачені всі його дані про
                    надані послуги дітям.
                </Typography>
            </div>
            <div className="shs-modal-footer"></div>
        </>
    );
};

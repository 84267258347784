import {
    EducationAction,
    EducationLevel,
    PreschoolAgeLevel,
    SpecialEducationRequirements,
    DisabilityGroup,
    DisabilityReason
} from 'src/constants';
export const ChildMock = {
    id: '10',
    version: '10',
    birthCertificateId: 'VII-ff 200022222',
    firstName: 'Андрій',
    lastName: 'Шевченко',
    patronymic: 'Миколайович',
    birthDate: '2013-12-27',
    gender: 'MALE',
    visit: {
        actualAddress: 'м. Дніпро, вул. Янтарна, буд 12, кв 34',
        registrationAddress: 'м. Бахмут, вул. Миру, 16-12',
        educationInstitutionUrl: 'http://google.com',
        educationInstitutionPhone: '+380979998877',
        educationInstitutionEmail: 'ebdo@gov.com',
        additionalActionRequired: {
            enabled: true,
            action: EducationAction.REMOTE // null
        },
        visitParents: [
            {
                parent: {
                    taxNumber: '7777777777',
                    surname: 'Денисов',
                    name: 'Денис',
                    patronymic: 'Патронович',
                    email: 'admin7@gmail.com',
                    phone: '+38099111111'
                },
                role: 'Діііііідько',
                active: true
            }
        ],
        educationLevel: EducationLevel.YEARS4,
        preschoolAgeLevel: PreschoolAgeLevel.AGE3, // null
        educationCenterId: 2,
        hospitalDepartmentId: 2,
        servicesAgreementDate: '2023-12-27',
        servicesEndDate: null,
        specialRequirements: {
            enabled: true,
            parameters: [
                SpecialEducationRequirements.ALTERNATIVE_COMMUNICATION,
                SpecialEducationRequirements.SIGN_LANGUAGE,
                SpecialEducationRequirements.SPEECH_THERAPIST,
                SpecialEducationRequirements.PSYCHOLOGIST,
                SpecialEducationRequirements.BRAILLE
            ] // []
        },
        disabilityPresent: true,
        disabilityGroup: DisabilityGroup.GROUP1, // null
        disabilityReason: DisabilityReason.INJURIES, // null
        orphan: false,
        internallyDisplaced: false,
        warVictim: true,
        complicatedLifeCircumstances: false,
        otherSocialStatus: '1234',
        mediaRecordingAgreement: true,
        mediaRecordingLink: 'https://en.wikipedia.org/wiki/Flag_of_Ukraine#/media/File:Flag_of_Ukraine.svg', // null
        info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    }
};

const lesson = {
    id: 1,
    version: 0,
    createdBy: '1111111111',
    date: new Date().toLocaleDateString(),
    startTime: '10:45:00',
    endTime: '11:30:00',
    teacherId: 2,
    teacher: {
        name: 'Красношапка Н.Г.'
    },
    classroomId: 2,
    classroom: {
        id: 2,
        building: null,
        floor: null,
        block: null,
        roomNumber: 412,
        educationCenter: {
            id: 1,
            name: 'Інститут ендокринології та обміну речовин ім. В.П ...',
            shortName: 'Ендокринології'
        }
    },
    subjectId: 1,
    subject: {
        id: 1,
        name: 'Географія'
    }
};

export const ChildSchedulerMock = [lesson];

import * as React from 'react';
import { Navigate as navigate } from 'react-big-calendar';
import type { ToolbarProps, View } from 'react-big-calendar';
import { Box, Stack, Select, MenuItem, Typography } from '@mui/material';

import { CalendarViewList } from 'src/constants';
import { ShsButton } from 'src/components/common';
import { ReactComponent as LeftIcon } from 'src/assets/chevron-left.svg';
import { ReactComponent as RightIcon } from 'src/assets/chevron-right.svg';

const toolbarStyles = {
    margin: 0,
    padding: '16px',
    justifyContent: 'space-between',
    '& .MuiButtonBase-root': {
        height: '40px',
        padding: '0 12px !important',
        lineHeight: '40px !important'
    },
    '& .MuiButtonBase-root.with-icon': {
        minWidth: 'unset',
        width: '40px !important',
        padding: '0 !important'
    },
    '& .with-icon .MuiButton-startIcon': {
        m: 0,
        width: '40px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

const selectStyles = {
    width: '160px',
    height: '40px',
    borderRadius: '6px'
};

const Toolbar: React.FC<ToolbarProps> = ({ label, onNavigate, onView, view }) => {
    const [viewValue, setViewValue] = React.useState(view);
    const handleChangeView = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const view = event.target.value as View;
        setViewValue(view);
        onView(view);
    };
    return (
        <Box className="rbc-toolbar" sx={toolbarStyles}>
            <Stack direction="row" spacing={2}>
                <ShsButton label="Cьогодні" styleType="filled-white" onClick={() => onNavigate(navigate.TODAY)} />
                <ShsButton
                    label=""
                    styleType="filled-white"
                    icon={<LeftIcon />}
                    className="with-icon"
                    onClick={() => onNavigate(navigate.PREVIOUS)}
                />
                <ShsButton
                    label=""
                    styleType="filled-white"
                    icon={<RightIcon />}
                    className="with-icon"
                    onClick={() => onNavigate(navigate.NEXT)}
                />
                <Typography component="div" fontWeight="600" fontSize="16px" lineHeight="40px" pl={1}>
                    {label}
                </Typography>
            </Stack>
            <Select sx={selectStyles} value={viewValue} label="" onChange={handleChangeView as any}>
                {CalendarViewList.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default Toolbar;

import { FC } from 'react';
import { SelectArrayInput, SelectArrayInputProps } from 'react-admin';
import { styled } from '@mui/system';

import { palette } from 'src/Theme';
import { ReactComponent as CheckIcon } from 'src/assets/chevron-checkbox.svg';

interface ShsSelectArrayInputProps extends SelectArrayInputProps {
    label?: string;
}

export const selectArrayInputSx = {
    '&.MuiFormControl-root': {
        margin: 0
    },
    '& .MuiInputBase-root': {
        background: palette.primary.contrastText
    },
    '& legend': {
        display: 'none'
    },
    '& .MuiInputBase-input': {
        padding: '11px 16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    },
    '& .MuiInputLabel-root': {
        display: 'none'
    },
    '.MuiOutlinedInput-notchedOutline legend': {
        display: 'none'
    },
    '.MuiAutocomplete-clearIndicator': {
        display: 'none'
    },
    '.MuiChip-label .select-option__checkbox': {
        display: 'none'
    }
};

const menuListSx = {
    '.MuiMenuItem-root.Mui-selected': {
        backgroundColor: palette.primary.contrastText
    },
    '.Mui-selected .select-option__checkbox': {
        backgroundColor: palette.primary.main,
        borderColor: palette.primary.main
    },
    '.Mui-selected .select-option__checkbox-icon': {
        visibility: 'visible'
    }
};

const SelectArrayInputLabel = styled('label')({
    fontSize: '1rem',
    fontWeight: '500',
    color: palette.primary.midnightBlue,
    whiteSpace: 'nowrap'
});

const SelectArrayInputContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%'
});

const StyledArrayInputOption = styled('span')({
    width: '100%',
    display: 'flex',
    gap: '10px'
});

const StyledArrayInputCheckbox = styled('span')({
    width: '20px',
    height: '20px',
    border: `1px solid ${palette.primary.inputBorder}`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const StyledCheckIcon = styled(CheckIcon)({
    width: '100%',
    height: '100%',
    visibility: 'hidden'
});

const SelectArrayOption: FC<{ option: { id: string; name: string } }> = ({ option }) => {
    return (
        <StyledArrayInputOption>
            <StyledArrayInputCheckbox className="select-option__checkbox">
                <StyledCheckIcon className="select-option__checkbox-icon" />
            </StyledArrayInputCheckbox>
            {option.name}
        </StyledArrayInputOption>
    );
};

export const ShsSelectArrayInput: FC<ShsSelectArrayInputProps> = ({ label, required, sx, ...props }) => {
    return (
        <SelectArrayInputContainer>
            <SelectArrayInputLabel>
                {label || ''}
                {required && '*'}
            </SelectArrayInputLabel>
            <SelectArrayInput
                label={false}
                helperText={false}
                optionText={(option) => <SelectArrayOption option={option} />}
                sx={{ ...selectArrayInputSx, ...sx }}
                required
                options={{
                    autoWidth: false,
                    MenuProps: {
                        MenuListProps: {
                            sx: menuListSx
                        }
                    }
                }}
                {...props}
            />
        </SelectArrayInputContainer>
    );
};

import { CenterFormFields } from 'src/constants/centers';

export const getSchoolLessons = (educationalLevel: CenterFormFields) => (days: CenterFormFields) => [
    {
        id: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FIRST_LESSON}`,
        start: {
            label: '1-ий урок',
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FIRST_LESSON}.${CenterFormFields.FROM}`
        },
        end: {
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FIRST_LESSON}.${CenterFormFields.TO}`
        }
    },
    {
        id: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SECOND_LESSON}`,
        start: {
            label: '2-ий урок',
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SECOND_LESSON}.${CenterFormFields.FROM}`
        },
        end: {
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SECOND_LESSON}.${CenterFormFields.TO}`
        }
    },
    {
        id: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.THIRD_LESSON}`,
        start: {
            label: '3-ий урок',
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.THIRD_LESSON}.${CenterFormFields.FROM}`
        },
        end: {
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.THIRD_LESSON}.${CenterFormFields.TO}`
        }
    },
    {
        id: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FOURTH_LESSON}`,
        start: {
            label: '4-ий урок',
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FOURTH_LESSON}.${CenterFormFields.FROM}`
        },
        end: {
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FOURTH_LESSON}.${CenterFormFields.TO}`
        }
    },
    {
        id: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FIFTH_LESSON}`,
        start: {
            label: '5-ий урок',
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FIFTH_LESSON}.${CenterFormFields.FROM}`
        },
        end: {
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.FIFTH_LESSON}.${CenterFormFields.TO}`
        }
    },
    {
        id: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SIXTH_LESSON}`,
        start: {
            label: '6-ий урок',
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SIXTH_LESSON}.${CenterFormFields.FROM}`
        },
        end: {
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SIXTH_LESSON}.${CenterFormFields.TO}`
        }
    },
    {
        id: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SEVENTH_LESSON}`,
        start: {
            label: '7-ий урок',
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SEVENTH_LESSON}.${CenterFormFields.FROM}`
        },
        end: {
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.SEVENTH_LESSON}.${CenterFormFields.TO}`
        }
    },
    {
        id: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.EIGHTH_LESSON}`,
        start: {
            label: '8-ий урок',
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.EIGHTH_LESSON}.${CenterFormFields.FROM}`
        },
        end: {
            source: `${CenterFormFields.LESSONS_DURATION}.${educationalLevel}.${days}.${CenterFormFields.EIGHTH_LESSON}.${CenterFormFields.TO}`
        }
    }
];

export const setTime = (hour: number, min: number, sec: number) => {
    const currentDate = new Date();
    currentDate.setHours(hour, min, sec);

    const formattedTime = currentDate.toTimeString().split(' ')[0];
    return formattedTime;
};

import React from 'react';
import { DeleteWithConfirmButton, Toolbar } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/material';
import { shsToolbarStyles } from 'src/styles';
import { ReactComponent as DeleteIcon } from 'src/assets/trash-can.svg';
import { ShsButton, ShsSaveButton, WithRole } from 'src/components/common';
import { UserRole } from 'src/auth/types';

export type ModalProps = {
    isOpen: boolean;
    onConfirm?: () => void;
    onClose: () => void;
};

export interface ShsFormToolbarProps {
    submitLabel?: string;
    cancelLabel?: string;
    cancelDialog?: React.FC<ModalProps>;
    enableDeleteButton?: boolean;
    enableCancelButton?: boolean;
    enableSaveButton?: boolean;
    deleteLabel?: string;
    deleteConfirmContent?: React.ReactNode;
    enableDeleteForRoles?: UserRole[];
    className?: string;
    sx?: SxProps;
    handleSave?: () => void;
    handleCancel: () => void;
}

const ShsFormToolbar: React.FC<ShsFormToolbarProps> = ({
    submitLabel = 'Зберегти',
    cancelLabel = 'Скасувати',
    cancelDialog: CancelDialog,
    enableDeleteButton = false,
    enableCancelButton = true,
    enableSaveButton = true,
    deleteLabel = 'Видалити',
    deleteConfirmContent,
    enableDeleteForRoles = [],
    className,
    sx,
    handleCancel,
    handleSave = () => {}
}) => {
    const { isDirty } = useFormState();
    const [openCancel, setOpenCancel] = React.useState(false);

    const handleSaveClick = () => {
        handleSave();
    };
    const handleCancelClick = () => (CancelDialog && isDirty ? setOpenCancel(true) : handleCancel());
    const handleCancelDialogClose = () => setOpenCancel(false);

    return (
        <Toolbar sx={shsToolbarStyles} className="add-child-toolbar">
            <Box sx={{ flexGrow: 1 }}>
                <ShsSaveButton disabled={!enableSaveButton} onClick={handleSave} label={submitLabel} sx={{ mr: 2 }} />
                {enableCancelButton && (
                    <ShsButton label={cancelLabel} styleType="outline" onClick={handleCancelClick} />
                )}

                {CancelDialog && (
                    <CancelDialog isOpen={openCancel} onConfirm={handleCancel} onClose={handleCancelDialogClose} />
                )}
            </Box>
            <WithRole roles={enableDeleteForRoles}>
                {enableDeleteButton && (
                    <DeleteWithConfirmButton
                        className={className}
                        sx={sx}
                        label={deleteLabel}
                        icon={<DeleteIcon style={{ fill: '#F23030' }} />}
                        confirmTitle=""
                        confirmColor="warning"
                        confirmContent={deleteConfirmContent}
                    />
                )}
            </WithRole>
        </Toolbar>
    );
};

export default ShsFormToolbar;

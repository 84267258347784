import * as React from 'react';
import { TextField } from 'react-admin';
import { Grid, Box, Divider } from '@mui/material';

import { sectionTitleStyle, smallGrayFontStyle } from 'src/styles';
import { ShsDateString } from 'src/components/common';
import { ChildFormFields } from 'src/constants';

export const PersonalData: React.FC = () => {
    return (
        <>
            <Grid item xs={12}>
                <Box sx={sectionTitleStyle}>Персональні дані дитини</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                    № свідоцтва про народження:
                </Box>
                <TextField source={ChildFormFields.BIRTH_CERTIFICATE_ID as any} />
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                    Дата народження
                </Box>
                <ShsDateString source={ChildFormFields.BIRTH_DATE} />
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                    Місце фактичного проживання
                </Box>
                <TextField source={`${ChildFormFields.VISIT}.${ChildFormFields.RESIDENCE}` as any} />
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                    Місце реєстрації
                </Box>
                <TextField source={`${ChildFormFields.VISIT}.${ChildFormFields.REGISTRATION}` as any} />
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>
        </>
    );
};

export default PersonalData;

import React from 'react';
import { Edit, FormGroupContextProvider, TabbedForm, useCreatePath, useRedirect } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { employeeEditContainerStyles } from 'src/styles/components/employee/employee';
import { EmployeeType } from './types';
import EmploymentTypeForm from './form/EmploymentTypeForm';
import EmployeeDocumentsForm from './form/EmployeeDocumentsForm';
import EmployeePersonalDetailsForm from './form/EmployeePersonalDetailsForm';
import tabGroups from './const/tab-groups';
import EmployeeEditToolbar from './form/EmployeeEditToolbar';
import { DeleteModalEmployee } from './modals/DeleteModalEmployee';

/**
 * Employee Edit page with three tabs
 */
const EmployeeEdit: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const onSuccess = () => {
        redirect(createPath({ resource: 'employees', type: 'list' }));
    };

    return (
        <Container sx={employeeEditContainerStyles} maxWidth={false}>
            <ShsReturnButton
                to={createPath({ resource: 'employees', type: 'list' })}
                label="Назад до списку працівників"
            />
            <Edit<EmployeeType> mutationOptions={{ onSuccess }}>
                <Typography variant="h4" component="h3" mb={4} fontWeight="700">
                    Редагування працівника
                </Typography>
                <TabbedForm
                    toolbar={
                        <EmployeeEditToolbar
                            deleteLabel="Видалити працівника"
                            enableDeleteButton
                            enableCancelButton={false}
                            deleteConfirmContent={<DeleteModalEmployee />}
                        />
                    }>
                    <TabbedForm.Tab label="Персональна інформація">
                        <FormGroupContextProvider name={tabGroups[0]}>
                            <EmployeePersonalDetailsForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Документи">
                        <FormGroupContextProvider name={tabGroups[1]}>
                            <EmployeeDocumentsForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Працевлаштування">
                        <FormGroupContextProvider name={tabGroups[2]}>
                            <EmploymentTypeForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                </TabbedForm>
            </Edit>
        </Container>
    );
};

export default EmployeeEdit;

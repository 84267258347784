import { SearchInput, SearchInputProps } from 'react-admin';
import React from 'react';
import { textInputSx } from './ShsTextInput';
import { Box } from '@mui/material';

const ShsSearchInput: React.FC<Partial<SearchInputProps> & Pick<SearchInputProps, 'source'>> = (props) => {
    return (
        <div className="text_field__container top">
            <Box className="text_field__label">{props.label || ''}</Box>
            <SearchInput name={props.source} {...props} sx={{ ...textInputSx, ...(props.sx || {}) }} label={false} />
        </div>
    );
};

export default ShsSearchInput;

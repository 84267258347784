import React from 'react';
import { useCreatePath, useRedirect } from 'react-admin';
import { CancelEditModal, DeleteModal } from 'src/components/Children/modals';
import ShsFormToolbar from '../../common/ShsFormToolbar';
import { UserRole } from 'src/auth/types';
import { shsButtonStyles } from 'src/styles';
import cn from 'classnames';
import { useFormState } from 'react-hook-form';

interface AddToolbarProps {
    editMode?: boolean;
}

const AddToolbar: React.FC<AddToolbarProps> = ({ editMode }) => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const handleCancelConfirm = () => {
        redirect(createPath({ resource: 'children', type: 'list' }));
    };
    const { isValid } = useFormState();

    return (
        <ShsFormToolbar
            enableSaveButton={isValid}
            handleCancel={handleCancelConfirm}
            cancelDialog={CancelEditModal}
            className={cn('shs-button', 'outline-danger')}
            sx={shsButtonStyles}
            enableDeleteButton={editMode}
            enableDeleteForRoles={[UserRole.SYSTEM_ADMIN]}
            deleteConfirmContent={<DeleteModal />}
        />
    );
};

export default AddToolbar;

import { capitalize } from 'lodash';
import type { Culture } from 'react-big-calendar';
import localizer from './config';

const capitalizeMonth = (str: string): string =>
    str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

export const getheaderFormatters = (culture: Culture) => {
    const monthHeaderFormat = (date: Date) => `${capitalize(localizer.format(date, 'LLLL yyyy', culture))}`;
    const dayHeaderFormat = (date: Date) => capitalizeMonth(localizer.format(date, 'd MMMM yyyy', culture));
    const dayRangeHeaderFormat = ({ start, end }: { start: Date; end: Date }) => {
        const startDate = capitalizeMonth(
            localizer.format(start, localizer.eq(start, end, 'month') ? 'dd' : 'dd MMMM', culture)
        );
        const endDate = capitalizeMonth(localizer.format(end, 'dd MMMM yyyy', culture));
        return `${startDate} - ${endDate}`;
    };
    return { monthHeaderFormat, dayHeaderFormat, dayRangeHeaderFormat };
};

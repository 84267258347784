import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { SubjectPlanningEditControl } from './SubjectPlanningEditControl';

export const SubjectControlsGrid: FC<{
    schoolPlanning: { sourceName: string; planningSourceName: string; label: string }[];
}> = ({ schoolPlanning }) => {
    return (
        <Grid container spacing={1} alignItems="center">
            {schoolPlanning.map((planning) => (
                <SubjectPlanningEditControl
                    key={planning.sourceName}
                    sourceName={planning.planningSourceName}
                    label={planning.label}
                />
            ))}
        </Grid>
    );
};

import { EducationStage } from '../education';
import { EducationStagePlanning } from '../education/educationStage.enum';

export const JUNIOR_SCHOOL_GRADES_LABELS = [
    { sourceName: EducationStage.GRADE1, planningSourceName: EducationStagePlanning.GRADE_1, label: '1 клас' },
    { sourceName: EducationStage.GRADE2, planningSourceName: EducationStagePlanning.GRADE_2, label: '2 клас' },
    { sourceName: EducationStage.GRADE3, planningSourceName: EducationStagePlanning.GRADE_3, label: '3 клас' },
    { sourceName: EducationStage.GRADE4, planningSourceName: EducationStagePlanning.GRADE_4, label: '4 клас' }
];

export const HIGH_SCHOOL_GRADES_LABELS = [
    { sourceName: EducationStage.GRADE5, planningSourceName: EducationStagePlanning.GRADE_5, label: '5 клас' },
    { sourceName: EducationStage.GRADE6, planningSourceName: EducationStagePlanning.GRADE_6, label: '6 клас' },
    { sourceName: EducationStage.GRADE7, planningSourceName: EducationStagePlanning.GRADE_7, label: '7 клас' },
    { sourceName: EducationStage.GRADE8, planningSourceName: EducationStagePlanning.GRADE_8, label: '8 клас' },
    { sourceName: EducationStage.GRADE9, planningSourceName: EducationStagePlanning.GRADE_9, label: '9 клас' },
    { sourceName: EducationStage.GRADE10, planningSourceName: EducationStagePlanning.GRADE_10, label: '10 клас' },
    { sourceName: EducationStage.GRADE11, planningSourceName: EducationStagePlanning.GRADE_11, label: '11 клас' }
];

export const PRE_SCHOOL_YEARS_LABELS = [
    { sourceName: EducationStage.YEARS4, planningSourceName: EducationStagePlanning.YEARS_4, label: '4 роки' },
    { sourceName: EducationStage.YEARS5, planningSourceName: EducationStagePlanning.YEARS_5, label: '5 років' },
    { sourceName: EducationStage.YEARS6, planningSourceName: EducationStagePlanning.YEARS_6, label: '6 років' }
];

import type { UpdateParams } from 'react-admin';
import axiosApiInstance from '../../axiosConfig';

import ApiMocks from 'src/api/mocks';
import { childToAPITransform } from 'src/api/Children/transforms';
import { Resource } from 'src/constants/resources';
import type { Child } from 'src/components/Children/types';

export default {
    update: (params: UpdateParams) => {
        const { useMock, method } = params.meta || {};
        if (useMock && ApiMocks[Resource.CHILDREN].update) {
            return Promise.resolve({
                data: { ...ApiMocks[Resource.CHILDREN].getOne, id: params.id }
            });
        }
        const methodUrlPath = method ? `${method.startsWith('/') ? '' : '/'}${method}` : '/update';

        return axiosApiInstance
            .put(`${Resource.CHILDREN}${methodUrlPath}`, childToAPITransform(params.data as Child))
            .then((response) => response);
    }
};

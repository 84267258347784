import { useEffect, useRef, useState } from 'react';
import { AutocompleteArrayInput, AutocompleteArrayInputProps, required as requiredValidate } from 'react-admin';
import { isEqual } from 'lodash';
import { useFormContext } from 'react-hook-form';
import axiosApiInstance, { postRequestCacheConfig } from '../../../api/axiosConfig';
import { Box } from '@mui/material';
import { UserRole } from 'src/auth/types';

type AutoCompleteSubjectProps = Partial<Omit<AutocompleteArrayInputProps, 'defaultValue'>> & {
    keyField: string;
    textField?: string | ((record: any) => string);
    filters?: { [key in string]: any };
    filterName?: string; // If filter name is not specified, then there won't be options reloading on filter change
    resultLimit?: number;
    onChange?: (value: any, option: any) => void;
    choices?: never;
    defaultValue?: any;
    setSelectedData?: (data: any[]) => void;
    sort?: (a: any, b: any) => number;
    filterChoices?: (record: any) => any;
    required?: boolean;
};

const autoCompleteInputSx = {
    '& .MuiInputBase-root': {
        padding: 0,
        background: '#ffffff'
    },
    '& .MuiInputBase-root > .MuiInputBase-input': {
        padding: '11px 16px'
    },

    '&.MuiFormControl-root': {
        margin: 0
    },
    '& legend': {
        display: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    },
    '& .MuiFormHelperText-root': {
        ml: 0
    }
};

const AutoCompleteSubject = (props: AutoCompleteSubjectProps) => {
    const {
        keyField,
        textField,
        filterName,
        resultLimit,
        filters,
        onChange,
        label,
        sx,
        setSelectedData,
        sort,
        filterChoices = (x: any) => x,
        required,
        ...otherProps
    } = props;
    const [requestFilters, setFilters] = useState<{ [key in string]: any } | undefined>(filters);
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const prevFilters = useRef(filters);

    useEffect(() => {
        if (filters != undefined || !isEqual(prevFilters.current, filters)) {
            setFilters((prevState) => ({
                ...(filters || {}),
                ...(filterName ? { [filterName]: prevState?.[filterName] } : {})
            }));
        }
        prevFilters.current = filters;
    }, [filters]);

    const { getValues } = useFormContext();

    useEffect(() => {
        setLoading(true);
        setData([]);
        const pageRequest = {
            page: 0,
            size: resultLimit || 10,
            sort: { properties: [typeof textField === 'string' ? textField : keyField], direction: 'ASC' },
            filters: requestFilters
        };

        axiosApiInstance
            .post('subjects', pageRequest, postRequestCacheConfig)
            .then((response) => {
                const res = response.data.content.map((x: any) => {
                    const roles = [];
                    if (x.highSchool) roles.push(UserRole.TEACHER);
                    if (x.juniorSchool) roles.push(UserRole.JUNIORS_TEACHER, UserRole.ASSISTANT_JUNIORS_TEACHER);
                    if (x.preschool) roles.push(UserRole.ASSISTANT_PRESCHOOL_TEACHER, UserRole.PRESCHOOL_TEACHER);

                    return { ...x, specialId: x.id, roles: roles };
                });

                setData((x) => x.concat(res));
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                throw e;
            });

        axiosApiInstance
            .post('/special-services', pageRequest, postRequestCacheConfig)
            .then((response) => {
                const res = response.data.content.map((x: any) => ({ ...x, specialId: x.id + '_spec' }));
                setData((x) => x.concat(res));
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                throw e;
            });
    }, [resultLimit, textField, keyField, requestFilters]);

    const handleChange = (value: any[]) => {
        setSelectedData?.(data.filter((x: any) => value.includes(x[keyField])));
        if (onChange) {
            onChange(
                value,
                data.find((x: any) => x[keyField] === value)
            );
        }
    };

    return (
        <div className="text_field__container top">
            {label && <Box className="text_field__label">{label + (required ? '*' : '')}</Box>}
            <AutocompleteArrayInput
                {...otherProps}
                sx={{ ...autoCompleteInputSx, ...(sx || {}) }}
                label={false}
                onChange={handleChange}
                validate={required ? requiredValidate() : []}
                choices={data.sort(sort).filter(filterChoices)}
                optionText={textField || keyField}
                optionValue={keyField}
                isLoading={loading}
                loading={loading}
                setFilter={(value: any) =>
                    value != '' &&
                    filterName &&
                    value !==
                        data.find((choice: any) => choice[keyField] === getValues(props.source as any))?.[
                            (textField || keyField) as string
                        ] &&
                    setFilters({ ...(filters || {}), [filterName]: `%${value.toLowerCase()}%` })
                }
            />
        </div>
    );
};

export default AutoCompleteSubject;

import { AuthProvider } from 'react-admin';
import axiosApiInstance from '../api/axiosConfig';
import { AuthUserType, UserRole } from './types';

const AUTH_USER_KEY = 'auth_user';

export function getAuthFromLocalStorage(): AuthUserType {
    return JSON.parse(localStorage.getItem(AUTH_USER_KEY) || 'null');
}

export function updateAuthInLocalStorage(auth: AuthUserType | null): void {
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(auth));
}

export function clearAuthInLocalStorage(): void {
    localStorage.removeItem(AUTH_USER_KEY);
}

const authProvider: AuthProvider = {
    // called when the user attempts to log in
    login: ({ taxNumber, password, authDto }: { taxNumber: number; password: string; authDto: AuthUserType }) => {
        // TODO update for diia auth
        if (authDto) {
            updateAuthInLocalStorage(authDto);
            return Promise.resolve(authDto);
        }
        return axiosApiInstance
            .post('/fakeAuth/authenticate', { taxNumber, password })
            .then((response) => updateAuthInLocalStorage(response.data));
    },
    // called when the user clicks on the logout button
    logout: async () => {
        clearAuthInLocalStorage();
    },
    // called when the API returns an error
    checkError: (error) => {
        if (error?.status === 401 || error?.status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return getAuthFromLocalStorage() ? Promise.resolve() : Promise.reject();
    },
    getIdentity: () => {
        const profile = getAuthFromLocalStorage()?.profile;
        return profile ? Promise.resolve(profile) : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async () => {
        return getAuthFromLocalStorage()?.profile?.authorities;
    },
    containsRoles: (roles: UserRole | UserRole[], oneOf = true): boolean => {
        const profile = getAuthFromLocalStorage()?.profile;
        return containsUserRoles(profile?.authorities || [], roles, oneOf);
    }
};

const AND_FUNC = (a: boolean, b: boolean) => a && b;
const OR_FUNC = (a: boolean, b: boolean) => a || b;

function containsUserRoles(userRoles: UserRole[], roles: UserRole | UserRole[], oneOf: boolean): boolean {
    const statementFunc = oneOf ? OR_FUNC : AND_FUNC;
    return (
        userRoles &&
        ((Array.isArray(roles) &&
            roles.reduce(
                (prev: boolean, current: UserRole): boolean => statementFunc(userRoles.includes(current), prev),
                !oneOf
            )) ||
            userRoles.includes(roles as UserRole))
    );
}

export default authProvider;

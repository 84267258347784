export const shsModalsStyles = {
    '& .MuiPaper-root': {
        maxWidth: '530px',
        borderRadius: '20px'
    },
    '& .MuiPaper-root > .MuiTypography-root': {
        display: 'none'
    },
    '& .MuiDialogContent-root': {
        padding: 0
    },
    '& .MuiDialogActions-root': {
        display: 'none'
    },
    '& .shs-modal-header': {
        padding: '40px 50px 0'
    },
    '& .shs-modal-body': {
        padding: '0 50px'
    },
    '& .shs-modal-footer': {
        display: 'flex',
        padding: '0 50px 40px',
        justifyContent: 'space-around'
    },
    '& .shs-modal-footer .MuiButton-root': {
        minWidth: '190px'
    }
};

import { palette } from 'src/Theme';

export const interFontStyle = {
    color: palette.primary.optional,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px'
};

export const smallFontStyle = {
    color: palette.primary.midnightBlue,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px'
};

export const titleFontStyle = {
    color: palette.primary.midnightBlue,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px'
};

export const smallGrayFontStyle = {
    ...smallFontStyle,
    color: palette.primary.grayBluish
};

export const inputLabelFontStyle = {
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '16px',
    color: palette.primary.midnightBlue
};

export const extraSmallGrayFontStyle = {
    color: palette.primary.grayBluish,
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '14px'
};

export const sectionTitleStyle = {
    color: palette.primary.midnightBlue,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
    '& .MuiTypography-root': {
        color: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit'
    },
    '& >.MuiTypography-root:not(:last-child)': {
        marginRight: '7px'
    }
};

import React, { useEffect, useState } from 'react';
import { Logout, useAuthProvider, UserMenu as UserMenuReactAdmin } from 'react-admin';
import { ReactComponent as ChevronDown } from 'src/assets/chevron-down.svg';
import './index.css';
import { UserRole, UserType } from '../../auth/types';
import { UserRoleLabel } from '../../constants';
import UnknownAvatar from '../common/UnknownAvatar';

const Icon: React.FC = () => {
    const [profile, setProfile] = useState<UserType>();
    const { getIdentity } = useAuthProvider();

    useEffect(() => {
        getIdentity?.().then((profile) => setProfile(profile as UserType));
    }, [getIdentity]);

    return (
        <div className="user_menu_container">
            <UnknownAvatar lastName={profile?.surname || ''} firstName={profile?.name} size="medium" />
            <div className="user_menu_titles">
                <div className="user_menu__name">{profile?.surname + ' ' + profile?.name}</div>
                <div className="user_menu__role">{UserRoleLabel[profile?.authorities?.[0] as UserRole]}</div>
            </div>
            <ChevronDown />
        </div>
    );
};

const UserMenu: React.FC = () => {
    return (
        <UserMenuReactAdmin className="user_menu__RA" icon={<Icon />}>
            <Logout />
        </UserMenuReactAdmin>
    );
};

export default UserMenu;

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Custom hook to manage the tab index in the Employees form
 * Tab index starts from 0
 *
 * @returns [tabIndex, navigateToTab]
 */
const useTabbedFormNavigation = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        setTabIndex(Number(pathname.split('/').pop()) || 0);
    }, [pathname]);

    const navigateToTab = (newIndex: number) => {
        const pathFragments = pathname.split('/');

        if (tabIndex > 0) {
            pathFragments.pop();
        }

        if (newIndex > 0) {
            pathFragments.push(newIndex.toString());
        }

        const newPath = '/' + pathFragments.filter(Boolean).join('/');

        navigate(newPath); // navigate to index or empty string
    };

    return { tabIndex, navigateToTab };
};

export default useTabbedFormNavigation;

import React, { FC, useMemo } from 'react';
import { Datagrid, EditButton, RaRecord, TextField, useListContext } from 'react-admin';

import { palette } from '../../../Theme';
import { ReactComponent as EditIcon } from '../../../assets/pencil-alt.svg';
import { ShsUrlField } from '../../common/Fields/ShsUrlField';
import { WithRole } from 'src/components/common';
import { UserRole } from 'src/auth/types';

export const OrderedDatagrid: FC = () => {
    const { data, page, perPage } = useListContext();
    const enrichedData = useMemo(
        () =>
            data?.map((record: RaRecord, idx: number) => {
                const _orderIdx = (page - 1) * perPage + idx + 1;
                return { ...record, _orderIdx };
            }),
        [data]
    );

    return (
        <Datagrid
            bulkActionButtons={false}
            sx={{
                '& .RaDatagrid-headerCell': { color: palette.primary.midnightBlue },
                '& .column-link': { width: '100%' }
            }}
            data={enrichedData}>
            <TextField source="_orderIdx" label="№" sortable={false} />
            <ShsUrlField
                cellClassName="column-link"
                sourceLink="link"
                sourceName="name"
                label="Назва законопроекту"
                sortable={false}
            />
            <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]}>
                <EditButton label="" icon={<EditIcon />} />
            </WithRole>
        </Datagrid>
    );
};

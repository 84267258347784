import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormGroups, useSaveContext } from 'react-admin';
import { Box } from '@mui/material';
import { ShsButton } from 'src/components/common';
import useTabbedFormNavigation from '../useTabIndex';
import tabGroups from '../const/tab-groups';

export const EmployeeCreateToolbar: React.FC = () => {
    const { tabIndex, navigateToTab } = useTabbedFormNavigation();
    const {
        trigger,
        setValue,
        getValues,
        handleSubmit,
        formState: { isValidating, isSubmitting }
    } = useFormContext();
    const { save } = useSaveContext();
    const { getGroupFields } = useFormGroups();

    const tabGroupName = tabGroups[tabIndex];
    const formGroupFields = getGroupFields(tabGroupName);
    const [formFields, setFormFields] = useState<string[]>([]);

    useEffect(() => {
        // filter out group fields like array input
        setFormFields(
            formGroupFields.filter(
                (field, fieldIndex) =>
                    !formGroupFields.some(
                        (otherField, otherFieldIndex) =>
                            otherFieldIndex !== fieldIndex && otherField.split('.')[0] === field
                    )
            )
        );
    }, [formGroupFields]);

    const handleBackwardBtnClick = () => {
        navigateToTab(tabIndex - 1);
    };

    const handleForwardBtnClick = async () => {
        let groupValid = true;

        for (let field of formFields) {
            const fieldValid = await trigger(field);

            if (!fieldValid) {
                groupValid = false;
                setValue(field, getValues(field), { shouldTouch: true }); // validation error might not display if not touched
            }
        }

        if (groupValid) {
            if (tabIndex < tabGroups.length - 1) {
                navigateToTab(tabIndex + 1);
            } else {
                await handleSubmit((data) => save?.(data))();
            }
        }
    };

    return (
        <Box display="flex" gap="12px" alignItems="center" padding="24px 0">
            {tabIndex > 0 && <ShsButton styleType="outline" label="Назад" onClick={handleBackwardBtnClick} />}
            <ShsButton
                label={tabIndex !== tabGroups.length - 1 ? 'Далі' : 'Додати працівника'}
                onClick={handleForwardBtnClick}
                disabled={isValidating || isSubmitting}
            />
        </Box>
    );
};

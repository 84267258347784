import React, { FC } from 'react';
import { EditButton, useCreatePath, useRecordContext } from 'react-admin';

import { ReactComponent as EditIcon } from '../../../assets/pencil-alt.svg';
import { Resource } from '../../../constants';

const SubjectEditBtn: FC = () => {
    const record = useRecordContext();
    const createPath = useCreatePath();

    if (!record) return null;

    return (
        <EditButton
            to={createPath({ type: 'edit', resource: Resource.SUBJECTS, id: record.id })}
            variant="text"
            label=""
            icon={<EditIcon />}
        />
    );
};
export default SubjectEditBtn;

import React, { useState } from 'react';
import { Grid, Button, FormControlLabel, Checkbox, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import TimeSlot from './TimeSlot';

interface DayScheduleProps {
    label: string;
    getSource: (source: string) => string;
}

const DaySchedule: React.FC<DayScheduleProps> = ({ label, getSource }) => {
    const [timeSlots, setTimeSlots] = useState([{ from: '', to: '' }]);

    const addTimeSlot = () => {
        setTimeSlots([...timeSlots, { from: '', to: '' }]);
    };

    const removeTimeSlot = (index: number) => {
        setTimeSlots(timeSlots.filter((_, i) => i !== index));
    };

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <FormControlLabel control={<Checkbox />} label={label} />
            </Grid>
            {timeSlots.map((slot, index) => (
                <Grid item key={index} container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography>Години роботи:</Typography>
                    </Grid>
                    <Grid item>
                        <TimeSlot index={index} getSource={getSource} />
                    </Grid>
                    <Grid item>
                        {index === timeSlots.length - 1 ? (
                            <Button onClick={addTimeSlot} variant="outlined" startIcon={<AddCircleOutlineIcon />}>
                                Інтервал
                            </Button>
                        ) : (
                            <Button onClick={() => removeTimeSlot(index)} variant="outlined" startIcon={<DeleteIcon />}>
                                Видалити
                            </Button>
                        )}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default DaySchedule;

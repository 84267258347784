import { palette } from 'src/Theme';

export const CheckboxStyles = {
    '.MuiSvgIcon-root': {
        fill: palette.primary.inputBorder
    },
    '.Mui-checked .MuiSvgIcon-root': {
        fill: palette.primary.main
    }
};

export const NumberInputStyles = {
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
        appearance: 'none',
        WebkitAppearance: 'none',
        MozAppearance: 'none'
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
        appearance: 'none',
        WebkitAppearance: 'none',
        MozAppearance: 'none'
    },
    '.Mui-error': {
        borderColor: palette.danger.optional
    },
    '.MuiFormHelperText-root.Mui-error': {
        color: palette.danger.optional
    },
    '.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        backgroundColor: palette.primary.creamyWhite
    },
    '.MuiOutlinedInput-notchedOutline legend': {
        display: 'none'
    },
    maxWidth: '70px',
    '& .MuiInputBase-input': {
        backgroundColor: 'white',
        borderColor: `${palette.primary.inputBorder}`,
        borderRadius: '6px',
        padding: '11px 16px',
        appearance: 'textfield'
    }
};

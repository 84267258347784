import * as React from 'react';
import { SimpleForm, Toolbar as T, useUpdate, useNotify } from 'react-admin';
import { Grid, Box, Stack } from '@mui/material';

import { sectionTitleStyle } from 'src/styles';
import { ChildFormFields, Resource } from 'src/constants';
import { ShsSaveButton, ShsButton, ShsTextInput, ShsDatePicker } from 'src/components/common';

const styles = {
    '&.MuiCardContent-root': {
        p: 0,
        m: 0
    },
    '&.MuiToolbar-root': {
        background: 'transparent',
        padding: 0,
        mt: 0
    }
};

interface ToolbarProps {
    cancel: () => void;
}

const Toolbar: React.FC<ToolbarProps> = ({ cancel }) => {
    return (
        <T sx={styles} className="add-child-toolbar">
            <ShsSaveButton label="Зберегти" sx={{ mr: 2 }} />
            <ShsButton label="Скасувати" styleType="outline" onClick={cancel} />
        </T>
    );
};

interface NewEducationProps {
    cancel: () => void;
}

export const NewEducation: React.FC<NewEducationProps> = ({ cancel }) => {
    const formPath = 'NewEducation';
    const [update] = useUpdate();
    const notify = useNotify();

    const handleSubmit = (data: any) => {
        const updates = { ...data[formPath] };
        delete data[formPath];
        data[ChildFormFields.VISIT] = {
            ...data[ChildFormFields.VISIT],
            ...updates,
            [ChildFormFields.EDUCATIONAL_SERVICE_END]: null
        };
        update(
            Resource.CHILDREN,
            { ...data, meta: { useMock: true } },
            {
                onSuccess: () => {
                    cancel();
                    notify('Дані збережено', { type: 'success', undoable: true });
                },
                onError: (error: any) => {
                    cancel();
                    notify(`Error: ${error?.message}`, { type: 'warning', undoable: true });
                }
            }
        );
    };
    return (
        <Grid item xs={12}>
            <SimpleForm sx={styles} toolbar={<Toolbar cancel={cancel} />} onSubmit={handleSubmit}>
                <Stack>
                    <Box sx={{ ...sectionTitleStyle, mb: 2 }}>Розпочати навчання знову</Box>
                    <ShsTextInput
                        source={`${formPath}.${ChildFormFields.HOSPITAL_DEPARTMENT}`}
                        label="Відділення в лікарні"
                        required
                    />
                    <ShsDatePicker
                        source={`${formPath}.${ChildFormFields.EDUCATIONAL_AGREEMENT_DATE}`}
                        label="Дата згоди на надання освітніх послуг"
                        required
                    />
                </Stack>
            </SimpleForm>
        </Grid>
    );
};

export default NewEducation;

import * as React from 'react';
import { Create, SimpleForm, useCreatePath, useNotify, useRedirect } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { ChildFormDefaults } from 'src/constants';
import { editContainerStyles } from 'src/styles';

import AddToolbar from './Toolbar';
import { PersonalData, EducationInstitutionData, VisitorsList, EducationChildData } from './FieldGroups';
import { Theme } from 'src/Theme';

export const ChildAdd: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const notify = useNotify();

    const onSuccess = () => {
        notify('Дитину успішно додано', {
            type: 'success',
            autoHideDuration: 2500
        });
        redirect(createPath({ resource: 'children', type: 'list' }));
    };

    return (
        <Container sx={editContainerStyles} maxWidth={false}>
            <ShsReturnButton
                to={createPath({ resource: 'children', type: 'list' })}
                label="Назад до списку дітей"
                sx={{ fontSize: Theme.typography.body2.fontSize, color: Theme.palette.primary.grayBluish }}
            />
            <Create mutationOptions={{ onSuccess }}>
                <Typography fontSize={'40px'} mb={4} fontWeight="700" color={Theme.palette.primary.midnightBlue}>
                    Додати дитину
                </Typography>
                <SimpleForm defaultValues={ChildFormDefaults} toolbar={<AddToolbar />}>
                    <PersonalData />
                    <EducationInstitutionData />
                    <VisitorsList />
                    <EducationChildData />
                </SimpleForm>
            </Create>
        </Container>
    );
};

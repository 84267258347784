import React, { useState } from 'react';
import { Loading, Show, TabbedShowLayout, useCreatePath, useEditController } from 'react-admin';
import { Container } from '@mui/material';

import { tabbedContainerStyles } from 'src/styles';
import { ShsReturnButton } from 'src/components/common';
import EmployeeTopToolbar from '../EmployeeTopToolbar';
import EmployeeGeneralTab from './EmployeeGeneralTab';
import { EmployeeTab } from 'src/constants';

/**
 * Displays detailed information about an employee
 * Includes tabbed navigation for viewing general information, schedule, and reports
 * Provides return button to navigate back to the employee list
 */
const EmployeeShow: React.FC = () => {
    const [activeTab, setActiveTab] = useState<EmployeeTab.GENERAL | EmployeeTab.SCHEDULE | EmployeeTab.REPORTS>(
        EmployeeTab.GENERAL
    );
    const createPath = useCreatePath();
    const { isLoading } = useEditController();

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Container maxWidth={false} sx={tabbedContainerStyles}>
            <ShsReturnButton
                to={createPath({ resource: 'employees', type: 'list' })}
                label="Назад до списку працівників"
            />

            <Show
                className="show-page-container"
                queryOptions={{ meta: { useMock: false } }}
                actions={<EmployeeTopToolbar />}>
                <TabbedShowLayout>
                    <TabbedShowLayout.Tab label="Загальна інформація">
                        <EmployeeGeneralTab />
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Розклад" path="scheduler">
                        <></>
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Звітність">
                        <></>
                    </TabbedShowLayout.Tab>
                </TabbedShowLayout>
            </Show>
        </Container>
    );
};

export default EmployeeShow;

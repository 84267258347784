import * as React from 'react';
import { useLocaleState } from 'react-admin';
import { Calendar } from 'react-big-calendar';
import type { CalendarProps } from 'react-big-calendar';

import localizer, { getCulture } from './config';
import { getheaderFormatters } from './formatters';

import { Toolbar } from './components';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const ShsCalendar: React.FC<{ events?: CalendarProps['events'] }> = ({ events = [] }) => {
    const [locale] = useLocaleState();
    const culture = getCulture(locale);
    return (
        <Calendar
            culture={culture}
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            formats={getheaderFormatters(culture)}
            components={{
                toolbar: Toolbar
            }}
        />
    );
};

export default ShsCalendar;

import React, { FC } from 'react';
import { CreateButton, List } from 'react-admin';
import { Container, Stack } from '@mui/material';

import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';
import ShsTitle from 'src/components/common/ShsTitle';
import CircleButtonPlus from 'src/assets/CircleButtonPlus';
import { shsButtonStyles } from 'src/styles';
import { OrderedDatagrid } from './OrderedDatagrid';

export const LawsList: FC = () => {
    return (
        <Container>
            <Stack direction="row" justifyContent="space-between" alignItems="left" paddingTop={2.5}>
                <ShsTitle title="Законодавство" sx={{ fontSize: 30 }} />
                <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]}>
                    <CreateButton
                        label="Додати законодавство"
                        variant="contained"
                        icon={<CircleButtonPlus color="white" />}
                        sx={shsButtonStyles}
                    />
                </WithRole>
            </Stack>
            <List exporter={false} actions={false}>
                <OrderedDatagrid />
            </List>
        </Container>
    );
};

import { UserRole } from 'src/auth/types';

export const SpecialTeachersGroup = [
    UserRole.SPEECH_THERAPIST,
    UserRole.INTELLECTUAL_DISABILITY_TEACHER,
    UserRole.DEAF_TEACHER,
    UserRole.VISION_TEACHER
];

export const AdminGroup = [UserRole.ADMIN, UserRole.SYSTEM_ADMIN, UserRole.METHODOLOGIST, UserRole.SECRETARIAT];

export const TeachersGroup = [
    UserRole.PRESCHOOL_TEACHER,
    UserRole.ASSISTANT_PRESCHOOL_TEACHER,
    UserRole.JUNIORS_TEACHER,
    UserRole.ASSISTANT_JUNIORS_TEACHER,
    UserRole.TEACHER
];

import { GetListParams, GetOneParams } from 'react-admin';
import axiosApiInstance from 'src/api/axiosConfig';
import { Resource } from 'src/constants';
import { EducationLevelGroup } from 'src/constants/school/schoolLevel.enum';
import { SubjectGroupList } from './types';

export const EducationLevelGradeRange = {
    [EducationLevelGroup.PRESCHOOL]: 'PRESCHOOL_EDUCATION',
    [EducationLevelGroup.JUNIOR_SCHOOL]: 'GRADES_1_TO_4',
    [EducationLevelGroup.HIGH_SCHOOL]: 'GRADES_5_TO_11'
};

const getGroupList = async (params: GetListParams): Promise<SubjectGroupList> => {
    const educationLevelGroup: EducationLevelGroup = params.meta.educationLevelGroup;

    const response = await axiosApiInstance.get(Resource.SUBJECTS, {
        params: {
            targetGradeRange: EducationLevelGradeRange[educationLevelGroup]
        }
    });

    return response.data;
};

export const getOne = (params: GetOneParams) => {
    return axiosApiInstance.get(`${Resource.SUBJECTS}/${params.id}`).then((response) => {
        const { name, planning, id, version } = response.data;
        return {
            data: {
                name,
                planning,
                id,
                version
            }
        };
    });
};

export default { getGroupList, getOne };

import * as React from 'react';
import { SelectInput, SelectInputProps, required as requiredValidate } from 'react-admin';
import { styled } from '@mui/system';
import { Theme } from 'src/Theme';

type Option = {
    value: string;
    name: string;
};

interface ShsSelectInputProps extends SelectInputProps {
    label?: string;
    onChangeValue?: (option: Option) => void;
    disabled?: boolean;
}

export const selectInputSx = {
    '.MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: Theme.palette.primary.main
        },
        '&:focus fieldset': {
            border: `3px solid #75CACB`
        }
    },
    '&.MuiFormControl-root': {
        margin: 0
    },
    '& .MuiInputBase-root': {
        background: '#ffffff'
    },
    '& legend': {
        display: 'none'
    },
    '& .MuiInputBase-input': {
        padding: '11px 16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    }
};

const StyledLabel = styled('label')<{ disabled?: boolean }>(({ disabled }) => ({
    whiteSpace: 'nowrap',
    color: disabled ? '#8899A8' : 'inherit'
}));

const ShsSelectInput: React.FC<ShsSelectInputProps> = ({
    label,
    required,
    onChangeValue,
    sx = {},
    disabled = false,
    ...props
}) => {
    const isRequired = () => (required ? '*' : '');
    const validateFn = [...(required ? [requiredValidate()] : [])];
    const handleChange = (e: any) => {
        const option = e.target as unknown as Option;
        onChangeValue && onChangeValue(option);
    };
    return (
        <div className={`text_field__container top ${disabled ? 'disabled' : ''}`}>
            <StyledLabel className="text_field__label" disabled={disabled}>
                {label || ''}
                {isRequired()}
            </StyledLabel>
            <SelectInput
                validate={validateFn}
                label={false}
                sx={{
                    ...selectInputSx,
                    ...(sx || {}),
                    '& .MuiInputBase-root': {
                        background: disabled ? '#f0f0f0' : '#ffffff',
                        color: disabled ? '#8899A8' : 'inherit'
                    }
                }}
                onChange={handleChange}
                disabled={disabled}
                {...props}
            />
        </div>
    );
};

export default ShsSelectInput;

import * as React from 'react';
import { Show } from 'react-admin';
import { Grid } from '@mui/material';
import { ShsCalendar } from 'src/components/common';

const styles = {
    mt: 0,
    '& .RaShow-main': {
        mt: 0
    }
};

export const SchedulerTab: React.FC = () => (
    <Show
        sx={styles}
        className="show-page-container scheduler"
        queryOptions={{ meta: { useMock: true, tab: 'scheduler' } }}
        actions={false}
    >
        <Grid container columnSpacing={0} rowSpacing={0} sx={{ m: 0 }}>
            <Grid item xs={12}>
                <ShsCalendar />
            </Grid>
        </Grid>
    </Show>
);

export default SchedulerTab;

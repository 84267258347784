import React, { FC } from 'react';
import { List } from 'react-admin';
import { Container } from '@mui/material';

import { listContainerStyles } from 'src/styles';
import { OrderedDatagrid } from './OrderedDataGrid';
import { ToolbarActions } from './ToolbarActions';

export const SpecialServicesList: FC = () => {
    return (
        <Container sx={listContainerStyles}>
            <ToolbarActions />
            <List exporter={false} actions={false}>
                <OrderedDatagrid />
            </List>
        </Container>
    );
};

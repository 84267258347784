import React from 'react';
import { Sidebar } from 'src/components/Layout/Sidebar';
import { Header } from 'src/components/Layout/Header';
import { Layout, LayoutProps } from 'react-admin';
import { Container } from '@mui/material';
import './index.css';

export const CustomLayout = (props: LayoutProps) => {
    return (
        <Layout
            appBar={Header}
            menu={Sidebar}
            appBarAlwaysOn
            sx={{
                '& .RaLayout-appFrame .RaSidebar-fixed': { top: 0, height: '100%' },
                '& .RaLayout-contentWithSidebar > .MuiDrawer-root': { zIndex: 10, height: '100vh' },
                '& > .RaLayout-appFrame': { margin: 0 }
            }}
        >
            <Container maxWidth={false} sx={{ height: '100%' }}>
                {props.children}
            </Container>
        </Layout>
    );
};

import React, { useEffect, useState } from 'react';
import {
    CreateButton,
    Datagrid,
    EditButton,
    FunctionField,
    List,
    TopToolbar,
    useAuthProvider,
    useListContext
} from 'react-admin';
import { Box, Container, Tab, Tabs } from '@mui/material';

import { ReactComponent as EditIcon } from 'src/assets/pencil-alt.svg';
import UnknownAvatar from 'src/components/common/UnknownAvatar';
import ShsSearchInput from 'src/components/common/Fields/ShsSearchInput';
import ShsSelectInput from 'src/components/common/Fields/ShsSelectInput';
import WithRole from 'src/components/common/WithRole';
import ShsTitle from 'src/components/common/ShsTitle';
import ShsAutoCompleteWithLoad from 'src/components/common/Fields/ShsAutoCompleteWithLoad';
import StatusBadge from 'src/components/common/StatusBadge';
import { UserRole } from 'src/auth/types';
import { listContainerStyles } from 'src/styles';
import { FILTER_EMPTY_VALUE, STATUS } from 'src/constants';
import { EmployeeType } from './types';

/**
 * Gets filters based on the provided parameters
 * @param { boolean } showEducationCenterFilter - indicates whether to include the education center filter
 */
const getFilters = (showEducationCenterFilter: boolean) => {
    const filters = [<ShsSearchInput key="1" source="keyword" label="Знайти працівника" alwaysOn />];
    if (showEducationCenterFilter) {
        filters.push(
            <ShsAutoCompleteWithLoad
                key="2"
                source="educationCenterId"
                pageUrl="centers"
                filters={{ isActive: true }}
                emptyValue={FILTER_EMPTY_VALUE}
                emptyText="Усі"
                alwaysOn
                keyField="id"
                textField="shortName"
                resultLimit={100}
                label="Освітній центр"
            />
        );
    }
    filters.push(
        <ShsSelectInput
            key="3"
            source="employmentType"
            label="Працевлаштування"
            alwaysOn
            emptyValue={FILTER_EMPTY_VALUE}
            emptyText="Усі"
            choices={[
                { name: 'Штатне', id: 'STAFF' },
                { name: 'ЦПХ', id: 'CONTRACT' }
            ]}
        />
    );

    return filters;
};

/**
 * Renders the toolbar with actions based on the user's role
 * Displays Add Employee button if the user has the appropriate role
 */
const ToolbarActions = () => {
    return (
        <TopToolbar>
            <WithRole roles={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN]}>
                <CreateButton label="Додати працівника" variant="contained" />
            </WithRole>
        </TopToolbar>
    );
};

/**
 * Grid component that renders a list and optionally notifies about total changes
 */
const Grid: React.FC<{ onTotalChanged?: (total: number) => void }> = ({ onTotalChanged }) => {
    const { total } = useListContext();

    useEffect(() => onTotalChanged?.(total), [total]);

    return (
        <Datagrid bulkActionButtons={false} rowClick="show">
            <FunctionField
                sortBy="surname"
                label="Прізвище, ім’я та по батькові"
                className="bold"
                render={({ surname, name, patronymic }: EmployeeType) => (
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <UnknownAvatar
                            lastName={surname}
                            firstName={name}
                            size="small"
                            style={{ marginRight: '12px' }}
                        />
                        <Box>{`${surname} ${name} ${patronymic || ''}`}</Box>
                    </Box>
                )}
            />

            <FunctionField
                label="Працевлаштування"
                render={({ employees }: EmployeeType) =>
                    employees?.map((record, index) => (
                        <Box key={index}>
                            {[record.staffMember && 'Штатне', record.civilLawNatureContract && 'ЦПХ']
                                .filter((v) => !!v)
                                .join(', ')}
                        </Box>
                    ))
                }
            />

            <FunctionField
                label="Освітні центри"
                render={({ employees }: EmployeeType) =>
                    employees?.map((record, index) => <Box key={index}>{record.educationCenter?.shortName}</Box>)
                }
            />
            <FunctionField
                label="Роль"
                render={({ employees }: EmployeeType) =>
                    employees?.map((record, index) => <Box key={index}>{record.roleDescription}</Box>)
                }
            />
            <FunctionField
                label="Предмет"
                render={({ employees }: EmployeeType) =>
                    employees
                        ?.filter(({ subjects }) => !!subjects?.length)
                        .map(({ subjects }, index) => (
                            <Box key={index}>{subjects.map((subject) => subject.name).join(', ')}</Box>
                        ))
                }
            />
            <FunctionField
                label="Навантаження"
                className="bold"
                render={({ employees }: EmployeeType) =>
                    employees?.map(({ staffLoadPerWeek, contractLoadPerWeek }, index) => (
                        <Box key={index}>{staffLoadPerWeek + contractLoadPerWeek + ' год/тижд'}</Box>
                    ))
                }
            />
            <FunctionField
                label="Статус"
                render={(record: EmployeeType) => {
                    const fired = record.employees?.reduce(
                        (prevVal, currentEmp) =>
                            prevVal && !!currentEmp.dateOfEnding && new Date(currentEmp.dateOfEnding) <= new Date(),
                        true
                    );
                    return (
                        <StatusBadge
                            status={fired ? STATUS.INACTIVE : record.active ? STATUS.ACTIVE : STATUS.SUSPENDED}
                        />
                    );
                }}
            />
            <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]}>
                <EditButton icon={<EditIcon />} label="" />
            </WithRole>
        </Datagrid>
    );
};

/**
 * Manages and displays list of employees
 * Includes tabbed navigation for active and inactive employees and updates the total count of employees
 */
const EmployeeList: React.FC = () => {
    const [activeTab, setActiveTab] = useState<STATUS.ACTIVE | STATUS.INACTIVE>(STATUS.ACTIVE);
    const [total, setTotal] = useState<number | undefined>(0);
    const authProvider = useAuthProvider();

    const currentDate = new Date().toISOString().slice(0, -14);

    return (
        <Container sx={listContainerStyles}>
            <ShsTitle title="Працівники" found={total} />
            <List<EmployeeType>
                resource="employees"
                exporter={false}
                empty={false}
                actions={<ToolbarActions />}
                filters={getFilters(authProvider?.containsRoles([UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]))}
                filterDefaultValues={{ employmentType: FILTER_EMPTY_VALUE, educationCenterId: FILTER_EMPTY_VALUE }}
                filter={
                    activeTab == STATUS.ACTIVE
                        ? { dateOfEndingGreater: currentDate }
                        : { dateOfEndingLess: currentDate }
                }>
                <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                    <Tab value={STATUS.ACTIVE} label="Зараз працюють" />
                    <Tab value={STATUS.INACTIVE} label="Завершили працю" />
                </Tabs>
                <Grid onTotalChanged={(total) => setTotal(total)} />
            </List>
        </Container>
    );
};

export default EmployeeList;

import React from 'react';
import { Filter } from 'react-admin';

import { FILTER_EMPTY_VALUE, EducationLevelList } from 'src/constants';
import { ShsSearchInput, ShsSelectInput, ShsAutoCompleteWithLoad } from 'src/components/common';

interface FiltersProps {
    showEducationCenterFilter?: boolean;
}

const Filters: React.FC<FiltersProps> = ({ showEducationCenterFilter }) => (
    <Filter>
        <ShsSearchInput source="keyword" label="Знайти дитину" alwaysOn />
        {showEducationCenterFilter && (
            <ShsAutoCompleteWithLoad
                source="educationCenterId"
                pageUrl="centers"
                filters={{ isActive: true }}
                emptyValue={FILTER_EMPTY_VALUE}
                emptyText="Усі"
                alwaysOn
                keyField="id"
                textField="shortName"
                resultLimit={100}
                label="Освітній центр"
            />
        )}
        <ShsSelectInput
            source="educationLevel"
            label="Рівень освіти"
            alwaysOn
            emptyValue={FILTER_EMPTY_VALUE}
            emptyText="Усі"
            choices={EducationLevelList}
        />
    </Filter>
);

export default Filters;

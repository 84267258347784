import React from 'react';
import { Button, useNotify, useListContext } from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import axiosApiInstance from 'src/api/axiosConfig';
import { FILTER_EMPTY_VALUE } from 'src/constants';

interface DownloadReportButtonProps {
    pageUrl: string;
    label?: string;
}

const DownloadReportButton: React.FC<DownloadReportButtonProps> = ({ pageUrl, label = 'Download Report' }) => {
    const notify = useNotify();
    const { filterValues } = useListContext();

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };

    const handleDownload = async () => {
        try {
            const educationCenterId =
                filterValues.educationCenterId === FILTER_EMPTY_VALUE ? undefined : filterValues.educationCenterId;
            const startDate = filterValues.startDate ? formatDate(filterValues.startDate) : undefined;
            const endDate = filterValues.endDate ? formatDate(filterValues.endDate) : undefined;

            if (!startDate || !endDate) {
                notify('Будь ласка, виберіть обидві дати початку і закінчення', { type: 'warning' });
                return;
            }

            const response = await axiosApiInstance.get(`reports/${pageUrl}`, {
                params: {
                    educationCenterId,
                    startDate,
                    endDate
                },
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${pageUrl}_report.xlsx`);
            document.body.appendChild(link);
            link.click();

            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }

            window.URL.revokeObjectURL(downloadUrl);

            notify('Звіт завантажено успішно', { type: 'success' });
        } catch (error) {
            console.error('Помилка завантаження звіту:', error);
            notify('Помилка завантаження звіту', { type: 'error' });
        }
    };

    return <Button onClick={handleDownload} label={label} startIcon={<GetAppIcon />} />;
};

export default DownloadReportButton;

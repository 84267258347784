import { Grid, Typography } from '@mui/material';

import { ShsTextInput } from 'src/components/common';
import { inputLabelFontStyle } from 'src/styles';

export const LinkInput: React.FC<{ source: string; label: string; required?: boolean; validate?: any }> = ({
    source,
    label,
    required,
    validate
}) => {
    return (
        <Grid container>
            <Grid item sm={6} alignItems="center" display="flex" height="45px">
                <Typography sx={inputLabelFontStyle}>{label + (required && label ? '*' : '')}</Typography>
            </Grid>
            <Grid item sm={6}>
                <ShsTextInput
                    name={source}
                    source={source}
                    label=""
                    required={required}
                    validate={validate}
                    fullWidth
                    placeholder="Вкажіть лінк на файл"
                    sx={{ width: '100%' }}
                />
            </Grid>
        </Grid>
    );
};

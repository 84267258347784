import React from 'react';
import { TimeInput, TimeInputProps, required as requiredValidate } from 'react-admin';
import { styled } from '@mui/system';

interface ShsTimeInputProps extends TimeInputProps {
    label?: string;
    required?: boolean;
    isAsteriskHidden?: boolean;
    labelPosition?: 'top' | 'left';
}

const styles = {
    '&.MuiFormControl-root': {
        margin: 0
    },
    '& legend': {
        display: 'none'
    },
    '& input.MuiInputBase-input': {
        background: '#ffffff',
        padding: '11px 16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    },
    '& .MuiFormHelperText-root': {
        display: 'none'
    }
};

const StyledTimeInput = styled(TimeInput)(styles);

const parseTime = (value: any) => {
    if (value instanceof Date) {
        return `${value.getHours().toString()}:${value.getMinutes().toString()}:${value.getSeconds().toString()}`;
    }
    return value;
};

const formatTime = (value: any) => {
    if (value === undefined || value === null) {
        return '';
    }
    if (typeof value === 'string') {
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
            return `${date.getHours().toString()}:${date.getMinutes().toString()}:${date.getSeconds().toString()}`;
        }
    }
    return value;
};

const ShsTimeInput: React.FC<ShsTimeInputProps> = (
    { label, required, source, isAsteriskHidden, validate = [], labelPosition = 'top' },
    props
) => {
    const isRequired = () => (required && !isAsteriskHidden ? '*' : '');
    const validateFn = [
        ...(Array.isArray(validate) ? validate : [validate]),
        ...(required ? [requiredValidate()] : [])
    ];

    return (
        <div style={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
            {label && (
                <label style={{ marginRight: '8px' }}>
                    {label}
                    {isRequired()}
                </label>
            )}
            <StyledTimeInput
                source={source}
                validate={validateFn}
                label={false}
                parse={parseTime}
                format={formatTime}
                {...props}
            />
        </div>
    );
};

export default ShsTimeInput;

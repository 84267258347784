import React from 'react';
import { AdminGroup } from 'src/constants/roles';
import { Report } from './Report';
import { WithRole } from '../common';
import NoAccess from '../common/Errors/NoAccess';

export default {
    list: (
        <WithRole roles={[...AdminGroup]} orElse={<NoAccess />}>
            <Report />
        </WithRole>
    )
};

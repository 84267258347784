export const CenterMock = {
    name: 'Освітній центр, розташований у закладі охорони здоров’я «Національна дитяча спеціалізована лікарня «Охматдит» Міністерства охорони здоров’я України»',
    shortName: 'Освітній центр, розташований у закладі охорони здоров’я Охмадит',
    phone: '+3801111111111',
    city: 'Київ',
    address: 'вул.Антоненка, 11',
    workStart: '2024-04-04T06:00:00.657Z',
    workEnd: '2024-04-04T15:00:00.657Z',
    breakStart: '2024-04-04T10:00:00.657Z',
    breakEnd: '2024-04-04T11:00:00.657Z',
    hospitalDepartments: [
        { value: 'Відділення мікрохірургії' },
        { value: 'Відділення абдоміальної хірургії' },
        { value: 'Відділення офтальмології та мікрохірургії ока' }
    ],
    classroomLocations: [
        {
            block: 'A',
            floor: '8',
            name: 'Відділення ургентної хірургії',
            targetGradeRange: ['beforeSchoolEducation']
        },
        {
            building: '1',
            floor: '3',
            maxSeatingCapacity: '8',
            name: 'Відділення урології та нефрології',
            targetGradeRange: ['firstFourthGrade', 'fifthTenthGrade']
        }
    ],
    lessonsDuration: {
        beforeSchoolEducation: {
            lessonDuration: 40,
            weekdays: {
                firstLesson: { from: '', to: '' }
            }
        },
        fifthTenthGrade: {
            lessonDuration: 45
        },
        firstFourthGrade: {
            lessonDuration: 45
        }
    }
};

import React from 'react';
import { Container } from '@mui/material';
import { useCreatePath, Show, TabbedShowLayout } from 'react-admin';

import { tabbedContainerStyles } from 'src/styles';

import { ShsReturnButton } from 'src/components/common';

import CenterTopToolbar from './components/CenterTopToolbar';
import GeneralCenterInfoTab from './components/GeneralCenterInfoTab';
import DepartmentsInfoTab from './components/DepartmentsInfoTab';
import ClassroomLocationsTab from './components/ClassroomLocationsTab';

export const EducationalCenterView: React.FC = () => {
    const createPath = useCreatePath();

    return (
        <Container
            maxWidth={false}
            sx={{
                ...tabbedContainerStyles,
                '& .RaShow-main > .MuiPaper-root .RaTabbedShowLayout-content': {
                    padding: '0'
                }
            }}
        >
            <ShsReturnButton to={createPath({ resource: 'centers', type: 'list' })} label="Назад до усіх центрів" />
            <Show
                className="show-page-container"
                queryOptions={{ meta: { useMock: false } }}
                actions={<CenterTopToolbar />}
            >
                <TabbedShowLayout>
                    <TabbedShowLayout.Tab label="Загальна інформація">
                        <GeneralCenterInfoTab />
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Відділення">
                        <DepartmentsInfoTab />
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Місця проведення уроків">
                        <ClassroomLocationsTab />
                    </TabbedShowLayout.Tab>
                </TabbedShowLayout>
            </Show>
        </Container>
    );
};

import * as React from 'react';
import { SimpleForm, useUpdate, useNotify, useRefresh } from 'react-admin';
import { Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { Resource } from 'src/constants';
import { ShsButton, ShsSaveButton, ShsDatePicker } from 'src/components/common';

const styles = {
    display: 'inline-block',
    '&.MuiCardContent-root, &.MuiToolbar-root': {
        display: 'inline-block',
        p: 0,
        m: 0,
        mt: 0.4
    },
    '&.MuiToolbar-root .MuiButtonBase-root': {
        mt: '-2px'
    }
};

const Toolbar: React.FC = () => {
    return (
        <Box sx={styles} className="add-child-toolbar">
            <ShsSaveButton label="Зберегти" sx={{ height: '45px', ml: 2 }} />
        </Box>
    );
};

export const EmploymentEndDate: React.FC = () => {
    const refresh = useRefresh();
    const [newServiceEndDate, setNewServiceEndDate] = React.useState(false);
    const [update] = useUpdate();
    const notify = useNotify();

    const handleSubmit = (data: any) => {
        const payload = {
            ...data
        };
        update(
            Resource.EMPLOYEES,
            {
                id: data.id,
                data: payload,
                meta: { useMock: false, method: `update` }
            },
            {
                mutationMode: 'optimistic',
                onSuccess: () => {
                    setNewServiceEndDate(true);
                    refresh();
                    notify('Дані збережено', { type: 'success', undoable: true });
                },
                onError: (error: any) => {
                    setNewServiceEndDate(false);
                    notify(`Error: ${error?.message}`, { type: 'warning', undoable: true });
                }
            }
        );
    };
    return (
        <>
            {newServiceEndDate ? (
                <SimpleForm sx={styles} toolbar={<Toolbar />} onSubmit={handleSubmit}>
                    <ShsDatePicker source="created" label="" required />
                </SimpleForm>
            ) : (
                <ShsButton
                    styleType="round"
                    label="Додати"
                    icon={<AddCircleOutlineIcon />}
                    sx={{ height: '24px', border: 'none !important', p: '0 !important', mt: '-5px' }}
                    onClick={() => setNewServiceEndDate(true)}
                />
            )}
        </>
    );
};

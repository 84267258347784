import { regex } from 'react-admin';

export const taxNumberUA = (message = 'РНОКПП має містити 10 цифр') => regex(/\d{10}/, message);

export const minArrayLength =
    (minLength: number, message = `Array must contain at least ${minLength} items."`) =>
    (value: any[]) => {
        if (Array.isArray(value)) {
            return value.length >= minLength ? undefined : message;
        }
        return message;
    };

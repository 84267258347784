import React from 'react';
import { useRecordContext, Datagrid, FunctionField, List } from 'react-admin';
import { Box } from '@mui/material';
import { ClassroomLocation, EducationCenterType } from 'src/components/Employees/types';

const gradesValuesToString: Record<string, string> = {
    PRESCHOOL_EDUCATION: 'Дошкільна освіта',
    GRADES_1_TO_4: '1-4 класи',
    GRADES_5_TO_11: '5-11 класи'
};

const getGradesString = (grades: string[]): string => {
    const gradesString = grades.map((gradeEntity: string) => gradesValuesToString[gradeEntity]).join(', ');
    return gradesString;
};

const ClassroomLocationsTab: React.FC = () => {
    const record = useRecordContext<EducationCenterType>();
    if (!record) return null;
    const { classroomLocations } = record;

    return (
        <List
            actions={false}
            pagination={false}
            sx={{
                '.RaList-content': {
                    marginTop: 0
                },
                border: 'none'
            }}
        >
            <Datagrid bulkActionButtons={false} data={classroomLocations}>
                <FunctionField
                    label="Назва місця"
                    render={({ name }: ClassroomLocation) => <Box py="16px">{name}</Box>}
                />
                <FunctionField
                    label="Корпус"
                    render={({ building }: ClassroomLocation) => <Box py="16px">{building}</Box>}
                />
                <FunctionField label="Поверх" render={({ floor }: ClassroomLocation) => <Box py="16px">{floor}</Box>} />
                <FunctionField label="Блок" render={({ block }: ClassroomLocation) => <Box py="16px">{block}</Box>} />
                <FunctionField
                    label="№ кабінету"
                    render={({ roomNumber }: ClassroomLocation) => <Box py="16px">{roomNumber}</Box>}
                />
                <FunctionField
                    label="Макс. кількість людей"
                    render={({ maxSeatingCapacity }: ClassroomLocation) => <Box py="16px">{maxSeatingCapacity}</Box>}
                />
                <FunctionField
                    label="Призначене для"
                    render={({ targetGradeRange }: ClassroomLocation) => (
                        <Box py="16px">{getGradesString(targetGradeRange)}</Box>
                    )}
                />
            </Datagrid>
        </List>
    );
};

export default ClassroomLocationsTab;

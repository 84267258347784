import React, { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { ReactComponent as Logo } from 'src/assets/logo.svg';
import { ReactComponent as DiiaLogo } from 'src/assets/diia-button.svg';
import { Container, Tab, Tabs } from '@mui/material';
import { STATUS } from 'src/constants';
import './index.css';
import QrCode from './QrCode';

const LoginPage: React.FC = () => {
    const [taxNumber, setTaxNumber] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showQrCode, setShowQrCode] = useState<boolean>(false);
    const fakeAuthEnabled: boolean = process.env.REACT_APP_ENABLE_FAKE_AUTH === 'true';
    const [diiaActivated, setDiiaActivated] = useState<STATUS.ACTIVE | STATUS.INACTIVE>(
        fakeAuthEnabled ? STATUS.INACTIVE : STATUS.ACTIVE
    );

    const login = useLogin();
    const notify = useNotify();

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => setTaxNumber(e.currentTarget.value);

    const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value);

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        login({ taxNumber, password }).catch(() => notify('Неправильний РНОКПП чи пароль'));
    };

    const getTemporaryAccess = () => {
        //setDiiaActivated(false);
        setShowQrCode(false);
    };

    return (
        <React.Fragment>
            <div className="page_container">
                <div className="login_container">
                    <Logo />
                    {fakeAuthEnabled && (
                        <Tabs
                            value={diiaActivated}
                            onChange={(e, value) => {
                                if (value == STATUS.INACTIVE) setShowQrCode(false);
                                setDiiaActivated(value);
                            }}
                        >
                            <Tab value={STATUS.INACTIVE} label="Тимчасовий вхід" />
                            <Tab value={STATUS.ACTIVE} label="Вхід через Дію" />
                        </Tabs>
                    )}

                    {diiaActivated == STATUS.INACTIVE && fakeAuthEnabled && (
                        <div>
                            <form onSubmit={handleSubmit} className="login_form" noValidate>
                                <input
                                    className="login_form__input"
                                    name="taxNumbers"
                                    type="text"
                                    pattern="[0-9]{10}"
                                    placeholder="РНОКПП"
                                    value={taxNumber}
                                    onChange={handleInputChange}
                                />
                                <input
                                    className="login_form__input"
                                    name="password"
                                    type="password"
                                    placeholder="Пароль"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                                <button className="login_from__login_button">Увійти</button>
                            </form>
                            <button className="login__forgot_password">Забули пароль?</button>
                        </div>
                    )}
                    {diiaActivated == STATUS.ACTIVE && !showQrCode && (
                        <div>
                            <div className={`login-diia-info ${fakeAuthEnabled ? '' : 'fake-auth-not-enabled'}`}>
                                Авторизуйтесь для входу в кабінет
                            </div>
                            <div className="login-diia-button-container">
                                <button className="login-diia-qr-button" onClick={() => setShowQrCode(true)}>
                                    <DiiaLogo />
                                </button>
                            </div>
                        </div>
                    )}
                    {diiaActivated == STATUS.ACTIVE && <QrCode showQrCode={showQrCode} />}
                </div>
            </div>
        </React.Fragment>
    );
};

export default LoginPage;

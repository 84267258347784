export * as validate from './validate';
export * as childrenUtils from './children';

export const getCurrentAgeInUAFormat = (dateOfBirthString: string): string => {
    const dateOfBirth = new Date(dateOfBirthString);
    const birthYear = dateOfBirth.getFullYear();
    const currentYear = new Date().getFullYear();
    let age = currentYear - birthYear;

    const lastDigit = age % 10;
    const secondLastDigit = age % 100;
    let result = '';
    if (secondLastDigit >= 11 && secondLastDigit <= 19) {
        result = `${age} років`;
    } else if (lastDigit === 1) {
        result = `${age} рік`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        result = `${age} роки`;
    } else {
        result = `${age || 0} років`;
    }
    return result;
};

export const formatPhoneNumber = (phoneNumber: string): number => {
    const countryCode = phoneNumber.substring(1, 3);
    const areaCode = phoneNumber.substring(3, 6);
    const localNumber = phoneNumber.substring(6);

    return `+${countryCode} (${areaCode}) ${localNumber.substring(0, 3)}-${localNumber.substring(
        3,
        5
    )}-${localNumber.substring(5)}` as unknown as number;
};

export const hashCode = (s: string) => {
    return s.split('').reduce((hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0, 0);
};

export const getYesNoFromBoolean = (value: boolean): string => (value ? 'так' : 'ні');

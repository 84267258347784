import React from 'react';
import { AppBar } from 'react-admin';
import UserMenu from 'src/components/UserMenu';
import { palette } from '../../../Theme';
import './index.css';

const HeaderStyles = {
    boxShadow: 'none',
    zIndex: 9,
    '& .RaAppBar-toolbar': { background: '#FFFFFF', borderBottom: `1px solid ${palette.primary.inputBorder}` },
    '& .RaAppBar-toolbar > .RaAppBar-menuButton': { display: 'none' },
    '& .RaAppBar-toolbar > .RaAppBar-title > span.RaConfigurable-root': { display: 'none' }
};

export const Header = () => <AppBar sx={HeaderStyles} container="div" userMenu={<UserMenu />} />;

import React, { FC } from 'react';
import { Create, RaRecord, SimpleForm, useCreatePath, useNotify, useRedirect } from 'react-admin';
import { Typography, Container } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import ShsFormToolbar from 'src/components/common/ShsFormToolbar';
import { Resource } from 'src/constants';
import { palette } from '../../../Theme';
import { SpecialServiceInputsCard } from '../common/SpecialServiceInputsCard';

export const CreateSpecialService: FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const notify = useNotify();

    const handleCancelBtnClick = () => {
        redirect(createPath({ resource: Resource.SPECIAL_SERVICES, type: 'list' }));
    };

    return (
        <Container>
            <ShsReturnButton
                to={createPath({ resource: Resource.SPECIAL_SERVICES, type: 'list' })}
                label="Назад до спеціальних послуг"
                sx={{ color: palette.primary.grayBluish }}
            />
            <Typography fontSize={40} fontWeight={700} sx={{ mb: 3 }}>
                Додати послугу
            </Typography>

            <Create
                mutationOptions={{
                    onSuccess: (data: RaRecord) => {
                        notify('Послугу успішно додано', {
                            type: 'success',
                            autoHideDuration: 2500
                        });
                        redirect('edit', Resource.SPECIAL_SERVICES, data.id, data);
                    }
                }}
                sx={{ '.RaCreate-card': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                <SimpleForm toolbar={false} mode="onBlur" sx={{ padding: 0 }}>
                    <SpecialServiceInputsCard />
                    <ShsFormToolbar submitLabel="Додати послугу" handleCancel={handleCancelBtnClick} />
                </SimpleForm>
            </Create>
        </Container>
    );
};

import React, { CSSProperties } from 'react';
import { palette } from '../../Theme';
import { hashCode } from '../../utils';
import { SIZE_CONFIG } from 'src/constants';

export type UnknownAvatarProps = {
    firstName?: string;
    lastName: string;
    size: keyof typeof SIZE_CONFIG;
    style?: CSSProperties;
};

const UnknownAvatar: React.FC<UnknownAvatarProps> = ({ firstName, lastName, size = 'small', style = {} }) => {
    const sizeConfig = SIZE_CONFIG[size];

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: sizeConfig.circle,
                minWidth: sizeConfig.circle,
                height: sizeConfig.circle,
                minHeight: sizeConfig.circle,
                backgroundColor:
                    palette.lessonColors[Math.abs(hashCode(`${lastName} ${firstName}`) % palette.lessonColors.length)],
                color: 'white',
                fontSize: sizeConfig.fontSize,
                borderRadius: '50%',
                fontWeight: '800',
                ...style
            }}>
            {lastName.charAt(0)?.toUpperCase() +
                (firstName ? firstName.charAt(0)?.toUpperCase() : lastName.charAt(1)?.toLowerCase())}
        </div>
    );
};

export default UnknownAvatar;

export enum EducationStage {
    YEARS4 = 'YEARS4',
    YEARS5 = 'YEARS5',
    YEARS6 = 'YEARS6',
    GRADE1 = 'GRADE1',
    GRADE2 = 'GRADE2',
    GRADE3 = 'GRADE3',
    GRADE4 = 'GRADE4',
    GRADE5 = 'GRADE5',
    GRADE6 = 'GRADE6',
    GRADE7 = 'GRADE7',
    GRADE8 = 'GRADE8',
    GRADE9 = 'GRADE9',
    GRADE10 = 'GRADE10',
    GRADE11 = 'GRADE11'
}

export enum EducationStagePlanning {
    YEARS_4 = 'years_4',
    YEARS_5 = 'years_5',
    YEARS_6 = 'years_6',
    GRADE_1 = 'grade_1',
    GRADE_2 = 'grade_2',
    GRADE_3 = 'grade_3',
    GRADE_4 = 'grade_4',
    GRADE_5 = 'grade_5',
    GRADE_6 = 'grade_6',
    GRADE_7 = 'grade_7',
    GRADE_8 = 'grade_8',
    GRADE_9 = 'grade_9',
    GRADE_10 = 'grade_10',
    GRADE_11 = 'grade_11'
}

import { DataProvider } from 'react-admin';

import {
    create,
    getList,
    getGroupList,
    getOne,
    getMany,
    getManyReference,
    update,
    updateMany,
    deleteApi,
    deleteMany
} from './REST';

const dataProvider: DataProvider = {
    getList,
    getOne,
    getMany,
    getManyReference,
    update,
    updateMany,
    create,
    delete: deleteApi,
    deleteMany,
    getGroupList
};

export default dataProvider;

import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';

import { interFontStyle } from 'src/styles';
import CircleWarning from 'src/assets/CircleWarning';

export const DeleteModal: React.FC = () => {
    const record = useRecordContext();

    const name = `${record.lastName} ${record.firstName} ${record.patronymic}`.trim();
    return (
        <>
            <div className="shs-modal-header">
                <Box sx={{ textAlign: 'center' }}>
                    <CircleWarning />
                </Box>
                <Typography variant="h6" component="h6" mb={2} mt={1} fontWeight="600" align="center">
                    Видалення Дитини
                </Typography>
            </div>
            <div className="shs-modal-body">
                <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                    Чи впевнені ви що хочете видалити: <strong>{name}</strong>?
                </Typography>
                <Typography component="div" mb={4} align="center" sx={interFontStyle}>
                    Після видалення дитини всі дані про неї, розклади та звіти будуть видалені й не підлягатимуть
                    відновленню.
                </Typography>
            </div>
        </>
    );
    // <div className="shs-modal-footer">
    //     <ShsButton label="Скасувати" styleType="outline" onClick={onClose} />
    //     <ShsButton label="Видалити" styleType="filled-danger" onClick={handleConfirm} />
    // </div>
};

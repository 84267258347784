import { Theme } from 'src/Theme';

export const editContainerStyles = {
    maxWidth: '748px',
    mt: 2,
    '& .RaCreate-main>.MuiPaper-root, & .RaEdit-main>.MuiPaper-root': {
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        overflow: 'visible'
    },
    '& form>.MuiCardContent-root': {
        background: '#fff',
        p: '24px 32px',
        borderRadius: '6px'
    },
    '& .edit-page ': {
        position: 'relative',
        marginTop: '1em'
    },
    '& .edit-page > .MuiToolbar-root': {
        position: 'absolute !important',
        right: 0
    }
};

export const listContainerStyles = {
    marginTop: '34px',
    display: 'flex',
    flexDirection: 'column'
};

export const tabbedContainerStyles = {
    padding: '24px 0 !important',
    minHeight: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiButton-root': {
        width: 'fit-content'
    },
    '& > .show-page-container': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%'
    },
    '&  .RaShow-main': {
        flex: 1
    },
    '& .MuiToolbar-root': { background: 'white', borderRadius: '6px 6px 0 0', padding: '24px' },
    '& .RaShow-main > .MuiPaper-root': {
        borderRadius: '0 0 8px 8px',
        boxShadow: 'none'
    },
    '& .RaShow-main > .MuiPaper-root .RaTabbedShowLayout-content': {
        padding: '24px'
    },
    '& .MuiTabs-root .MuiTabs-flexContainer': {
        background: Theme.palette.primary.creamyWhite
    },
    '& .MuiTabs-root .show-tab': {
        textTransform: 'none',
        fontWeight: 500,
        lineHeight: '24px',
        fontSize: '16px',
        color: Theme.palette.primary.grayBluish
    },
    '& .MuiTabs-root .show-tab.Mui-selected': {
        color: Theme.palette.primary.main
    }
};

export type AuthUserType = {
    access_token: string;
    refresh_token: string;
    profile: UserType;
};

export type UserType = {
    id: number;
    taxNumber: string;
    name: string;
    surname: string;
    patronymic: string;
    email: string;
    phone: string;
    authorities: UserRole[];
    active: boolean;
};

export enum UserRole {
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    ADMIN = 'ADMIN',
    SECRETARIAT = 'SECRETARIAT',
    PRESCHOOL_TEACHER = 'PRESCHOOL_TEACHER',
    ASSISTANT_PRESCHOOL_TEACHER = 'ASSISTANT_PRESCHOOL_TEACHER',
    JUNIORS_TEACHER = 'JUNIORS_TEACHER',
    ASSISTANT_JUNIORS_TEACHER = 'ASSISTANT_JUNIORS_TEACHER',
    TEACHER = 'TEACHER',
    PSYCHOLOGIST = 'PSYCHOLOGIST',
    PARENT = 'PARENT',
    SPEECH_THERAPIST = 'SPEECH_THERAPIST',
    INTELLECTUAL_DISABILITY_TEACHER = 'INTELLECTUAL_DISABILITY_TEACHER',
    VISION_TEACHER = 'VISION_TEACHER',
    DEAF_TEACHER = 'DEAF_TEACHER',
    METHODOLOGIST = 'METHODOLOGIST'
}

import React from 'react';
import { RadioButtonGroupInput, RadioButtonGroupInputProps, useInput, required as requiredValidate } from 'react-admin';

import { shsFormErrorStyles } from 'src/styles';
import { BooleanStringMapping } from 'src/constants';

interface ShsRadioButtonGroupProps extends RadioButtonGroupInputProps {
    label?: string;
}

const ShsRadioButtonGroup: React.FC<ShsRadioButtonGroupProps> = ({
    label,
    required,
    source = '',
    onChange,
    ...props
}) => {
    const [value, setValue] = React.useState('');
    const isRequired = () => (required ? '*' : '');
    const validateFn = [...(required ? [requiredValidate()] : [])];
    const { field } = useInput({ source, isRequired: required, validate: validateFn });
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        setValue(target.value);

        const mappedValue = BooleanStringMapping[target.value];
        const result = mappedValue !== undefined ? mappedValue : target.value;
        field.onChange(result);
        onChange && onChange(result);
    };
    return (
        <div className="text_field__container top">
            {label && (
                <label className="text_field__label">
                    {label || ''}
                    {isRequired()}
                </label>
            )}
            <RadioButtonGroupInput
                value={value}
                source={source}
                onChange={handleChange}
                label={false}
                sx={{ ...shsFormErrorStyles, m: 0 }}
                validate={validateFn}
                {...props}
            />
        </div>
    );
};

export default ShsRadioButtonGroup;

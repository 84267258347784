import React from 'react';
import { useNavigate } from 'react-router-dom';
import ShsButton from 'src/components/common/Fields/ShsButton';
import { ReactComponent as NotFoundLogo } from 'src/assets/non-found.svg';

import '../index.css';

const NotFound = () => {
    const navigate = useNavigate();
    const handleClick = () => navigate('/');

    return (
        <div className="not_found_container">
            <div className="not_found_logo">
                <NotFoundLogo />
            </div>
            <div className="not_found_content">
                <h1 className="code_error">404</h1>
                <h4 className="not_found_title">Вибачте, такої сторінки не існує</h4>
                <span className="not_found_content_title">
                    Схоже, це неправильна адреса, ця сторінка видалена, перейменована або тимчасово недоступна.
                </span>
                <ShsButton label="Повернутись на головну" onClick={handleClick} sx={{ maxWidth: 'fit-content' }} />
            </div>
        </div>
    );
};

export default NotFound;

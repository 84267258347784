import React, { useState } from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ShsSelectInput, ShsTimeInput, ShsButton } from 'src/components/common';
import { CenterFormFields, LessonsDurationList } from 'src/constants/centers';

interface SchoolLesson {
    id: string;
    start: { label: string; source: string };
    end: { source: string };
}

interface CenterLessonsDurationProps {
    educationalLevel: string;
    lessonDurationSource: string;
    schoolLessons: (days: CenterFormFields) => SchoolLesson[];
    isTitleShown?: boolean;
}

export const CenterLessonsDuration: React.FC<CenterLessonsDurationProps> = ({
    educationalLevel,
    lessonDurationSource,
    schoolLessons,
    isTitleShown
}) => {
    const [showWeekends, setShowWeekends] = useState(false);

    const handleClick = () => {
        setShowWeekends((showWeekends) => !showWeekends);
    };

    return (
        <>
            {isTitleShown && (
                <Typography variant="h6" component="h6" mb={3} fontWeight="600">
                    Тривалість уроків
                </Typography>
            )}
            <Typography variant="h6" component="h6" lineHeight="1.5rem" mb={3} fontWeight="500">
                {educationalLevel}
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item xs={4}>
                    <ShsSelectInput
                        source={lessonDurationSource}
                        label="Тривалість уроків (хв)"
                        choices={LessonsDurationList}
                        required
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    {schoolLessons(CenterFormFields.WEEKDAYS)
                        .slice(0, 4)
                        .map(({ id, start, end }) => (
                            <Grid key={id} sx={{ display: 'flex', gap: '8px' }}>
                                <ShsTimeInput source={start.source} label={start.label} />
                                <Divider
                                    sx={{
                                        width: '7px',
                                        borderColor: 'black',
                                        borderWidth: '1px',
                                        alignSelf: 'center',
                                        marginTop: '8px'
                                    }}
                                />
                                <ShsTimeInput source={end.source} />
                            </Grid>
                        ))}
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    {schoolLessons(CenterFormFields.WEEKDAYS)
                        .slice(4)
                        .map(({ id, start, end }) => (
                            <Grid key={id} sx={{ display: 'flex', gap: '8px' }}>
                                <ShsTimeInput source={start.source} label={start.label} />
                                <Divider
                                    sx={{
                                        width: '7px',
                                        borderColor: 'black',
                                        borderWidth: '1px',
                                        alignSelf: 'center',
                                        marginTop: '8px'
                                    }}
                                />
                                <ShsTimeInput source={end.source} />
                            </Grid>
                        ))}
                </Grid>
            </Grid>
            {showWeekends && (
                <>
                    <Divider
                        sx={{
                            borderColor: '#D9D9D9',
                            borderWidth: '1px',
                            width: '100%',
                            alignSelf: 'center',
                            m: '24px'
                        }}
                    />
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            {schoolLessons(CenterFormFields.WEEKENDS)
                                .slice(0, 4)
                                .map(({ id, start, end }) => (
                                    <Grid key={id} sx={{ display: 'flex', gap: '8px' }}>
                                        <ShsTimeInput source={start.source} label={start.label} />
                                        <Divider
                                            sx={{
                                                width: '7px',
                                                borderColor: 'black',
                                                borderWidth: '1px',
                                                alignSelf: 'center',
                                                marginTop: '8px'
                                            }}
                                        />
                                        <ShsTimeInput source={end.source} />
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            {schoolLessons(CenterFormFields.WEEKENDS)
                                .slice(4)
                                .map(({ id, start, end }) => (
                                    <Grid key={id} sx={{ display: 'flex', gap: '8px' }}>
                                        <ShsTimeInput source={start.source} label={start.label} />
                                        <Divider
                                            sx={{
                                                width: '7px',
                                                borderColor: 'black',
                                                borderWidth: '1px',
                                                alignSelf: 'center',
                                                marginTop: '8px'
                                            }}
                                        />
                                        <ShsTimeInput source={end.source} />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                </>
            )}
            <ShsButton
                styleType="text"
                label={
                    showWeekends ? 'Приховати інші години для суботи-неділі' : 'Додати інші години для суботи-неділі'
                }
                icon={<AddCircleOutlineIcon />}
                onClick={handleClick}
            />
            <Divider
                sx={{
                    borderColor: '#D9D9D9',
                    borderWidth: '1px',
                    width: '100%',
                    alignSelf: 'center',
                    m: '10px'
                }}
            />
        </>
    );
};

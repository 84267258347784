import React from 'react';
import { EducationalCentersList } from 'src/components/EducationalCenters/EducationalCentersList';
import { EducationalCenterAdd } from 'src/components/EducationalCenters/EducationalCenterAdd';
import { EducationalCenterEdit } from 'src/components/EducationalCenters/EducationalCenterEdit';
import { EducationalCenterView } from 'src/components/EducationalCenters/EducationalCenterView';
import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';
import { AdminGroup, TeachersGroup } from 'src/constants/roles';
import NoAccess from '../common/Errors/NoAccess';

export default {
    list: (
        <WithRole roles={[...AdminGroup, UserRole.PSYCHOLOGIST, ...TeachersGroup]} orElse={<NoAccess />}>
            <EducationalCentersList />
        </WithRole>
    ),
    show: (
        <WithRole roles={[...AdminGroup, UserRole.PSYCHOLOGIST, ...TeachersGroup]} orElse={<NoAccess />}>
            <EducationalCenterView />
        </WithRole>
    ),
    create: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN]}>
            <EducationalCenterAdd />
        </WithRole>
    ),
    edit: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN]}>
            <EducationalCenterEdit />
        </WithRole>
    )
};

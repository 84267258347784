import React, { FC } from 'react';
import { Edit, SimpleForm, useCreatePath, useRecordContext, useRedirect } from 'react-admin';
import { Box, Container, Stack, Typography } from '@mui/material';

import { ShsReturnButton } from '../../../components/common';
import { Resource } from '../../../constants';
import { SubjectsEditCard } from './SubjectsEditCard';
import ShsFormToolbar from '../../common/ShsFormToolbar';
import CircleWarning from '../../../assets/CircleWarning';
import { interFontStyle } from 'src/styles';
import { UserRole } from 'src/auth/types';

export const DeleteSubjectConfirmation: FC = () => {
    const record = useRecordContext();

    return (
        <Stack alignItems="center">
            <div className="shs-modal-header">
                <Box mb={3} sx={{ textAlign: 'center' }}>
                    <CircleWarning />
                </Box>
                <Typography variant="h6" component="h6" mb={2} mt={1} fontWeight="600" align="center">
                    Видалення предмету
                </Typography>
            </div>
            <div className="shs-modal-body">
                <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                    {`Чи впевнені ви що хочете видалити предмет «${record?.name}»?`}
                </Typography>
                <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                    {`Після видалення предмету він буде видалений у всіх розкладах.`}
                </Typography>
            </div>
        </Stack>
    );
};

const SubjectEdit: FC = () => {
    const createPath = useCreatePath();
    const redirect = useRedirect();

    const handleCancelBtnClick = () => {
        redirect(createPath({ resource: Resource.SUBJECTS, type: 'list' }));
    };

    return (
        <Container>
            <ShsReturnButton
                to={createPath({ resource: Resource.SUBJECTS, type: 'list' })}
                label="Назад до навчального плану"
            />
            <Typography fontSize={40} fontWeight={700} sx={{ mb: 3 }}>
                Редагувати предмет
            </Typography>
            <Edit sx={{ '.RaEdit-card': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                <SimpleForm mode="onBlur" noValidate={true} toolbar={false} sx={{ padding: 0 }}>
                    <SubjectsEditCard />
                    <ShsFormToolbar
                        enableDeleteButton
                        handleCancel={handleCancelBtnClick}
                        deleteConfirmContent={<DeleteSubjectConfirmation />}
                        enableDeleteForRoles={[UserRole.SYSTEM_ADMIN]}
                    />
                </SimpleForm>
            </Edit>
        </Container>
    );
};

export default SubjectEdit;

import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { extraSmallGrayFontStyle, titleFontStyle } from '../../styles';

const BoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    marginBottom: '12px',
    gap: '4px'
};

const ShsTitle: FC<{ title?: string; found?: number; sx?: Record<string, any>; foundTitle?: string }> = ({
    title,
    found,
    sx = {},
    foundTitle = 'Знайдено'
}) => {
    return title ? (
        <Box className="ShsTitle-root" sx={BoxStyles}>
            <Typography sx={{ ...titleFontStyle, ...sx }}>{title}</Typography>
            {found != null && (
                <Typography sx={extraSmallGrayFontStyle} paddingBottom="4px">
                    {`(${foundTitle}: ${found})`}
                </Typography>
            )}
        </Box>
    ) : null;
};

export default ShsTitle;

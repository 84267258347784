import React from 'react';
import { Typography, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';

import { ShsTextInput, ShsButton } from 'src/components/common';
import { CenterFormFields } from 'src/constants/centers';

import { disableFirstRemoveStyle, formIteratorStyles } from 'src/styles';

export const CenterDepartment: React.FC = () => {
    return (
        <>
            <Typography variant="h6" component="h6" mb={3} fontWeight="600">
                Відділення
            </Typography>
            <ArrayInput source={CenterFormFields.CENTER_DEPARTMENTS} label="">
                <SimpleFormIterator
                    addButton={<ShsButton styleType="text" label="Додати відділення" icon={<AddCircleOutlineIcon />} />}
                    disableReordering
                    disableClear
                    fullWidth
                    sx={{ ...disableFirstRemoveStyle, ...formIteratorStyles }}
                >
                    <FormDataConsumer>
                        {({ getSource }) => (
                            <Grid container columnSpacing={4} rowSpacing={1} sx={{ mt: 2 }}>
                                <Grid item xs={12}>
                                    <ShsTextInput
                                        source={getSource('departmentName')}
                                        label="Назва відділення"
                                        required
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};

import React from 'react';
import { ChildrenList } from './ChildrenList';
import { ChildAdd, ChildEdit } from './ChildAddEdit';
import { ChildShow } from './ChildShow';
import { WithRole } from '../common';
import { UserRole } from 'src/auth/types';
import NoAccess from '../common/Errors/NoAccess';

export default {
    list: ChildrenList,
    show: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]} orElse={<NoAccess />}>
            <ChildShow />
        </WithRole>
    ),
    create: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]} orElse={<NoAccess />}>
            <ChildAdd />
        </WithRole>
    ),
    edit: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]} orElse={<NoAccess />}>
            <ChildEdit />
        </WithRole>
    )
};

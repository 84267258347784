import React from 'react';
import cn from 'classnames';
import { SaveButton } from 'react-admin';
import type { SaveButtonProps } from 'react-admin';

import { shsButtonStyles } from 'src/styles';

interface ShsSaveButtonProps extends SaveButtonProps {
    styleType?: 'filled' | 'outline';
    iconType?: 'none' | 'edit';
    label: string;
}

const ShsSaveButton: React.FC<ShsSaveButtonProps> = (
    { label, disabled, styleType = 'filled', type = 'submit', onClick = () => {}, alwaysEnable = false, sx },
    props
) => (
    <SaveButton
        className={cn('shs-button', styleType, disabled ? 'disabled_button' : '')}
        disabled={disabled}
        type={type}
        icon={null}
        onClick={onClick}
        label={label}
        alwaysEnable={alwaysEnable}
        sx={{ ...shsButtonStyles, ...sx }}
        {...props}
    />
);

export default ShsSaveButton;

import * as React from 'react';
import { Create, FormGroupContextProvider, TabbedForm, useCreatePath, useRedirect } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { editContainerStyles } from 'src/styles';
import { palette, Theme } from 'src/Theme';
import { ReactComponent as ChevronRight } from 'src/assets/chevron-right.svg';
import { EmployeeType } from './types';
import EmploymentTypeForm from './form/EmploymentTypeForm';
import { TabLabel } from './components/TabLabel';
import EmployeeDocumentsForm from './form/EmployeeDocumentsForm';
import useTabbedFormNavigation from './useTabIndex';
import EmployeePersonalDetailsForm from './form/EmployeePersonalDetailsForm';
import tabGroups from './const/tab-groups';
import { EmployeeCreateToolbar } from './form/EmployeeCreateToolbar';

const tabbedFormStyles = {
    'a.form-tab.MuiTab-labelIcon': {
        flex: '0 1 auto'
    },
    '.MuiTabs-indicator': {
        display: 'none'
    },
    '.Mui-selected.Mui-disabled': {
        color: Theme.palette.primary.main
    }
};

const tabStyles = {
    padding: 0,
    '&.MuiTab-labelIcon': {
        flexFlow: 'row-reverse'
    },
    '.MuiTab-iconWrapper': {
        margin: '0 32px'
    }
};

/**
 * Employee Create page
 */
export const EmployeeCreate: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const { tabIndex } = useTabbedFormNavigation();

    const onSuccess = () => {
        redirect(createPath({ resource: 'employees', type: 'list' }));
    };

    return (
        <Container sx={editContainerStyles} maxWidth={false}>
            <ShsReturnButton
                to={createPath({ resource: 'employees', type: 'list' })}
                label="Назад до списку працівників"
            />
            <Create<EmployeeType> mutationOptions={{ onSuccess }}>
                <Typography variant="h4" component="h3" mb={4} fontWeight="700">
                    Додати працівника
                </Typography>
                <TabbedForm toolbar={<EmployeeCreateToolbar />} sx={tabbedFormStyles} mode="onBlur">
                    <TabbedForm.Tab
                        label={<TabLabel label="Персональна інформація" index={0} />}
                        icon={<ChevronRight stroke={palette.primary.gray} />}
                        sx={tabStyles}
                        disabled={tabIndex === 0}>
                        <FormGroupContextProvider name={tabGroups[0]}>
                            <EmployeePersonalDetailsForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab
                        label={<TabLabel label="Документи" index={1} />}
                        icon={<ChevronRight stroke={palette.primary.gray} />}
                        sx={tabStyles}
                        disabled={tabIndex < 1}>
                        <FormGroupContextProvider name={tabGroups[1]}>
                            <EmployeeDocumentsForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab
                        label={<TabLabel label="Працевлаштування" index={2} />}
                        sx={tabStyles}
                        disabled={tabIndex < 2}>
                        <FormGroupContextProvider name={tabGroups[2]}>
                            <EmploymentTypeForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                </TabbedForm>
            </Create>
        </Container>
    );
};

import React, { CSSProperties } from 'react';
import { palette } from '../../Theme';
import { hashCode } from '../../utils';
import { SIZE_CONFIG } from 'src/constants';
import UnknownAvatar from './UnknownAvatar';

export type PhotoAvatarProps = {
    firstName: string;
    lastName: string;
    url?: string;
    size: keyof typeof SIZE_CONFIG;
    style?: CSSProperties;
};

const PhotoAvatar: React.FC<PhotoAvatarProps> = ({ url, size = 'small', firstName, lastName, style = {} }) => {
    const sizeConfig = SIZE_CONFIG[size];

    return url?.length ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: sizeConfig.circle,
                minWidth: sizeConfig.circle,
                height: sizeConfig.circle,
                minHeight: sizeConfig.circle,
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white',
                fontSize: sizeConfig.fontSize,
                borderRadius: '50%',
                fontWeight: '800',
                ...style
            }}></div>
    ) : (
        <UnknownAvatar lastName={lastName} firstName={firstName} size={size} style={{ marginRight: '12px' }} />
    );
};

export default PhotoAvatar;

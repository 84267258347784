import React from 'react';
import { CreateLaw } from './Create/CreateLaw';
import { LawsList } from './List/LawsList';
import { EditLaw } from './Edit/EditLaw';
import { WithRole } from '../common';
import { AdminGroup, TeachersGroup } from 'src/constants/roles';
import NoAccess from '../common/Errors/NoAccess';
import { UserRole } from 'src/auth/types';

export default {
    list: (
        <WithRole roles={[...AdminGroup, UserRole.PSYCHOLOGIST, ...TeachersGroup]} orElse={<NoAccess />}>
            <LawsList />
        </WithRole>
    ),
    create: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]} orElse={<NoAccess />}>
            <CreateLaw />
        </WithRole>
    ),
    edit: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]} orElse={<NoAccess />}>
            <EditLaw />
        </WithRole>
    )
};

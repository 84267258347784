import React from 'react';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { initAxios } from './api/axiosConfig';
import './assets/font/inter.css';

initAxios();
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

import React, { ReactNode } from 'react';
import { useAuthProvider } from 'react-admin';
import { UserRole } from '../../auth/types';

interface WithRoleProps {
    roles?: UserRole | UserRole[];
    atLeastOneRole?: boolean;
    children: ReactNode;
    orElse?: ReactNode;
}

export default function WithRole({ roles, atLeastOneRole = true, children, orElse }: WithRoleProps) {
    const authProvider = useAuthProvider();

    return !roles || (Array.isArray(roles) && !roles.length) ? (
        <>{children}</>
    ) : authProvider?.containsRoles(roles, atLeastOneRole) ? (
        <>{children}</>
    ) : (
        <>{orElse}</>
    );
}

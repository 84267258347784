import * as React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { palette } from 'src/Theme';

const chipStylesBase = {
    fontWeight: 500,
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    '.MuiChip-root': {
        flexShrink: 0
    },
    '.MuiChip-label': {
        padding: 0
    }
};

export const TabLabel: React.FC<{ label: string; index: number }> = ({ label, index = 0 }) => {
    const { pathname } = useLocation();

    const tabIndex = Number(pathname.split('/').pop()) || 0;
    const isActive = tabIndex === index;
    const isCompleted = tabIndex > index;

    return (
        <Box display={'flex'} alignItems="center" gap={1}>
            <Chip
                label={index + 1}
                variant={isActive ? 'filled' : 'outlined'}
                sx={{
                    ...chipStylesBase,
                    borderColor: isCompleted ? palette.primary.main : palette.primary.inputBorder,
                    background: isActive ? palette.primary.main : 'transparent',
                    color: isActive
                        ? palette.primary.contrastText
                        : isCompleted
                          ? palette.primary.main
                          : palette.primary.grayBluish
                }}
            />
            <Typography fontWeight={500}>{label}</Typography>
        </Box>
    );
};

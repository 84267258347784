import { FC, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Card } from '@mui/material';
import { styled } from '@mui/system';
import { omit } from 'lodash';

import { ScheduleEventCard } from '../common/ScheduleEventCard';
import { ShsAutoCompleteWithLoad, ShsSelectInput } from 'src/components/common';
import { Resource, UserRoleLabel } from 'src/constants';
import { UserRole } from 'src/auth/types';
import { DragItem, DragAndDropZone } from 'src/constants/drag-and-drop';
import { palette } from 'src/Theme';
import { CALENDAR_EVENTS_MOCK } from 'src/api/mocks/ScheduleEventsMock';
import { DragAndDropData } from '../interfaces';
import { CalendarEvent } from '../types';

const EXCLUDED_ROLES = [UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT, UserRole.PARENT];

const StyledCard = styled(Card)<{ highlighted?: boolean }>(({ highlighted = false }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        backgroundColor: highlighted ? palette.primary.gray : palette.primary.grayLight,
        padding: '8px',
        paddingBottom: '24px',
        borderRadius: '8px'
    };
});

export const ScheduleSidebar: FC = () => {
    const [events, setEvents] = useState(CALENDAR_EVENTS_MOCK.slice(3));

    const [{ highlighted }, dropRef] = useDrop(
        () => ({
            accept: DragItem.SCHEDULE_CARD,
            drop: (item: any) => handleScheduleEventDrop(item),
            collect: (monitor) => ({
                highlighted: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }),
        [events]
    );

    const handleScheduleEventDrop = (item: CalendarEvent) => {
        const itemIndex = events.findIndex((event) => event.id === item.id);
        if (itemIndex === -1) {
            const updatedEvent = omit(item, ['start', 'end']);

            setEvents((events) => [...events, updatedEvent]);
        }

        return { item, target: DragAndDropZone.SIDEBAR };
    };

    const handleScheduleEventDragEnd = ({ item, target }: DragAndDropData) => {
        if (target === DragAndDropZone.TIMESLOT) {
            // todo: update data on BE here
            setEvents((events) => events.filter((event) => event.id !== item.id));
        }
    };

    return (
        <>
            <ShsSelectInput
                source="userRole"
                label="Для кого складаєте розклад"
                placeholder="Виберіть роль"
                choices={Object.entries(UserRoleLabel)
                    .filter(([id]) => !EXCLUDED_ROLES.includes(id as UserRole))
                    .map(([id, name]) => ({ id, name }))}
                defaultValue={UserRole.TEACHER}
                required
            />

            <ShsAutoCompleteWithLoad
                source="subject"
                pageUrl={Resource.SUBJECTS}
                keyField="id"
                textField="name"
                label="Для кого складаєте розклад"
                resultLimit={1000}
                required
            />

            <StyledCard ref={dropRef} highlighted={highlighted}>
                {events.map((event, idx) => (
                    <ScheduleEventCard key={idx} event={event} dragIcon onDragEnd={handleScheduleEventDragEnd} />
                ))}
            </StyledCard>
        </>
    );
};

import * as React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

interface ReturnButtonProps {
    label: string;
    to?: string;
    onClick?: () => void;
    sx?: Record<string, any>;
}

const ReturnButton: React.FC<ReturnButtonProps> = ({ label, to, onClick, sx = {} }) => {
    if (to) {
        return (
            <Button
                component={Link}
                to={to}
                startIcon={<KeyboardBackspaceIcon />}
                sx={{ textTransform: 'none', ...sx }}
            >
                {label}
            </Button>
        );
    } else {
        return (
            <Button onClick={onClick} startIcon={<KeyboardBackspaceIcon />} sx={{ textTransform: 'none', ...sx }}>
                {label}
            </Button>
        );
    }
};

export default ReturnButton;

import { FC } from 'react';
import { Chip } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../../assets/close.svg';

type ChipLabelProps = {
    key: string;
    label: string;
    onDelete: () => void;
};

const chipStylesBase = {
    backgroundColor: '#75CACB1A',
    border: '1px solid #75CACB',
    borderRadius: '6px',
    fontSize: '16px',
    lineHeight: '24px'
};

export const ChipLabel: FC<ChipLabelProps> = ({ key, label, onDelete }) => {
    return (
        <Chip
            variant={'outlined'}
            deleteIcon={<CloseIcon />}
            sx={chipStylesBase}
            key={key}
            label={label}
            onDelete={onDelete}
        />
    );
};

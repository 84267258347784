import * as React from 'react';
import { UrlField, NumberField, TextField, useRecordContext } from 'react-admin';
import { Grid, Box, Divider } from '@mui/material';

import { sectionTitleStyle, smallGrayFontStyle } from 'src/styles';
import { ChildFormFields } from 'src/constants';
import { formatPhoneNumber } from 'src/utils';
import { getAdditionalInstitutionAction } from '../helpers';

export const RegistrationData: React.FC = () => {
    const record = useRecordContext();
    const visit = record?.[ChildFormFields.VISIT];
    const additionalActionRequired = visit?.[ChildFormFields.EDUCATIONAL_INSTITUTION_ADDITIONAL_ACTION];
    return (
        <>
            <Grid item xs={12}>
                <Box sx={sectionTitleStyle}>Відомості про освіту дитини за місцем проживання</Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                    Заклад освіти за місцем проживання:
                </Box>
                <UrlField
                    source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_INSTITUTION_REFERENCE}` as any}
                    target="_blank"
                    rel="noreferrer"
                />
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                    Номер телефону закладу освіти
                </Box>
                <NumberField
                    source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_INSTITUTION_PHONE}` as any}
                    transform={formatPhoneNumber}
                />
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                    Email закладу освіти
                </Box>
                <TextField
                    source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_INSTITUTION_EMAIL}` as any}
                />
            </Grid>
            {additionalActionRequired?.enabled && (
                <Grid item xs={6}>
                    <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                        Потрібна організація освітнього процесу:
                    </Box>
                    {getAdditionalInstitutionAction(additionalActionRequired.action)}
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>
        </>
    );
};

export default RegistrationData;

export enum Resource {
    CENTERS = 'centers',
    CHILDREN = 'children',
    EMPLOYEES = 'employees',
    LAWS = 'laws',
    SUBJECTS = 'subjects',
    SCHEDULE = 'schedule',
    SPECIAL_SERVICES = 'special-services',
    REPORTS = 'reports'
}

import React, { FC } from 'react';
import { FunctionField, FunctionFieldProps, Link, useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { palette } from '../../../Theme';

export const ShsUrlField: FC<Omit<FunctionFieldProps, 'render'> & { sourceName: string; sourceLink: string }> = ({
    sourceName,
    sourceLink,
    ...otherFunctionProps
}) => {
    const record = useRecordContext();

    return (
        <FunctionField
            render={() => (
                <Link
                    to={get(record, sourceLink)}
                    target="_blank"
                    sx={{
                        '&.RaLink-link': { color: palette.primary.midnightBlue, fontSize: 12 },
                        '&.RaLink-link:hover': { textDecoration: 'underline' }
                    }}
                >
                    {record[sourceName]}
                </Link>
            )}
            {...otherFunctionProps}
        />
    );
};

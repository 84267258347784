import React, { FC, useState } from 'react';
import { Card, Container, Tab, Tabs } from '@mui/material';
import { listContainerStyles } from '../../../styles';
import { EducationLevelGroup } from '../../../constants';
import EducationLevelSubjects from './EducationLevelSubjects';
import { ToolbarActions } from './ToolbarActions';

const SubjectsList: FC = () => {
    const [activeTab, setActiveTab] = useState<EducationLevelGroup>(EducationLevelGroup.PRESCHOOL);

    return (
        <Container sx={listContainerStyles}>
            <ToolbarActions />
            <Card>
                <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                    <Tab value={EducationLevelGroup.PRESCHOOL} label="Дошкільна освіта" />
                    <Tab value={EducationLevelGroup.JUNIOR_SCHOOL} label="1-4 класи" />
                    <Tab value={EducationLevelGroup.HIGH_SCHOOL} label="5-11 класи" />
                </Tabs>
                <EducationLevelSubjects educationLevelGroup={activeTab} />
            </Card>
        </Container>
    );
};

export default SubjectsList;

import React, { FC } from 'react';
import { Card, Typography } from '@mui/material';

import { ShsTextInput } from '../../common';
import { SubjectControlsGrid } from './SubjectControlsGrid';
import { HIGH_SCHOOL_GRADES_LABELS, JUNIOR_SCHOOL_GRADES_LABELS, PRE_SCHOOL_YEARS_LABELS } from '../../../constants';

const separatorStyles = { mb: 1, mt: 3 };

export const SubjectsCreateCard: FC = () => {
    return (
        <Card sx={{ width: '100%', mb: 2, p: 4 }}>
            <ShsTextInput required source={'name'} label="Назва предмету" />
            <Typography fontSize="16px" fontWeight="500" sx={{ ...separatorStyles }}>
                Дошкільна освіта
            </Typography>
            <SubjectControlsGrid schoolPlanning={PRE_SCHOOL_YEARS_LABELS} />
            <Typography fontSize="16px" fontWeight="500" sx={{ ...separatorStyles }}>
                1-4 класи
            </Typography>
            <SubjectControlsGrid schoolPlanning={JUNIOR_SCHOOL_GRADES_LABELS} />
            <Typography fontSize="16px" fontWeight="500" sx={{ ...separatorStyles }}>
                5-11 класи
            </Typography>
            <SubjectControlsGrid schoolPlanning={HIGH_SCHOOL_GRADES_LABELS} />
        </Card>
    );
};

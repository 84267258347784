import { FC } from 'react';
import { TopToolbar, CreateButton } from 'react-admin';
import { Box } from '@mui/material';
import { ShsButton, ShsTitle, ShsToggle, WithRole } from 'src/components/common';
import { shsButtonStyles } from 'src/styles';
import { UserRole } from 'src/auth/types';
import CircleButtonPlus from 'src/assets/CircleButtonPlus';

export const ScheduleToolbar: FC = () => {
    return (
        <TopToolbar
            sx={{
                width: '100%',
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                marginTop: '20px',
                marginBottom: '12px',
                '.ShsTitle-root': { marginBottom: 0 }
            }}
        >
            <Box sx={{ flex: 1, display: 'flex', gap: '16px', alignItems: 'center' }}>
                <ShsTitle title="Розклад" />
                <ShsToggle
                    label="Режим перегляду"
                    source="viewMode"
                    sx={{
                        '.MuiFormHelperText-root': {
                            display: 'none'
                        },
                        '& .MuiSwitch-root': {
                            background: '#CCCCCE'
                        }
                    }}
                />
            </Box>
            <WithRole roles={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN]}>
                <ShsButton label="Скопіювати з попереднього тижня" styleType="outline" />
                <CreateButton
                    label="Додати предмет"
                    variant="contained"
                    icon={<CircleButtonPlus color="white" />}
                    sx={shsButtonStyles}
                />
            </WithRole>
        </TopToolbar>
    );
};

import type { ValidationError, APIError } from './types';

export const getAPIFormErrors = (errors: APIError[]): ValidationError => {
    const errorsObject: ValidationError = {};
    if (Array.isArray(errors) && errors.length) {
        errors.forEach((obj: APIError) => {
            errorsObject[obj.field] = obj.message;
        });
    }
    return errorsObject;
};

import {
    ChildFormFields,
    SpecialEducationRequirementsList,
    DisabilityGroup,
    DisabilityGroupList,
    DisabilityReason,
    DisabilityReasonList,
    YesNoChoices,
    SocialStatusLabels,
    AdditionalEducationActionList
} from 'src/constants';

export const getSpecialRequirementsString = (arr: string[] = []) =>
    arr
        .map((id) => SpecialEducationRequirementsList.find((req) => req.id === id)?.name || '')
        .filter((name) => name)
        .join(', ');

export const getDisabilityString = (
    isDisability = false,
    disabilityGroup: DisabilityGroup,
    disabilityReason: DisabilityReason
) => {
    const yes = 'yes';
    if (!isDisability) return '';
    const disability = YesNoChoices.find((item) => item.id === yes)?.name || yes;
    const group = DisabilityGroupList.find((item) => item.id === disabilityGroup)?.name;
    const reason = DisabilityReasonList.find((item) => item.id === disabilityReason)?.name;

    return [disability, group, reason].filter((text) => text).join(', ');
};

export const getSocialStatusString = (visit: any) => {
    const orphan = visit?.[ChildFormFields.ORPHAN] && SocialStatusLabels[ChildFormFields.ORPHAN];
    const displaced = visit?.[ChildFormFields.DISPLACED] && SocialStatusLabels[ChildFormFields.DISPLACED];
    const warVictim = visit?.[ChildFormFields.WAR_VICTIM] && SocialStatusLabels[ChildFormFields.WAR_VICTIM];
    const complicated =
        visit?.[ChildFormFields.COMPLICATED_LIFE_CIRCUMSTANCES] &&
        SocialStatusLabels[ChildFormFields.COMPLICATED_LIFE_CIRCUMSTANCES];
    const other = visit?.[ChildFormFields.OTHER_SOCIAL_STATUS] || '';

    return [orphan, displaced, warVictim, complicated, other].filter((text) => text).join(', ');
};

export const getAdditionalInstitutionAction = (action: string): string =>
    AdditionalEducationActionList.find((req) => req.id === action)?.name || '';

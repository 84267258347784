import React from 'react';
import cn from 'classnames';
import { TextInput, TextInputProps, required as requiredValidate } from 'react-admin';
import { styled } from '@mui/system';
import './index.css';
import { palette } from '../../../../Theme';

interface ShsTextInputProps extends TextInputProps {
    label?: string;
    required?: boolean;
    labelPosition?: 'top' | 'left';
    'label-sx'?: any;
}

export const textInputSx = {
    '.MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: `${palette.primary.main}`
        },
        '&:focus fieldset': {
            border: `3px solid #75CACB`
        }
    },
    '& .MuiInputBase-input': {
        backgroundColor: 'white',
        borderColor: `${palette.primary.inputBorder}`,
        borderRadius: '6px',
        padding: '11px 16px'
    },
    '& .MuiInputBase-root': {
        background: '#ffffff'
    },
    '& p.Mui-error': {
        marginLeft: '2px'
    },
    '& .MuiInputBase-multiline': {
        padding: 0
    },
    '& textarea': {
        height: '110px !important',
        overflow: 'auto !important'
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2FA9A6 !important'
    }
};

const StyledLabel = styled('label')({
    whiteSpace: 'nowrap'
});

const ShsTextInput: React.FC<ShsTextInputProps> = (
    {
        label,
        required,
        labelPosition = 'top',
        source = '',
        placeholder,
        multiline = false,
        validate = [],
        sx = {},
        'label-sx': labelSX,
        inputProps
    },
    props
) => {
    const isRequired = () => (required && label ? '*' : '');
    const validateFn = [
        ...(Array.isArray(validate) ? validate : [validate]),
        ...(required ? [requiredValidate()] : [])
    ];
    return (
        <div className={cn('text_field__container', label ? labelPosition : '')}>
            <StyledLabel className="text_field__label" sx={labelSX}>
                {label || ''}
                {isRequired()}
            </StyledLabel>
            <TextInput
                source={source}
                label={false}
                validate={validateFn}
                placeholder={placeholder || ''}
                defaultValue=""
                multiline={multiline}
                sx={{ ...textInputSx, ...sx }}
                inputProps={inputProps}
                {...props}
            />
        </div>
    );
};

export default ShsTextInput;

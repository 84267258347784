import React from 'react';
import { BooleanInput } from 'react-admin';
import { styled } from '@mui/system';
import type { SxProps } from '@mui/material';

import './index.css';

interface ShsToggleProps {
    label: string;
    source: string;
    disabled?: boolean;
    sx?: SxProps;
}

const ToggleButton = () => {
    return <div className="toggle-button" />;
};

const styles = {
    '& .MuiSwitch-root': {
        width: '50px',
        height: '26px',
        borderRadius: '30px',
        padding: '0px'
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: 0,
        width: 'fit-content'
    },
    '& .MuiSwitch-root .Mui-checked': {
        color: 'white',
        background: '#2FA9A6'
    },
    '& .MuiSwitch-root:has(.Mui-checked)': {
        background: '#2FA9A6'
    },
    '& .MuiSwitch-root .MuiSwitch-switchBase': {
        padding: '4px 6px'
    },
    '& .MuiTypography-root': {
        marginLeft: '12px'
    }
};

const StyledShsToggle = styled(BooleanInput)(styles);

const ShsToggle: React.FC<ShsToggleProps> = ({ label, source, disabled = false, sx = {} }) => {
    return (
        <StyledShsToggle
            label={label}
            source={source}
            disabled={disabled}
            options={{ checkedIcon: <ToggleButton />, icon: <ToggleButton /> }}
            sx={{ m: 0, ...sx }}
        />
    );
};

export default ShsToggle;

import React from 'react';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/material';

import { ReactComponent as StarIcon } from 'src/assets/star.svg';
import { Theme } from 'src/Theme';

const starLabelStyles = {
    display: 'inline-block',
    fontSize: '16px'
};

const labelStyles = {
    ...starLabelStyles,
    fontWeight: 400,
    color: Theme.palette.primary.grayBluish,
    marginRight: '8px'
};

const textStyles = {
    ...starLabelStyles,
    fontWeight: 600,
    color: Theme.palette.primary.midnightBlue
};

const startIconStyles = {
    position: 'relative',
    display: 'inline-block',
    marginRight: '17px',
    '& > div': {
        width: '10px'
    },
    '& svg': {
        top: '-12px',
        position: 'absolute'
    }
};

interface StarLabelProps {
    label?: string;
    text?: string;
    sx?: SxProps;
}

const StarLabel: React.FC<StarLabelProps> = ({ label = '', text, sx = {} }) => {
    const labelComp = label && <Box sx={labelStyles}>{label}:</Box>;
    return (
        <Box sx={{ ...starLabelStyles, ...sx }}>
            <Box sx={startIconStyles}>
                <Box>
                    <StarIcon />
                </Box>
            </Box>
            {labelComp}
            <Box sx={textStyles}>{text}</Box>
        </Box>
    );
};

export default StarLabel;

import React from 'react';
import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';
import NoAccess from 'src/components/common/Errors/NoAccess';
import EmployeeList from './EmployeeList';
import { EmployeeCreate } from './EmployeeCreate';
import { AdminGroup, TeachersGroup } from 'src/constants/roles';
import EmployeeShow from './EmployeeShow';
import EmployeeEdit from './EmployeeEdit';

export default {
    list: (
        <WithRole roles={[...AdminGroup, UserRole.PSYCHOLOGIST, ...TeachersGroup]} orElse={<NoAccess />}>
            <EmployeeList />
        </WithRole>
    ),
    create: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]} orElse={<NoAccess />}>
            <EmployeeCreate />
        </WithRole>
    ),
    edit: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]} orElse={<NoAccess />}>
            <EmployeeEdit />
        </WithRole>
    ),
    show: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]} orElse={<NoAccess />}>
            <EmployeeShow />
        </WithRole>
    )
};

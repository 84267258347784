import React from 'react';
import { Filter, List, useAuthProvider } from 'react-admin';
import { Box, Container } from '@mui/material';
import { FILTER_EMPTY_VALUE, EducationLevelList } from 'src/constants';
import { listContainerStyles } from 'src/styles';
import { ShsTitle, ShsAutoCompleteWithLoad, ShsDatePicker, ShsSelectInput, WithRole } from 'src/components/common';
import type { Child } from 'src/components/Children/types';
import { ShsReturnButton } from '../common';
import { TopToolbar } from 'react-admin';
import DownloadReportButton from './components/DownloadReportButton';
import { AdminGroup } from 'src/constants/roles';

interface ChildrenReportProps {
    onReturn: () => void;
}

export const ChildrenReport: React.FC<ChildrenReportProps> = ({ onReturn }) => {
    const authProvider = useAuthProvider();
    const showEducationCenterFilter = authProvider?.containsRoles(AdminGroup);
    const buttonLabel = 'Назад до звітів';

    return (
        <Container sx={listContainerStyles}>
            <Box display="flex" justifyContent="flex-start" mb={2}>
                <ShsReturnButton onClick={onReturn} label={buttonLabel} />
            </Box>
            <ShsTitle title="Звіти по дітях" />
            <List<Child>
                resource="children"
                exporter={false}
                empty={false}
                pagination={false}
                actions={<ToolbarActions />}
                filters={<Filters showEducationCenterFilter={showEducationCenterFilter} />}
                filterDefaultValues={{ employmentType: FILTER_EMPTY_VALUE, educationCenterId: FILTER_EMPTY_VALUE }}>
                <></>
            </List>
        </Container>
    );
};

interface FiltersProps {
    showEducationCenterFilter?: boolean;
}

const Filters: React.FC<FiltersProps> = ({ showEducationCenterFilter }) => (
    <Filter>
        {showEducationCenterFilter && (
            <ShsAutoCompleteWithLoad
                source="educationCenterId"
                pageUrl="centers"
                filters={{ isActive: true }}
                emptyValue={FILTER_EMPTY_VALUE}
                emptyText="Усі"
                alwaysOn
                keyField="id"
                textField="shortName"
                resultLimit={100}
                label="Освітній центр"
            />
        )}
        <ShsSelectInput
            source="educationLevel"
            label="Рівень освіти"
            alwaysOn
            emptyValue={FILTER_EMPTY_VALUE}
            emptyText="Усі"
            choices={EducationLevelList}
        />
        <ShsDatePicker source="startDate" label="Початок періоду" alwaysOn required />
        <ShsDatePicker source="endDate" label="Кінець періоду" alwaysOn required />
    </Filter>
);

const ToolbarActions = () => {
    return (
        <TopToolbar>
            <WithRole roles={AdminGroup}>
                <DownloadReportButton pageUrl="children" label="Завантажити звіт" />
            </WithRole>
        </TopToolbar>
    );
};

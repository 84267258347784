import { UserRole } from 'src/auth/types';

export const FULL_TIME_EMPLOYEES = [
    UserRole.ADMIN,
    UserRole.METHODOLOGIST,
    UserRole.PSYCHOLOGIST,
    UserRole.PRESCHOOL_TEACHER,
    UserRole.ASSISTANT_PRESCHOOL_TEACHER,
    UserRole.JUNIORS_TEACHER,
    UserRole.ASSISTANT_JUNIORS_TEACHER
];

export const CIVIL_LAW_NATURE_EMPLOYEES = [
    UserRole.TEACHER,
    UserRole.PSYCHOLOGIST,
    UserRole.SPEECH_THERAPIST,
    UserRole.INTELLECTUAL_DISABILITY_TEACHER,
    UserRole.VISION_TEACHER,
    UserRole.DEAF_TEACHER
];

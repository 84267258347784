import React from 'react';
import { useNavigate } from 'react-router-dom';
import ShsButton from 'src/components/common/Fields/ShsButton';
import { ReactComponent as NoAccessLogo } from 'src/assets/no-access.svg';

import '../index.css';

const NoAccess = () => {
    const navigate = useNavigate();
    const handleClick = () => navigate('/');

    return (
        <div className="not_access_container">
            <div className="not_access_logo">
                <NoAccessLogo />
            </div>
            <div className="not_access_content">
                <h1 className="code_error">403</h1>
                <h4 className="not_access_title">Доступ заборонено</h4>
                <span className="not_access_content_title">
                    Вам потрібні відповідні дозволи, щоб переглянути цю сторінку.
                </span>
                <ShsButton label="Повернутись на головну" onClick={handleClick} sx={{ maxWidth: 'fit-content' }} />
            </div>
        </div>
    );
};

export default NoAccess;

import { GetListParams } from 'react-admin';
import { CALENDAR_EVENTS_MOCK } from 'src/api/mocks/ScheduleEventsMock';
import { CalendarEvent } from 'src/components/Schedule/types';

// export type SubjectGroupList = Record<EducationLevelGroup, SubjectData[]>;

const getGroupList = async (params: GetListParams): Promise<CalendarEvent[]> => {
    const { educationLevelGroup, startDate, endDate } = params.meta;

    // const response = await axiosApiInstance.get(Resource.SCHEDULE, {
    //     params: {
    //         targetGradeRange: EducationLevelGradeRange[educationLevelGroup],
    //         startDate,
    //         endDate
    //     }
    // });

    // todo: transform BE data to calendar events
    // return response.data;
    return new Promise((resolve) => setTimeout(() => resolve(CALENDAR_EVENTS_MOCK.slice(0, 4)), 100));
};

export default { getGroupList };

import { ChildMock, ChildSchedulerMock } from './ChildMock';
import { CenterMock } from './CenterMock';

import { Resource } from 'src/constants';

const ApiMocks = {
    [Resource.CHILDREN]: {
        getOne: ChildMock,
        update: ChildMock,
        'getOne/scheduler': ChildSchedulerMock
    },
    [Resource.CENTERS]: {
        getOne: CenterMock,
        update: CenterMock
    }
};

export default ApiMocks as any;

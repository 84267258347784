export const isChildEducationActive = (startDate: string, endDate: string | undefined): boolean => {
    const today = new Date();
    const start = new Date(startDate);

    if (endDate) {
        const end = new Date(endDate);
        return start <= today && today <= end;
    } else {
        return start <= today;
    }
};

import React from 'react';
import { TopToolbar as Toolbar, useRecordContext, useRedirect, useCreatePath, TextField } from 'react-admin';
import { Box, Stack } from '@mui/material';

import EditIcon from 'src/assets/Edit';
import { ShsButton, StatusBadge } from 'src/components/common';
import { sectionTitleStyle, interFontStyle } from 'src/styles';
import { EmployeeFormFields } from 'src/constants/employee';
import PhotoAvatar from '../common/PhotoAvatar';

/**
 * Employee Top Toolbar which provides photo and basic info about employee
 * Allows to go to edit page
 */
const EmployeeTopToolbar: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const record = useRecordContext();
    const titleName = `${record?.[EmployeeFormFields.LAST_NAME] || ''} ${record?.[EmployeeFormFields.FIRST_NAME] || ''} ${
        record?.[EmployeeFormFields.PATRONYMIC] || ''
    }`;

    /**
     * Handles the edit button click event
     * Redirects to the edit page for the employee with the specified ID
     */
    const handleEditClick = () => {
        redirect(createPath({ resource: 'employees', type: 'edit', id: record?.id }));
    };

    return (
        <Toolbar>
            <Stack display="flex" flexDirection="row" width={1}>
                <Box mr={3}>
                    <PhotoAvatar
                        firstName={record?.[EmployeeFormFields.FIRST_NAME] || ''}
                        lastName={record?.[EmployeeFormFields.LAST_NAME] || ''}
                        url={record?.photo}
                        size="extra-large"
                    />
                </Box>
                <Stack sx={{ flex: '1 1 100%' }}>
                    <Box sx={sectionTitleStyle}>{titleName}</Box>
                    <Box whiteSpace="normal" sx={{ mt: 1.5 }}>
                        <StatusBadge isActive={record?.active} />
                    </Box>
                    <Box sx={{ ...interFontStyle, mt: 1.5 }} whiteSpace="normal">
                        {record?.[EmployeeFormFields.EMPLOYEES][0].educationCenter.name}
                        <TextField source={record?.[EmployeeFormFields.EMPLOYEES][0].educationCenter.name} />
                    </Box>
                    <Box sx={{ marginTop: 1 }}>
                        Штатний працівник: {record?.[EmployeeFormFields.EMPLOYEES][0].roleDescription}
                    </Box>
                </Stack>
                <Box>
                    <ShsButton
                        label="Редагувати"
                        styleType="outline"
                        icon={<EditIcon />}
                        sx={{ height: '40px' }}
                        onClick={handleEditClick}
                    />
                </Box>
            </Stack>
        </Toolbar>
    );
};

export default EmployeeTopToolbar;

import { FC, useEffect, useState } from 'react';
import { Calendar, Views } from 'react-big-calendar';
import localizer from '../../../components/common/ShsCalendar/config';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Toolbar } from 'src/components/common/ShsCalendar/components';
import { useDrop } from 'react-dnd';
import { ScheduleEventCard } from '../common/ScheduleEventCard';
import { styled } from '@mui/system';
import { DragItem, DragAndDropZone } from 'src/constants/drag-and-drop';
import { CalendarEvent } from '../types';
import { CalendarConfig, DragAndDropData } from '../interfaces';

const CalendarCellDimensions = {
    width: 150,
    height: 70
};

const StyledCalendar = styled(Calendar)({
    minHeight: '800px',
    '.rbc-event': {
        border: 'none',
        padding: 0,
        '&.rbc-selected:focus': {
            outline: '5px auto currentColor'
        }
    },
    '.rbc-time-view': {
        '.rbc-event-label': {
            display: 'none'
        },
        '.rbc-events-container': {
            position: 'static'
        },
        '.rbc-timeslot-group': {
            flexBasis: 'auto',
            height: CalendarCellDimensions.height,
            minWidth: CalendarCellDimensions.width
        },
        '.rbc-time-header-gutter': {
            minWidth: CalendarCellDimensions.width
        },
        '.rbc-day-bg': {
            minWidth: CalendarCellDimensions.width - 1 // border
        },
        '.rbc-header': {
            minWidth: CalendarCellDimensions.width - 1 // border
        }
    }
});

const TimeSlotWithDrop: FC<any> = ({ children, value, onDrop }) => {
    const [{ highlighted }, dropRef] = useDrop(
        () => ({
            accept: DragItem.SCHEDULE_CARD,
            drop: (event: CalendarEvent) => {
                onDrop(value, event);
                return { event, target: DragAndDropZone.TIMESLOT };
            },
            collect: (monitor) => ({
                highlighted: monitor.isOver()
            })
        }),
        [value]
    );

    return (
        <div
            ref={dropRef}
            style={{
                width: '100%',
                height: '100%',
                background: !highlighted
                    ? 'initial'
                    : 'repeating-linear-gradient( -45deg, #9fa2a6, #9fa2a6 2px, #e8ebef 4px, #e8ebef 6px )' // todo: replace with img for performance?
            }}>
            {children}
        </div>
    );
};

// todo: pass some calendar config data like lesson duration etc.
export const ScheduleCalendar: FC<{ events: CalendarEvent[]; config: CalendarConfig }> = ({ events, config }) => {
    const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);

    useEffect(() => {
        setCalendarEvents(Array.isArray(events) ? events : []);
    }, [events]);

    function handleScheduleEventDragEnd({ item }: DragAndDropData) {
        setCalendarEvents((calendarEvents) => calendarEvents.filter((calendarEvent) => calendarEvent.id !== item.id));
    }

    const handleTimeSlotDrop = (slotDate: Date, dropEvent: CalendarEvent) => {
        const calendarEventIndex = calendarEvents.findIndex((calendarEvent) => calendarEvent.id === dropEvent.id);

        // todo: replace this logic with the call to API
        if (calendarEventIndex !== -1) {
            const updatedEvent = {
                ...calendarEvents[calendarEventIndex],
                start: slotDate,
                end: new Date(slotDate.getTime() + config.lessonDurationMs),
                id: crypto.randomUUID()
            };

            setCalendarEvents((calendarEvents) =>
                calendarEvents.map((event, index) => (index === calendarEventIndex ? updatedEvent : event))
            );
        } else {
            const newEvent = {
                ...dropEvent,
                start: slotDate,
                end: new Date(slotDate.getTime() + config.lessonDurationMs)
            };

            setCalendarEvents((calendarEvents) => [...calendarEvents, newEvent]);
        }
    };

    return (
        <StyledCalendar
            localizer={localizer}
            events={calendarEvents}
            selectable={false}
            defaultView={Views.WEEK}
            timeslots={1}
            step={60}
            components={{
                toolbar: Toolbar,
                event: ({ event }) => <ScheduleEventCard event={event as any} onDragEnd={handleScheduleEventDragEnd} />, // todo: correct any
                timeSlotWrapper: (props) => <TimeSlotWithDrop {...props} onDrop={handleTimeSlotDrop} />
            }}
        />
    );
};

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserRole } from 'src/auth/types';
import { UserRoleLabel } from 'src/constants';
import { CIVIL_LAW_NATURE_EMPLOYEES } from './const/roles';

/**
 * Custom hook to manage the educational services in the Employees form
 *
 * @returns [selectedItems, filterChoices, setSelectedItems, handleRemoveService, sortOptions, groupByCategory]
 */
const useEducationalServices = (source: string) => {
    const [selectedItems, setSelectedItems] = useState<any[]>();
    const { getValues, setValue } = useFormContext();
    const [selectedRoles, setSelectedRoles] = useState(getValues(source)?.rolesCLNC);

    const groupByCategory = (option: any) => {
        const categories = [];
        if (option.roles) {
            if (selectedRoles && selectedRoles.length > 0) {
                option.roles.forEach(
                    (role: UserRole) => selectedRoles?.includes(role) && categories.push(UserRoleLabel[role])
                );
            } else {
                option.roles.forEach((role: UserRole) => categories.push(UserRoleLabel[role]));
            }
        }
        if (categories.length === 0) categories.push('Інші');
        return categories.join(', ');
    };

    const sortOptions = (a: any, b: any) => {
        const groupA = groupByCategory(a);
        const groupB = groupByCategory(b);

        const isOtherA = groupA.includes('Інші');
        const isOtherB = groupB.includes('Інші');

        if (isOtherA && !isOtherB) return 1;
        if (!isOtherA && isOtherB) return -1;

        if (groupA < groupB) return -1;
        if (groupA > groupB) return 1;

        // If groups are equal, sort by name
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
    };

    const handleRemoveService = (source: string, itemId: any) => {
        if (selectedItems) {
            setValue(
                source,
                getValues(source).filter((item: any) => item !== itemId)
            );
            setSelectedItems(selectedItems?.filter((item) => item.specialId !== itemId));
        }
    };

    useEffect(() => {
        setSelectedRoles(getValues(source)?.rolesCLNC);
    }, [getValues(source)?.rolesCLNC]);

    const filterChoices = (choice: any) => {
        if (selectedRoles && selectedRoles.length > 0) {
            return choice.roles?.some(
                (role: any) => selectedRoles?.includes(role) && CIVIL_LAW_NATURE_EMPLOYEES.includes(role)
            );
        }
        return choice.roles?.some((role: any) => CIVIL_LAW_NATURE_EMPLOYEES.includes(role));
    };

    return {
        selectedItems,
        filterChoices,
        setSelectedItems,
        handleRemoveService,
        sortOptions,
        groupByCategory
    };
};

export default useEducationalServices;

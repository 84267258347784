export enum EmployeeFormFields {
    ID = 'id',
    VERSION = 'version',

    BIRTH_CERTIFICATE_ID = 'birthCertificateId',
    FIRST_NAME = 'name',
    LAST_NAME = 'surname',
    PATRONYMIC = 'patronymic',
    BIRTH_DATE = 'birthDate',
    GENDER = 'gender',
    RESIDENCE = 'actualAddress',
    REGISTRATION = 'registrationAddress',
    EMPLOYEES = 'employees',
    CREATED = 'created',

    EMPLOYEES_DATE_OF_STARTING = 'dateOfStarting',
    EMPLOYEES_DATE_OF_ENDING = 'dateOfEnding',

    EMPLOYEE_DETAILS = 'details',

    EDUCATIONAL_INSTITUTION_REFERENCE = 'educationInstitutionUrl',
    EDUCATIONAL_INSTITUTION_PHONE = 'educationInstitutionPhone',
    EDUCATIONAL_INSTITUTION_EMAIL = 'educationInstitutionEmail',
    EDUCATIONAL_INSTITUTION_ADDITIONAL_ACTION = 'additionalActionRequired',

    VISIT = 'visit',
    VISITORS = 'visitParents',
    PARENT = 'parent',
    TAX_NUMBER = 'taxNumber',
    VISITOR_FIRST_NAME = 'name',
    VISITOR_LAST_NAME = 'surname',
    VISITOR_PATRONYMIC = 'patronymic',
    EMAIL = 'email',
    PHONE = 'phone',
    VISITOR_ROLE = 'role',
    ACTIVE = 'active',

    EDUCATIONAL_LEVEL = 'educationLevel',
    PRESCHOOL_AGE_LEVEL = 'preschoolAgeLevel',
    EDUCATION_CENTER = 'educationCenterId',
    EDUCATION_CENTER_DATA = 'educationCenter',
    HOSPITAL_DEPARTMENT_DATA = 'hospitalDepartment',
    HOSPITAL_DEPARTMENT = 'hospitalDepartmentId',
    EDUCATIONAL_AGREEMENT_DATE = 'servicesAgreementDate',
    EDUCATIONAL_SERVICE_END = 'servicesEndDate',
    EDUCATIONAL_SPECIAL_REQUIREMENTS = 'specialRequirements',
    DISABILITY = 'disabilityPresent',
    DISABILITY_GROUP = 'disabilityGroup',
    DISABILITY_REASON = 'disabilityReason',
    ORPHAN = 'orphan',
    DISPLACED = 'internallyDisplaced',
    WAR_VICTIM = 'warVictim',
    COMPLICATED_LIFE_CIRCUMSTANCES = 'complicatedLifeCircumstances',
    OTHER_SOCIAL_STATUS = 'otherSocialStatus',
    MEDIA_RECORDING_AGREEMENT = 'mediaRecordingAgreement',
    MEDIA_RECORDING_LINK = 'mediaRecordingLink',
    CHILD_INFO = 'info'
}

import React from 'react';
import { CreateButton, TopToolbar } from 'react-admin';

import { WithRole } from 'src/components/common';
import { UserRole } from 'src/auth/types';

const ToolbarActions = () => {
    return (
        <TopToolbar>
            <WithRole roles={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN]}>
                <CreateButton label="Додати дитину" variant="contained" resource="children" />
            </WithRole>
        </TopToolbar>
    );
};

export default ToolbarActions;

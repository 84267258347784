import React from 'react';
import { Link } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { ShsButton } from '../../../common';

interface EmployeeDocumentLinkProps {
    url: string;
}

export const EmployeeDocumentLink: React.FC<EmployeeDocumentLinkProps> = ({ url }) => {
    return (
        <>
            {url ? (
                <Link href={url} underline="none" target="_blank" rel="noreferrer">
                    <ShsButton
                        styleType="text"
                        sx={{ paddingLeft: 0 }}
                        label="Переглянути файл"
                        icon={<DescriptionOutlinedIcon sx={{ fontSize: '24px!important' }} />}
                        onClick={() => {}}
                    />
                </Link>
            ) : (
                '-'
            )}
        </>
    );
};

import React, { FC } from 'react';
import { Confirm, useCreatePath, useDelete, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { Typography, Stack, Box } from '@mui/material';

import { ShsButton } from '../../common';
import { shsModalsStyles } from '../../../styles';
import CircleWarning from '../../../assets/CircleWarning';
import { Resource } from '../../../constants';
import { Theme } from 'src/Theme';

interface DeleteSpecialServiceConfirmationProps {
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteSpecialServiceConfirmation: FC<DeleteSpecialServiceConfirmationProps> = ({
    modalOpen,
    setModalOpen
}) => {
    const record = useRecordContext();
    const createPath = useCreatePath();
    const redirect = useRedirect();
    const notify = useNotify();
    const [deleteOne] = useDelete();

    const handleCancelBtnClick = () => {
        setModalOpen(false);
    };

    const handleDeleteBtnClick = () => {
        deleteOne(
            Resource.SPECIAL_SERVICES,
            { id: record.id },
            {
                mutationMode: 'pessimistic',
                onSuccess: () => {
                    notify('Видалення послуги пройшло успішно', {
                        type: 'success',
                        autoHideDuration: 2500
                    });
                    redirect(createPath({ resource: Resource.SPECIAL_SERVICES, type: 'list' }));
                },
                onError: (error: any) => {
                    notify(`Помилка: ${error?.message}`, {
                        type: 'error'
                    });
                }
            }
        );
        setModalOpen(false);
    };

    return (
        <Confirm
            isOpen={modalOpen}
            title=""
            confirmColor="warning"
            onConfirm={handleDeleteBtnClick}
            onClose={handleCancelBtnClick}
            sx={shsModalsStyles}
            content={
                <Stack alignItems="center">
                    <div className="shs-modal-header">
                        <Box mb={3} sx={{ textAlign: 'center' }}>
                            <CircleWarning />
                        </Box>
                        <Typography variant="h6" component="h6" mb={2} mt={1} fontWeight="600" align="center">
                            Видалення послуги
                        </Typography>
                    </div>
                    <div className="shs-modal-body">
                        <Typography
                            component="div"
                            mb={3}
                            align="center"
                            fontWeight={'400'}
                            lineHeight={'24px'}
                            fontSize={'16px'}
                            color={Theme.palette.primary.grayBluish}
                        >
                            {`Чи впевнені ви що хочете видалити послугу «${record?.name}»?`}
                            <p>{`Після видалення послуги, вона буде видалена у всіх розкладах. `}</p>
                        </Typography>
                    </div>
                    <div className="shs-modal-footer">
                        <ShsButton
                            label="Скасувати"
                            styleType="outline"
                            onClick={handleCancelBtnClick}
                            sx={{ mr: 2 }}
                        />
                        <ShsButton label="Видалити" styleType="filled-danger" onClick={handleDeleteBtnClick} />
                    </div>
                </Stack>
            }
        />
    );
};

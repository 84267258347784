import { dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { uk } from 'date-fns/locale';

const locales = {
    uk
};

export default dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});

const CultureMapping: { [key: string]: string } = {
    ua: 'uk'
};

export const getCulture = (locale: string): string => CultureMapping[locale] || locale;

import React from 'react';
import cn from 'classnames';
import { Button } from 'react-admin';
import type { ButtonProps } from 'react-admin';

import { shsButtonStyles } from 'src/styles';

interface ShsButtonProps extends ButtonProps {
    styleType?:
        | 'filled'
        | 'filled-white'
        | 'filled-danger'
        | 'outline'
        | 'outline-danger'
        | 'text'
        | 'text-danger'
        | 'outline-no-border'
        | 'outline-danger'
        | 'round'
        | '';
    icon?: React.ReactElement;
    label: string;
}

const ShsButton: React.FC<ShsButtonProps> = (
    {
        label,
        disabled,
        styleType = 'filled',
        className = '',
        type = 'button',
        onClick = () => {},
        icon = null,
        sx = {}
    },
    props
) => (
    <Button
        className={cn('shs-button', className, styleType, disabled ? 'disabled_button' : '')}
        disabled={disabled}
        type={type}
        onClick={onClick}
        label={label}
        sx={{ ...shsButtonStyles, ...sx }}
        {...props}
    >
        {icon}
    </Button>
);

export default ShsButton;

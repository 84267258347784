import React, { FC } from 'react';
import { Datagrid, ListContextProvider, TextField, useList } from 'react-admin';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import {
    HIGH_SCHOOL_GRADES_LABELS,
    JUNIOR_SCHOOL_GRADES_LABELS,
    PRE_SCHOOL_YEARS_LABELS,
    EducationStage
} from 'src/constants';
import SubjectEditBtn from './SubjectEditBtn';
import { palette } from 'src/Theme';
import { SubjectData } from 'src/api/REST/subjects/interfaces';
import { WithRole } from 'src/components/common';
import { UserRole } from 'src/auth/types';

const educationLevelToLabelMap = new Map(
    [...PRE_SCHOOL_YEARS_LABELS, ...JUNIOR_SCHOOL_GRADES_LABELS, ...HIGH_SCHOOL_GRADES_LABELS].map(
        ({ sourceName, label }) => [sourceName, label]
    )
);
const EducationStageSubjectsList: FC<{ educationStage: EducationStage; data: SubjectData[] }> = ({
    educationStage,
    data
}) => {
    const listContext = useList({ data, sort: { field: 'name', order: 'ASC' } });

    return (
        <Accordion disableGutters>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                    flexDirection: 'row-reverse',
                    gap: '14px'
                }}>
                <Typography>{educationLevelToLabelMap.get(educationStage)}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0' }}>
                <ListContextProvider value={listContext}>
                    <Datagrid
                        bulkActionButtons={false}
                        sx={{
                            '.RaDatagrid-headerCell': {
                                color: palette.primary.midnightBlue
                            },
                            '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root > span': {
                                fontWeight: 500,
                                color: palette.primary.midnightBlue
                            },
                            '.RaDatagridHeaderCell-icon.MuiTableSortLabel-icon': {
                                display: 'initial'
                            }
                        }}>
                        <TextField label="Назва предмету" source="name" sx={{ marginLeft: '48px' }} />
                        <TextField label="Кількість годин/тиждень" source="hours" />
                        <TextField label="Кількість тем" source="topics" />
                        <WithRole roles={[UserRole.SYSTEM_ADMIN]}>
                            <SubjectEditBtn />
                        </WithRole>
                    </Datagrid>
                </ListContextProvider>
            </AccordionDetails>
        </Accordion>
    );
};

export default EducationStageSubjectsList;

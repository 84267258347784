import React from 'react';
import { List, TopToolbar, useAuthProvider } from 'react-admin';
import { Box, Container } from '@mui/material';
import WithRole from 'src/components/common/WithRole';
import ShsTitle from 'src/components/common/ShsTitle';
import ShsAutoCompleteWithLoad from 'src/components/common/Fields/ShsAutoCompleteWithLoad';
import { listContainerStyles } from 'src/styles';
import { FILTER_EMPTY_VALUE } from 'src/constants';
import { EmployeeType } from '../Employees/types';
import { ShsDatePicker, ShsReturnButton } from '../common';
import DownloadReportButton from './components/DownloadReportButton';
import { AdminGroup } from 'src/constants/roles';

const getFilters = (showEducationCenterFilter: boolean) => {
    const filters = [];
    if (showEducationCenterFilter) {
        filters.push(
            <ShsAutoCompleteWithLoad
                key="2"
                source="educationCenterId"
                pageUrl="centers"
                filters={{ isActive: true }}
                emptyValue={FILTER_EMPTY_VALUE}
                emptyText="Усі"
                alwaysOn
                keyField="id"
                textField="shortName"
                resultLimit={100}
                label="Освітній центр"
            />
        );
    }
    filters.push(<ShsDatePicker key="3" source="startDate" label="Початок періоду" alwaysOn required />);
    filters.push(<ShsDatePicker key="4" source="endDate" label="Кінець періоду" alwaysOn required />);

    return filters;
};

const ToolbarActions = () => {
    return (
        <TopToolbar>
            <WithRole roles={AdminGroup}>
                <DownloadReportButton pageUrl="employees" label="Завантажити звіт" />
            </WithRole>
        </TopToolbar>
    );
};

interface EmployeeReportProps {
    onReturn: () => void;
}

const EmployeeReport: React.FC<EmployeeReportProps> = ({ onReturn }) => {
    const authProvider = useAuthProvider();
    const buttonLabel = 'Назад до звітів';

    return (
        <Container sx={listContainerStyles}>
            <Box display="flex" justifyContent="flex-start" mb={2}>
                <ShsReturnButton onClick={onReturn} label={buttonLabel} />
            </Box>
            <ShsTitle title="Звіти по працівниках" />
            <List<EmployeeType>
                resource="employees"
                exporter={false}
                empty={false}
                pagination={false}
                actions={<ToolbarActions />}
                filters={getFilters(authProvider?.containsRoles(AdminGroup))}
                filterDefaultValues={{ employmentType: FILTER_EMPTY_VALUE, educationCenterId: FILTER_EMPTY_VALUE }}>
                <></>
            </List>
        </Container>
    );
};

export default EmployeeReport;

import * as React from 'react';
import { SimpleForm, Toolbar as T, useUpdate, useNotify, useRefresh } from 'react-admin';
import { Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ChildFormFields, Resource } from 'src/constants';
import { ShsButton, ShsSaveButton, ShsDatePicker } from 'src/components/common';

const styles = {
    '&.MuiCardContent-root, &.MuiToolbar-root': {
        display: 'inline-block',
        p: 0,
        m: 0,
        mt: 0.4
    },
    '&.MuiToolbar-root .MuiButtonBase-root': {
        mt: '-2px'
    }
};

const Toolbar: React.FC = () => {
    return (
        <T sx={styles} className="add-child-toolbar">
            <ShsSaveButton label="Зберегти" sx={{ height: '45px', ml: 2 }} />
        </T>
    );
};

export const ServiceEndDate: React.FC = () => {
    const refresh = useRefresh();
    const [newServiceEndDate, setNewServiceEndDate] = React.useState(false);
    const [update] = useUpdate();
    const notify = useNotify();
    const formPath = 'ServiceEndDate';

    const handleSubmit = (data: any) => {
        const payload = {
            id: data.id,
            date: data[formPath][ChildFormFields.EDUCATIONAL_SERVICE_END]
        };
        update(
            Resource.CHILDREN,
            {
                id: data.id,
                data: payload,
                meta: { useMock: false, method: `end-service-date` }
            },
            {
                mutationMode: 'optimistic',
                onSuccess: () => {
                    setNewServiceEndDate(true);
                    refresh();
                    notify('Дані збережено', { type: 'success', undoable: true });
                },
                onError: (error: any) => {
                    setNewServiceEndDate(false);
                    notify(`Error: ${error?.message}`, { type: 'warning', undoable: true });
                }
            }
        );
    };
    return (
        <Grid item xs={12}>
            {newServiceEndDate ? (
                <SimpleForm sx={styles} toolbar={<Toolbar />} onSubmit={handleSubmit}>
                    <ShsDatePicker
                        source={`${formPath}.${ChildFormFields.EDUCATIONAL_SERVICE_END}`}
                        label=""
                        required
                    />
                </SimpleForm>
            ) : (
                <ShsButton
                    styleType="round"
                    label="Додати"
                    icon={<AddCircleOutlineIcon />}
                    sx={{ height: '24px', border: 'none !important', p: '0 !important', mt: '-5px' }}
                    onClick={() => setNewServiceEndDate(true)}
                />
            )}
        </Grid>
    );
};

export default ServiceEndDate;

export const employeeEditContainerStyles = {
    maxWidth: '748px',
    mt: 2,
    '& .MuiPaper-root': {
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        overflow: 'visible'
    },
    '.edit-page ': {
        position: 'relative',
        marginTop: '1em'
    },
    '.edit-page > .MuiToolbar-root': {
        position: 'absolute !important',
        right: 0
    },
    'form .MuiCardContent-root': {
        background: '#fff',
        p: '24px 32px',
        borderRadius: '6px',
        marginTop: '26px'
    },
    '.MuiTab-root': {
        flex: 1
    }
};

import * as React from 'react';
import { TextField, useRecordContext, useRedirect, useCreatePath } from 'react-admin';
import { Grid, Box, Divider, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { sectionTitleStyle, smallGrayFontStyle, extraSmallGrayFontStyle } from 'src/styles';
import { ShsButton } from 'src/components/common';
import { EmployeeDocumentLink } from './EmployeeDocumentLink';
import { EmployeeFormFields } from 'src/constants/employee';
import { EmploymentEndDate } from './EmploymentEndDate';

const EmployeeWorkGrid: React.FC<any> = () => {
    const record = useRecordContext();
    const employees = record[EmployeeFormFields.EMPLOYEES] || [];

    const employeeRate = employees.map((employee: any) => ({
        id: employee.id,
        dateOfStarting: employee.dateOfStarting,
        staffRate: employee.staffRate
    }));

    const headerFont = {
        fontWeight: '600',
        color: '#000'
    } as const;

    const rowsFont = {
        fontWeight: '500'
    } as const;

    return (
        <TableContainer component={Paper} sx={{ maxWidth: 650 }}>
            <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: '#F9FAFB' }}>
                    <TableRow>
                        <TableCell sx={{ borderRight: '1px solid #e0e0e0' }}>
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>Дата становлення ставки</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>Розмір ставки</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {employeeRate.map((row: any, index: number) => (
                        <TableRow key={`${row.id}-${index}`}>
                            <TableCell
                                sx={{
                                    ...extraSmallGrayFontStyle,
                                    ...(index === 0 && { color: '#000' }),
                                    ...rowsFont,
                                    borderRight: '1px solid #e0e0e0'
                                }}
                                component="th"
                                scope="row">
                                {row.dateOfStarting}
                            </TableCell>
                            <TableCell
                                sx={[
                                    index === 0
                                        ? { ...extraSmallGrayFontStyle, color: '#000' }
                                        : extraSmallGrayFontStyle,
                                    rowsFont
                                ]}>
                                {row.staffRate}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const EmployeeNoData: React.FC<any> = ({ text }) => {
    return (
        <Grid item xs={4}>
            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                {text}
            </Box>
            -
        </Grid>
    );
};

export const EmployeeGeneralTab: React.FC = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const createPath = useCreatePath();

    /**
     * Handles the edit button click event
     * Redirects to the edit page for the employee with the specified ID
     */
    const handleEditClick = () => {
        redirect(createPath({ resource: 'employees', type: 'edit', id: record?.id }) + '/2');
    };

    const diplomas = record[EmployeeFormFields.EMPLOYEE_DETAILS]?.diplomas || [];
    const refresherCertificates = record[EmployeeFormFields.EMPLOYEE_DETAILS]?.refresherCertificates || [];
    const additionalDocuments = record[EmployeeFormFields.EMPLOYEE_DETAILS]?.additionalDocuments || [];
    const employees = record[EmployeeFormFields.EMPLOYEES] || [];

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={sectionTitleStyle}>Персональні дані</Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        РНОКПП:
                    </Box>
                    {record[EmployeeFormFields.TAX_NUMBER] ? <TextField source={EmployeeFormFields.TAX_NUMBER} /> : '-'}
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Телефон:
                    </Box>
                    {record[EmployeeFormFields.PHONE] ? <TextField source={EmployeeFormFields.PHONE} /> : '-'}
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Email:
                    </Box>
                    {record[EmployeeFormFields.EMAIL] ? <TextField source={EmployeeFormFields.EMAIL} /> : '-'}
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>

                {/* Працевлаштування */}
                <Grid item xs={12}>
                    <Box sx={sectionTitleStyle}>Працевлаштування</Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Тип працевлаштування:
                    </Box>
                    Штатний працівник
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Початок трудових відносин:
                    </Box>

                    <TextField source={`employees[0].dateOfStarting`} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Завершення трудових відносин:
                    </Box>
                    {employees[0].dateOfEnding ? (
                        <TextField source={`employees[0].dateOfEnding`} />
                    ) : (
                        <EmploymentEndDate />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>

                {/* Ставка */}
                <Grid item xs={12} mb={1}>
                    <Box sx={sectionTitleStyle}>Ставка</Box>
                </Grid>

                <EmployeeWorkGrid />

                <Grid item xs={12} mt={2}>
                    <ShsButton
                        styleType="outline"
                        label="Встановити нову ставку"
                        icon={<AddCircleOutlineIcon />}
                        sx={{ height: '48px' }}
                        onClick={handleEditClick}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>

                <Grid item xs={12}>
                    <ShsButton
                        styleType="outline"
                        label="Додати тип працевлаштування"
                        icon={<AddCircleOutlineIcon />}
                        sx={{ height: '48px' }}
                        onClick={handleEditClick}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>

                {/* Документи */}

                <Grid item xs={12} mb={1}>
                    <Box sx={sectionTitleStyle}>Документи</Box>
                </Grid>

                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Паспорт
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.passportLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Довідка про реєстрацію місця проживання
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.registrationLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Ідентифікаційний код
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.identificationCodeLink} />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
            </Grid>

            {/* Диплом */}
            {diplomas.length ? (
                diplomas.map((diploma: any, index: any) => (
                    <Grid container key={index}>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Диплом {index + 1}:
                            </Box>
                            <EmployeeDocumentLink url={diploma.diplomaLink} />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Додаток до диплому - {index + 1}
                            </Box>
                            <EmployeeDocumentLink url={diploma.diplomaSupplementLink} />
                        </Grid>
                    </Grid>
                ))
            ) : (
                <EmployeeNoData text="Диплом" />
            )}
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>

            {/* Сертифікати */}
            <Grid container>
                {refresherCertificates.length ? (
                    refresherCertificates.map((certificate: any, index: any) => (
                        <Grid item xs={4} key={index}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Сертифікати підвищення кваліфікації - {index + 1}
                            </Box>
                            <EmployeeDocumentLink url={certificate.certificateLink} />
                        </Grid>
                    ))
                ) : (
                    <EmployeeNoData text="Сертифікати підвищення кваліфікації" />
                )}
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
            </Grid>

            {/* Інше */}
            <Grid container>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Трудова книжка
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.employmentHistoryLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Медична книжка
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.medicalHistoryLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Документ про зміну ПІБ
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.nameChangeDocumentLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Довідка ВПО
                    </Box>
                    <EmployeeDocumentLink
                        url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.temporarilyDisplacedDocumentLink}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Атестаційний лист
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.attestationLetterLink} />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
            </Grid>

            {/* Інші документи */}
            <Grid container>
                {additionalDocuments.length ? (
                    additionalDocuments.map((document: any, index: any) => (
                        <Grid item xs={4} key={index}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                {document.name}
                            </Box>
                            <EmployeeDocumentLink url={document.link} />
                        </Grid>
                    ))
                ) : (
                    <EmployeeNoData text="Інший документ" />
                )}
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
            </Grid>
        </>
    );
};

export default EmployeeGeneralTab;

import React from 'react';
import { get } from 'lodash';
import { TopToolbar as Toolbar, useRecordContext, useRedirect, useCreatePath } from 'react-admin';
import { Box, Stack } from '@mui/material';

import { ChildFormFields, EducationLevelList, ChildGenderList } from 'src/constants';
import { getCurrentAgeInUAFormat, childrenUtils } from 'src/utils';
import EditIcon from 'src/assets/Edit';
import UnknownAvatar from 'src/components/common/UnknownAvatar';
import { ShsButton, StatusBadge, StarLabel } from 'src/components/common';
import { sectionTitleStyle, interFontStyle } from 'src/styles';

const TopToolbar: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const record = useRecordContext();

    const titleName = `${record?.[ChildFormFields.LAST_NAME] || ''} ${record?.[ChildFormFields.FIRST_NAME] || ''} ${
        record?.[ChildFormFields.PATRONYMIC] || ''
    }`;
    const educationLevel = EducationLevelList.find(
        (level) => level.id === record?.[ChildFormFields.VISIT]?.[ChildFormFields.EDUCATIONAL_LEVEL]
    );
    const gender = ChildGenderList.find((gender) => gender.id === record?.[ChildFormFields.GENDER])?.name || '';
    const age = getCurrentAgeInUAFormat(record?.[ChildFormFields.BIRTH_DATE]);
    const educationCenter = record?.[ChildFormFields.VISIT]?.[ChildFormFields.EDUCATION_CENTER_DATA];
    const handleEditClick = () => {
        redirect(createPath({ resource: 'children', type: 'edit', id: record?.id }));
    };

    return (
        <Toolbar>
            <Stack display="flex" flexDirection="row" width={1}>
                <Box mr={3}>
                    <UnknownAvatar
                        firstName={record?.[ChildFormFields.FIRST_NAME] || ''}
                        lastName={record?.[ChildFormFields.LAST_NAME] || ''}
                        size="extra-large"
                    />
                </Box>
                <Stack sx={{ flex: '1 1 100%' }}>
                    <Box sx={sectionTitleStyle}>{titleName}</Box>
                    <Box whiteSpace="normal">
                        <StatusBadge
                            isActive={childrenUtils.isChildEducationActive(
                                get(record, 'visit.servicesAgreementDate'),
                                get(record, 'visit.servicesEndDate')
                            )}
                            sx={{ mr: 3 }}
                        />
                        <StarLabel text={`${gender}, ${age}`} sx={{ mr: 3, mt: 1.5 }} />
                        <StarLabel label="Рівень освіти" text={educationLevel?.name} sx={{ mt: 1.5 }} />
                    </Box>
                    <Box sx={{ ...interFontStyle, mt: 1.5 }} whiteSpace="normal">
                        {educationCenter?.name}
                    </Box>
                </Stack>
                <Box>
                    <ShsButton
                        label="Редагувати"
                        styleType="outline"
                        icon={<EditIcon />}
                        sx={{ height: '40px' }}
                        onClick={handleEditClick}
                    />
                </Box>
            </Stack>
        </Toolbar>
    );
};

export default TopToolbar;

import React, { FC } from 'react';
import { email, regex } from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { editContainerStyles, titleFontStyle } from 'src/styles';
import { ShsPhoneInput, ShsRadioButtonGroup, ShsTextInput } from 'src/components/common';
import { PassportLabel, PassportType } from 'src/constants';
import { LinkInput } from '../components/LinkInput';

const EmployeePersonalDetailsForm: FC = () => {
    return (
        <Grid sx={editContainerStyles} container>
            <Typography sx={titleFontStyle} variant="h6" component="h6" mb={3}>
                Персональні дані
            </Typography>

            <Grid item>
                <ShsTextInput
                    required
                    source="taxNumber"
                    name="taxNumber"
                    label="Ідентифікаційний номер платника податків (РНОКПП)"
                    validate={regex(/^\d{10}$/, 'Поле має містити 10 цифр')}
                />
            </Grid>

            <Grid item sm={12}>
                <ShsTextInput source="surname" required label="Прізвище" fullWidth />
                <Grid container columnSpacing={4} rowSpacing={1}>
                    <Grid item sm={6}>
                        <ShsTextInput source="name" required label="Ім’я" fullWidth />
                        <ShsPhoneInput source="phone" label="Телефон" required fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <ShsTextInput source="patronymic" label="По батькові" fullWidth />
                        <ShsTextInput
                            source="email"
                            required
                            label="Email"
                            fullWidth
                            validate={[email('Email некоректний')]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Typography sx={titleFontStyle} variant="h6" component="h6" mt={3} mb={3}>
                Паспорт та ідентифікаційний код
            </Typography>
            <Grid item sm={12}>
                <ShsRadioButtonGroup
                    source="employeeDetails.passportType"
                    required
                    defaultValue={PassportType.ID_CARD}
                    choices={Object.entries(PassportLabel).map(([id, name]) => ({ id, name }))}
                />

                <LinkInput source="employeeDetails.passportLink" label="Паспорт" required />
                <LinkInput
                    source="employeeDetails.registrationLink"
                    label="Довідка про реєстрацію місця проживання"
                    required
                />
                <LinkInput source="employeeDetails.identificationCodeLink" label="Ідентифікаційний код" required />
            </Grid>

            <Typography sx={titleFontStyle} variant="h6" component="h6" mt={3} mb={3}>
                Фото працівника
            </Typography>
            <Grid item sm={12}>
                <LinkInput source="photo" label="Фото працівника" required />
            </Grid>
        </Grid>
    );
};

export default EmployeePersonalDetailsForm;

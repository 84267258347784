import React from 'react';
import { Typography, Grid } from '@mui/material';

import { ShsToggle, ShsTextInput, ShsPhoneInput } from 'src/components/common';
import { CenterFormFields } from 'src/constants/centers';

export const GeneralCenterInfo: React.FC = () => {
    return (
        <>
            <Typography variant="h6" component="h6" mb={3} fontWeight="600">
                Статус
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item xs={12}>
                    <ShsToggle source={CenterFormFields.ACTIVE} label="Активний" />
                </Grid>
            </Grid>
            <Typography variant="h6" component="h6" mb={3} fontWeight="600">
                Інформація про центр
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item xs={12}>
                    <ShsTextInput source={CenterFormFields.NAME} label="Назва" required />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput source={CenterFormFields.SHORT_NAME} label="Скорочена назва" required />
                </Grid>
                <Grid item xs={6}>
                    <ShsPhoneInput source={CenterFormFields.PHONE} label="Контактний номер телефону" required />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput source={CenterFormFields.CITY} label="Місто" required />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput source={CenterFormFields.ADDRESS} label="Адреса" required />
                </Grid>
            </Grid>
        </>
    );
};

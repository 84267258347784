import * as React from 'react';
import { Edit, SimpleForm, useCreatePath, TextInput, Loading, useEditController } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { editContainerStyles } from 'src/styles';
import { ChildFormFields } from 'src/constants';

import Toolbar from './Toolbar';
import { PersonalData, EducationInstitutionData, VisitorsList, EducationChildData } from './FieldGroups';
import { Theme } from 'src/Theme';

export const ChildEdit: React.FC = () => {
    const { isLoading, record } = useEditController();
    const createPath = useCreatePath();

    if (isLoading) {
        return <Loading />;
    }
    return (
        <Container sx={editContainerStyles} maxWidth={false}>
            <ShsReturnButton
                to={createPath({ resource: `children/${record.id}/show`, type: 'show' })}
                label="Назад до профіля дитини"
                sx={{ fontSize: Theme.typography.body2.fontSize, color: Theme.palette.primary.grayBluish }}
            />
            <Edit mutationMode="pessimistic" queryOptions={{ meta: { useMock: false } }} redirect="show">
                <Typography fontSize={'40px'} mb={4} fontWeight="700" color={Theme.palette.primary.midnightBlue}>
                    Редагувати дитину
                </Typography>
                <SimpleForm toolbar={<Toolbar editMode />}>
                    <TextInput sx={{ display: 'none' }} source={ChildFormFields.ID} />
                    <TextInput sx={{ display: 'none' }} source={ChildFormFields.VERSION} />

                    <TextInput sx={{ display: 'none' }} source={`${ChildFormFields.VISIT}.${ChildFormFields.ID}`} />
                    <TextInput
                        sx={{ display: 'none' }}
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.VERSION}`}
                    />

                    <PersonalData />
                    <EducationInstitutionData />
                    <VisitorsList />
                    <EducationChildData />
                </SimpleForm>
            </Edit>
        </Container>
    );
};

import { SimpleForm } from 'react-admin';
import { Container, Box, Stack } from '@mui/material';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { palette } from 'src/Theme';
import { ScheduleSidebar } from './ScheduleSidebar';
import { TabbedScheduleCalendar } from './TabbedScheduleCalendar';

export const ScheduleList: React.FC = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <Box width={'100vw'}>
                <SimpleForm toolbar={false} sx={{ padding: 0 }}>
                    <Stack direction={'row'} sx={{ backgroundColor: 'white' }}>
                        <Container sx={{ padding: '16px 8px', minWidth: '280px' }}>
                            <ScheduleSidebar />
                        </Container>
                        <Container
                            sx={{
                                backgroundColor: palette.primary.grayLight,
                                margin: 0,
                                '@media (min-width: 1200px)': {
                                    maxWidth: 'fit-content'
                                }
                            }}>
                            <TabbedScheduleCalendar />
                        </Container>
                    </Stack>
                </SimpleForm>
            </Box>
        </DndProvider>
    );
};

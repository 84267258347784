import React, { FC } from 'react';
import { regex } from 'react-admin';
import { Card } from '@mui/material';

import { ShsTextInput } from '../../common';
import { URL_REGEX } from '../../../constants';

export const LawInputsCard: FC = () => {
    return (
        <Card sx={{ width: '100%', marginBottom: 2, padding: 2 }}>
            <ShsTextInput source={'name'} label="Назва законопроєкту" required />
            <ShsTextInput
                source={'link'}
                label="Лінк"
                required
                validate={[
                    regex(
                        URL_REGEX,
                        'Поле повинно відповідати формату URL адреси. Наприклад: "https://www.rada.gov.ua/"'
                    )
                ]}
            />
        </Card>
    );
};

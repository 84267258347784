import { Theme } from 'src/Theme';

const outline = {
    background: 'transparent',
    color: Theme.palette.primary.main,
    border: `1px solid ${Theme.palette.primary.main}`
};

const filled = {
    background: '#2fa9a6',
    color: '#ffffff',
    border: '1px solid #2fa9a6'
};

export const shsButtonStyles = {
    padding: '12px 30px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    borderRadius: '6px',
    lineHeight: 1.5,

    '&.text:hover': {
        background: 'none'
    },
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: Theme.palette.primary.main
    },
    '&.filled': filled,
    '&.filled-white': {
        ...filled,
        background: Theme.palette.primary.creamyWhite,
        border: `1px solid #DFE4EA`,
        color: Theme.palette.primary.midnightBlue
    },
    '&.filled-danger': {
        ...filled,
        background: Theme.palette.danger.main,
        border: `1px solid ${Theme.palette.danger.main}`
    },
    '&.outline': outline,
    '&.outline-no-border': { ...outline, border: 'none' },
    '&.outline-danger': {
        ...outline,
        color: Theme.palette.danger.main,
        border: `1px solid ${Theme.palette.danger.main}`
    },
    '&.outline-danger:hover': {
        color: Theme.palette.danger.optional,
        border: `1px solid ${Theme.palette.danger.optional}`
    },
    '&.text-danger': {
        background: 'transparent',
        color: Theme.palette.danger.main,
        border: 'none',
        fill: Theme.palette.danger.main
    },
    '&.text-danger:hover': {
        color: Theme.palette.danger.optional,
        fill: Theme.palette.danger.optional,
        border: 'none'
    },
    '&.round': {
        background: 'transparent',
        border: `1px solid ${Theme.palette.primary.main}`,
        color: Theme.palette.primary.main,
        borderRadius: '50px',
        opacity: 0.8
    },
    '&.round:hover': {
        opacity: 1
    },
    '&.Mui-disabled': {
        boxShadow: 'none',
        opacity: 0.5
    }
};

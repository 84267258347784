import React, { useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { DeleteWithConfirmButton, Toolbar, useNotify, useUpdate } from 'react-admin';
import { Box } from '@mui/material';

import { ShsButton } from 'src/components/common';
import { ReactComponent as DeleteIcon } from 'src/assets/trash-can.svg';
import { shsToolbarStyles } from 'src/styles';
import { Resource } from 'src/constants';
import useTabbedFormNavigation from '../useTabIndex';
import tabGroups from '../const/tab-groups';

const styles = {
    flexGrow: 1,
    '.MuiButton-root': {
        mr: '10px'
    },
    '.ra-delete-button': {
        float: 'right',
        top: '10px'
    }
};

export type ModalProps = {
    isOpen: boolean;
    onConfirm?: () => void;
    onClose: () => void;
};

export interface ShsFormToolbarProps {
    submitLabel?: string;
    cancelLabel?: string;
    cancelDialog?: React.FC<ModalProps>;
    enableDeleteButton?: boolean;
    enableCancelButton?: boolean;
    deleteLabel?: string;
    deleteConfirmContent?: React.ReactNode;
    handleCancel?: () => void;
}

const EmployeeEditToolbar: React.FC<ShsFormToolbarProps> = ({
    submitLabel = 'Зберегти',
    cancelLabel = 'Скасувати',
    cancelDialog: CancelDialog,
    enableDeleteButton = false,
    enableCancelButton = true,
    deleteLabel = 'Видалити',
    deleteConfirmContent,
    handleCancel = () => {}
}) => {
    const { tabIndex, navigateToTab } = useTabbedFormNavigation();
    const {
        trigger,
        setValue,
        getValues,
        handleSubmit,
        formState: { isValidating, isSubmitting }
    } = useFormContext();

    const [update] = useUpdate();
    const [formFields, setFormFields] = useState<string[]>([]);

    const { isDirty } = useFormState();
    const [openCancel, setOpenCancel] = React.useState(false);

    const notify = useNotify();

    const handleCancelClick = () => (CancelDialog && isDirty ? setOpenCancel(true) : handleCancel());
    const handleCancelDialogClose = () => setOpenCancel(false);

    const handleSaveBtnClick = async () => {
        let groupValid = true;

        for (let field of formFields) {
            const fieldValid = await trigger(field);
            if (!fieldValid) {
                groupValid = false;
                setValue(field, getValues(field), { shouldTouch: true });
            }
        }

        if (groupValid) {
            if (tabIndex < tabGroups.length - 1) {
                navigateToTab(tabIndex + 1);
            } else {
                await handleSubmit((data) => {
                    const payload = {
                        ...data
                    };

                    update(
                        Resource.EMPLOYEES,
                        {
                            id: data.id,
                            data: payload,
                            meta: { useMock: false, method: `update` }
                        },
                        {
                            mutationMode: 'pessimistic',
                            onSuccess: () => {
                                notify('Дані збережено', { type: 'success', undoable: true });
                            },
                            onError: (error: any) => {
                                notify(`Error: ${error?.message}`, { type: 'warning', undoable: true });
                            }
                        }
                    );
                })();
            }
        }
    };

    return (
        <Toolbar sx={shsToolbarStyles} className="add-child-toolbar">
            <Box sx={styles}>
                <ShsButton label={submitLabel} onClick={handleSaveBtnClick} disabled={isValidating || isSubmitting} />
                {enableCancelButton && (
                    <ShsButton label={cancelLabel} styleType="outline" onClick={handleCancelClick} />
                )}
                {CancelDialog && (
                    <CancelDialog isOpen={openCancel} onConfirm={handleCancel} onClose={handleCancelDialogClose} />
                )}
                {enableDeleteButton && (
                    <DeleteWithConfirmButton
                        label={deleteLabel}
                        icon={<DeleteIcon style={{ fill: '#F23030' }} />}
                        confirmTitle=""
                        confirmColor="warning"
                        confirmContent={deleteConfirmContent}
                    />
                )}
            </Box>
        </Toolbar>
    );
};

export default EmployeeEditToolbar;

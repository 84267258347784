import React from 'react';
import { CreateButton, TopToolbar } from 'react-admin';

import CircleButtonPlus from '../../../assets/CircleButtonPlus';
import { UserRole } from '../../../auth/types';
import ShsTitle from '../../../components/common/ShsTitle';
import WithRole from '../../../components/common/WithRole';
import { shsButtonStyles } from '../../../styles';

export const ToolbarActions = () => {
    return (
        <TopToolbar
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '.ShsTitle-root': { marginBottom: 0 }
            }}>
            <ShsTitle title="Навчальний план" />
            <WithRole roles={[UserRole.SYSTEM_ADMIN]}>
                <CreateButton
                    label="Додати предмет"
                    variant="contained"
                    icon={<CircleButtonPlus color="white" />}
                    sx={shsButtonStyles}
                />
            </WithRole>
        </TopToolbar>
    );
};

import React, { useEffect, useState, ReactNode, cloneElement, isValidElement, ReactElement } from 'react';
import { ArrayInput, FormDataConsumer, minLength, required, SimpleFormIterator } from 'react-admin';
import { disableFirstRemoveStyle, formIteratorStyles } from '../../../styles';
import { Box, Grid, Typography, Divider } from '@mui/material';
import { ShsButton, ShsCheckbox, ShsDateInput, ShsSelectInput } from '../../common';
import { ReactComponent as DeleteIcon } from '../../../assets/trash-can.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ShsAutoCompleteWithLoad from '../../common/Fields/ShsAutoCompleteWithLoad';
import { StaffRate, StaffRateHours, StaffRateLabel, UserRoleLabel } from '../../../constants';
import { UserRole } from '../../../auth/types';
import DaySchedule from './DaySchedule';
import { DaysOfWeek, DaysOfWeekLabel } from '../../../constants';
import { Theme } from 'src/Theme';
import { ShsSelectArrayInput } from 'src/components/common/Fields/ShsSelectArrayInput';
import { ChipLabel } from '../components/ChipLabel';
import useEducationalServices from '../useEducationalServices';
import { minArrayLength } from 'src/utils/validate';
import { useWatch } from 'react-hook-form';
import AutoCompleteSubject from '../components/AutoCompleteSubject';
import { CIVIL_LAW_NATURE_EMPLOYEES, FULL_TIME_EMPLOYEES } from '../const/roles';

interface DisabledWrapperProps {
    isDisabled: boolean;
    children: ReactNode;
}

const DisabledWrapper: React.FC<DisabledWrapperProps> = ({ isDisabled, children }) => {
    if (isDisabled) {
        return (
            <div style={{ opacity: 0.5, pointerEvents: 'none' }}>
                {React.Children.map(children, (child) => {
                    if (isValidElement(child)) {
                        return cloneElement(child as ReactElement<any>, { disabled: true });
                    }
                    return child;
                })}
            </div>
        );
    }
    return <>{children}</>;
};

const EmploymentTypeForm: React.FC = () => {
    const [isAddButtonActive, setIsAddButtonActive] = useState(false);
    return (
        <ArrayInput source="employeeCenters" label="" defaultValue={[{}]}>
            <SimpleFormIterator
                fullWidth
                disableClear
                disableReordering
                sx={{
                    ...disableFirstRemoveStyle,
                    ...formIteratorStyles,
                    '& .RaSimpleFormIterator-line': {
                        flexDirection: 'column'
                    }
                }}
                getItemLabel={(index) => (
                    <Typography
                        variant="h5"
                        component="h5"
                        mb={1}
                        mt={1}
                        fontWeight="600"
                        color={Theme.palette.primary.midnightBlue}>
                        {`Працевлаштування - ${index + 1}`}
                    </Typography>
                )}
                removeButton={
                    <ShsButton
                        styleType="text-danger"
                        sx={{ p: 0, mb: 1 }}
                        label="Видалити працевлаштування"
                        icon={<DeleteIcon />}
                    />
                }
                addButton={
                    <Box pt={2} borderTop="solid 1px rgba(0, 0, 0, 0.12)" width="100%">
                        <ShsButton
                            styleType="text"
                            sx={{ p: 0 }}
                            label="Додати послугу"
                            icon={<AddCircleOutlineIcon />}
                            disabled={!isAddButtonActive}
                        />
                    </Box>
                }>
                <FormDataConsumer>
                    {({ getSource }) => {
                        const [isEducationCenterSelected, setIsEducationCenterSelected] = useState(false);

                        const educationCenterId = useWatch({
                            name: getSource('educationCenterId'),
                            defaultValue: null
                        });
                        const isStaffMember = useWatch({
                            name: getSource('staffMember'),
                            defaultValue: false
                        });
                        const isCivilLawContract = useWatch({
                            name: getSource('civilLawNatureContract'),
                            defaultValue: false
                        });
                        const roles = useWatch({
                            name: getSource('roles'),
                            defaultValue: []
                        });
                        const rate = useWatch({
                            name: getSource('rate'),
                            defaultValue: []
                        });

                        useEffect(() => {
                            const newIsSelected = !!educationCenterId;
                            setIsEducationCenterSelected(newIsSelected);
                        }, [educationCenterId]);

                        useEffect(() => {
                            setIsAddButtonActive(isStaffMember || isCivilLawContract);
                        }, [isStaffMember, isCivilLawContract]);

                        const handleEducationCenterChange = (value: any, option: any) => {
                            setIsEducationCenterSelected(!!value);
                        };

                        const {
                            selectedItems,
                            filterChoices,
                            setSelectedItems,
                            handleRemoveService,
                            sortOptions,
                            groupByCategory
                        } = useEducationalServices(getSource(''));

                        return (
                            <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
                                <Grid item xs={12}>
                                    <ShsAutoCompleteWithLoad
                                        source={getSource('educationCenterId')}
                                        required
                                        pageUrl="centers"
                                        filters={{ active: true }}
                                        validate={required('Вкажіть освітній центр')}
                                        keyField="id"
                                        textField="shortName"
                                        resultLimit={100}
                                        label="Освітній центр"
                                        onChange={handleEducationCenterChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DisabledWrapper isDisabled={!isEducationCenterSelected}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.12)' }}>
                                                <ShsCheckbox
                                                    source={getSource('staffMember')}
                                                    label="Штатний працівник"
                                                    defaultValue={false}
                                                />
                                                {isStaffMember ? (
                                                    <>
                                                        <Grid container columnSpacing={4} rowSpacing={1} mt={1}>
                                                            <Grid item xs={6}>
                                                                <ShsSelectArrayInput
                                                                    source={getSource('roles')}
                                                                    label="Роль"
                                                                    required
                                                                    validate={minArrayLength(
                                                                        1,
                                                                        "Обов'язково для заповнення"
                                                                    )}
                                                                    choices={Object.entries(UserRoleLabel)
                                                                        .filter(([id]) =>
                                                                            FULL_TIME_EMPLOYEES.includes(id as UserRole)
                                                                        )
                                                                        .map(([id, name]) => ({ id, name }))}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container columnSpacing={4} rowSpacing={1} mt={1}>
                                                            <Grid item xs={6}>
                                                                <ShsDateInput
                                                                    source={getSource('dateOfStarting')}
                                                                    required
                                                                    label="Дата початку трудових відносин"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <ShsSelectInput
                                                                    source={getSource('rate')}
                                                                    label="Розмір ставки"
                                                                    placeholder="Виберіть розмір ставки"
                                                                    required
                                                                    disabled={roles.length === 0}
                                                                    choices={Object.entries(StaffRateLabel)
                                                                        .filter(
                                                                            ([id]) =>
                                                                                !FULL_TIME_EMPLOYEES.includes(
                                                                                    id as UserRole
                                                                                )
                                                                        )
                                                                        .map(([id, name]) => ({ id, name }))}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Навантаження працівника:{' '}
                                                            <b>
                                                                {StaffRateHours[rate as StaffRate] || '-'} год/тиждень
                                                            </b>
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} sx={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.12)' }}>
                                                <ShsCheckbox
                                                    source={getSource('civilLawNatureContract')}
                                                    label="Договір цивільно-правового характеру"
                                                    defaultValue={false}
                                                />
                                                {isCivilLawContract ? (
                                                    <>
                                                        <Grid container columnSpacing={4} rowSpacing={1} mt={1}>
                                                            <Grid item xs={6}>
                                                                <ShsDateInput
                                                                    source={getSource('dateOfStartingCLNC')}
                                                                    required
                                                                    label="Дата початку трудових відносин"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <ShsDateInput
                                                                    source={getSource('dateOfEnding')}
                                                                    required
                                                                    label="Дата завершення трудових відносин"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <ShsSelectArrayInput
                                                                    source={getSource('rolesCLNC')}
                                                                    label="Освітня роль"
                                                                    required
                                                                    validate={minArrayLength(
                                                                        1,
                                                                        "Обов'язково для заповнення"
                                                                    )}
                                                                    choices={Object.entries(UserRoleLabel)
                                                                        .filter(([id]) =>
                                                                            CIVIL_LAW_NATURE_EMPLOYEES.includes(
                                                                                id as UserRole
                                                                            )
                                                                        )
                                                                        .map(([id, name]) => ({ id, name }))}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <AutoCompleteSubject
                                                                    required={isCivilLawContract ? true : false}
                                                                    setSelectedData={setSelectedItems}
                                                                    groupBy={groupByCategory}
                                                                    sort={sortOptions}
                                                                    source={getSource('subjectIds')}
                                                                    validate={minLength(
                                                                        1,
                                                                        'Хоча б одна освітня послуга повинна бути вказана'
                                                                    )}
                                                                    keyField="specialId"
                                                                    textField="name"
                                                                    resultLimit={100}
                                                                    filterChoices={filterChoices}
                                                                    label="Освітня послуга"
                                                                />
                                                            </Grid>

                                                            <Divider variant="middle" sx={{ width: '100%' }} />

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                gap={'8px'}
                                                                display={'flex'}
                                                                flexWrap={'wrap'}
                                                                margin={'10px'}>
                                                                {selectedItems?.map((item: any) => (
                                                                    <ChipLabel
                                                                        key={item.specialId + '_key'}
                                                                        label={item.name}
                                                                        onDelete={() =>
                                                                            handleRemoveService(
                                                                                getSource('subjectIds'),
                                                                                item.specialId
                                                                            )
                                                                        }
                                                                    />
                                                                ))}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    component="span"
                                                                    mb={1}
                                                                    fontWeight="400"
                                                                    color={Theme.palette.primary.optional}>
                                                                    Можливий графік роботи
                                                                </Typography>
                                                                {Object.keys(DaysOfWeek).map((dayKey, index) => {
                                                                    const day =
                                                                        DaysOfWeek[dayKey as keyof typeof DaysOfWeek];
                                                                    return (
                                                                        <Grid item xs={12} key={index}>
                                                                            <DaySchedule
                                                                                label={DaysOfWeekLabel[day]}
                                                                                getSource={(source) =>
                                                                                    getSource(
                                                                                        `possibleWorkSchedule.${day}.${source}`
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </DisabledWrapper>
                                </Grid>
                            </Grid>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default EmploymentTypeForm;

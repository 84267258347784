import React from 'react';
import { UserRole } from 'src/auth/types';
import { WithRole } from '../common';
import { CreateSpecialService } from './Create/CreateSpecialService';
import { EditSpecialService } from './Edit/EditSpecialService';
import { SpecialServicesList } from './List/SpecialServicesList';
import NoAccess from '../common/Errors/NoAccess';
import { AdminGroup, SpecialTeachersGroup } from 'src/constants/roles';

export default {
    list: (
        <WithRole roles={[...AdminGroup, ...SpecialTeachersGroup]} orElse={<NoAccess />}>
            <SpecialServicesList />
        </WithRole>
    ),
    create: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<NoAccess />}>
            <CreateSpecialService />
        </WithRole>
    ),
    edit: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<NoAccess />}>
            <EditSpecialService />
        </WithRole>
    )
};

import * as React from 'react';
import { Grid } from '@mui/material';

import { InstitutionData, EducationData, PersonalData, RegistrationData, VisitorData } from './FieldGroups';

export const GeneralTab: React.FC = () => (
    <Grid container columnSpacing={4} rowSpacing={1}>
        <InstitutionData />
        <EducationData />
        <PersonalData />
        <RegistrationData />
        <VisitorData />
    </Grid>
);

export default GeneralTab;

import { CreateParams, GetListParams } from 'react-admin';
import axiosApiInstance, { postRequestCacheConfig } from '../../axiosConfig';

export default {
    getList: (params: GetListParams) => {
        let { keyword, employmentType, ...filters } = params.filter;
        filters = {
            ...filters,
            ...(employmentType === 'STAFF'
                ? { isStaff: true }
                : employmentType === 'CONTRACT'
                  ? { isContract: true }
                  : {})
        };
        filters = Object.keys(filters).length ? filters : undefined;
        const pageRequest = {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: params.sort && { properties: [params.sort.field], direction: params.sort.order },
            keyword,
            filters
        };

        return axiosApiInstance
            .post('employees', pageRequest, postRequestCacheConfig)
            .then((response) => ({ data: response.data.content, total: response.data.totalElements }));
    },

    create: (params: CreateParams) => {
        const payload = params.data;
        const employeeCenters = payload.employeeCenters.map((center: any) => {
            const subjectIds = center.subjectIds?.filter((id: any) => !String(id).endsWith('_spec'));
            const specialSubjectIds = center.subjectIds
                ?.filter((id: any) => String(id).endsWith('_spec'))
                .map((id: any) => Number(id.replace(/_spec$/, '')));
            return { ...center, subjectIds, specialSubjectIds };
        });

        return axiosApiInstance.post(`employees/add`, { ...payload, employeeCenters }).then((response) => response);
    }
};

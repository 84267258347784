import React from 'react';
import { Theme } from 'src/Theme';

interface EditIconProps {
    color?: string;
    fill?: string;
}

const EditIcon: React.FC<EditIconProps> = ({
    fill = Theme.palette.danger.warning,
    color = Theme.palette.primary.optional
}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2520_25129)">
                <path
                    d="M19.4375 3.71861C19.4375 3.46861 19.3438 3.21861 19.1563 3.03111C18.7813 2.65611 18.4063 2.28111 18.0625 1.90611C17.7188 1.56236 17.4063 1.21861 17.0625 0.906111C16.9063 0.718611 16.7188 0.593611 16.4688 0.562361C16.1875 0.531111 15.9375 0.593611 15.7188 0.781111L13.5938 2.90611H2.53125C1.46875 2.90611 0.5625 3.78111 0.5625 4.87486V17.4999C0.5625 18.5624 1.4375 19.4686 2.53125 19.4686H15.1875C16.25 19.4686 17.1563 18.5936 17.1563 17.4999V6.40611L19.1875 4.37486C19.3438 4.18736 19.4375 3.96861 19.4375 3.71861ZM10.25 11.2499C10.2188 11.2811 10.2188 11.2811 10.1875 11.2811L7.9375 12.0311L8.6875 9.78111C8.6875 9.74986 8.71875 9.74986 8.71875 9.71861L14.3438 4.09361L15.9063 5.62486L10.25 11.2499ZM15.7188 17.4999C15.7188 17.8124 15.4688 18.0624 15.1563 18.0624H2.53125C2.21875 18.0624 1.96875 17.8124 1.96875 17.4999V4.84361C1.96875 4.53111 2.21875 4.28111 2.53125 4.28111H12.1563L7.71875 8.74986C7.5625 8.90611 7.40625 9.12486 7.34375 9.37486L6.1875 12.8749C6.09375 13.1249 6.15625 13.3436 6.28125 13.5624C6.375 13.6874 6.5625 13.8749 6.90625 13.8749H7.03125L10.625 12.6874C10.8438 12.6249 11.0625 12.4686 11.2188 12.3124L15.7188 7.81236V17.4999ZM16.875 4.65611L15.3125 3.12486L16.2813 2.15611C16.5313 2.40611 17.5625 3.43736 17.8125 3.71861L16.875 4.65611Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_2520_25129">
                    <rect width="20" height="20" fill={fill} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EditIcon;
